import { ECurrency } from "config/types";

export const CURRENCIES = [
  {
    currency: ECurrency.rub,
    disabled: false
  },
  {
    currency: ECurrency.usdt,
    disabled: false
  },
  {
    currency: ECurrency.usd,
    disabled: true
  }
];
