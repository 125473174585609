import { FC } from "react";

import AMLActivatePacks from "./components/AMLActivatePacks";
import AMLActivateDropdown from "./components/AMLActivateDropdown";

export type TAMLActivateProps = FC & {
  Packs: typeof AMLActivatePacks
  Dropdown: typeof AMLActivateDropdown
}

const AMLActivate: TAMLActivateProps = () => null;

AMLActivate.Dropdown = AMLActivateDropdown;
AMLActivate.Packs = AMLActivatePacks;

export default AMLActivate;
