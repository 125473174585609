import { FC } from 'react';
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import cn from "classnames";

import { paths } from "config/paths";

import SettingIcon from "components/Icons/SettingIcon";

import { usePricingContext } from "../../../../hooks";

const PricingCardActiveMarker: FC = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const { isModal } = usePricingContext();

  return (
    <div
      className={cn("pricing__card__active-marker", { modal: isModal })}
      onClick={() => history.push(paths.SETTINGS_PLAN_AND_PAYMENTS)}
    >
      <span>{t("pricing.active")}</span>
      <SettingIcon/>
    </div>
  );
};

export default PricingCardActiveMarker;