import React, { FC } from "react";
import cn from "classnames";
import { useDispatch, useSelector } from "react-redux";

import { ESyncStatus } from "config/types";
import { getAssetSingleId } from "config/paths";

import {
  getSingleAssetDataSelector,
  startAccountSyncLoadingSelector
} from "store/assets/selectors";
import { startAccountSyncRequest } from "store/assets/reducers";
import { analyticEvent } from "store/analytics/effects";
import { EEventType } from "store/analytics/types";

import Button from "../../../Buttons/Button";
import SyncLoader from "../../../SyncLoader";
import SyncIcon from "../../../Icons/SyncIcon";

const AssetSyncBtn: FC = () => {
  const dispatch = useDispatch();
  const id = getAssetSingleId();
  const data = useSelector(getSingleAssetDataSelector(id));
  const loading = useSelector(startAccountSyncLoadingSelector(id));

  const onClickSync = (e: React.MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault();
    dispatch(startAccountSyncRequest({ id }));
    dispatch(analyticEvent(EEventType.WALLETS_ACCOUNT_SYNC));
  };

  return (
    <div className="assets-single__sync-account">
      <Button
        icon={(loading && <SyncLoader isSpin={true} />)
          || ((data?.status === ESyncStatus.synchronized || data?.status === ESyncStatus.error) && <SyncIcon/>)
        }
        onClick={onClickSync}
        className={cn({
          error: data?.status === ESyncStatus.error && !loading,
          synchronizing: loading,
        })}
        disabled={loading}
      />
    </div>
  );
};

export default AssetSyncBtn;
