import { FC, useCallback, useMemo, useState } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { EAppNames } from "config/types";
import { paths } from "config/paths";

import YandexMetricaInitializer from "components/YandexMetricaInitializer";
import ScrollToTop from "components/ScrollToTop";
import GoogleAnalytics from "components/GoogleAnalytics";
import { LayoutAppNameContext } from "components/Layout/hooks";
import GoogleOAuth from "components/GoogleOAuth";
import BitOkIcon from "components/Icons/BitOkIcon";
import BitnalogIcon from "components/Icons/BitnalogIcon";

import { TUserInfoData } from "store/user/types";
import { EEventType } from "store/analytics/types";
import { userLogout } from "store/auth/actions";
import { getUserInfoDataSelector } from "store/user/selectors";

import {
  sendAmplitudeEvent,
  setAmplitudeRegenerateDeviceId,
  setAmplitudeUserId
} from "utils/amplitudeAnalytic";
import { setGoogleUserId } from "utils/googleAnalytic";

import LoadingLayout from "../LoadingLayout";

export type THeaderMobileProps = {
  mobileHeaderRight?: JSX.Element
  mobileHeaderLeft?: JSX.Element
}

export type LayoutProvidersProps = {
  routePath?: paths
}

const LayoutProviders: FC<LayoutProvidersProps> = ({ children, routePath }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const userData = useSelector(getUserInfoDataSelector);
  const [headerMobile, setHeaderMobile] = useState<THeaderMobileProps>({});

  const getAppNameValue = useMemo(() => {
    if (process.env.REACT_APP_NAME === EAppNames.bitOk) {
      return { name: "BitOK", logo: <BitOkIcon/> };
    }
    return { name: "Bitnalog", logo: <BitnalogIcon/> };
  }, []);

  const logout = useCallback(() => {
    sendAmplitudeEvent<TUserInfoData>(EEventType.LOGOUT, userData);
    setAmplitudeUserId(null);
    setAmplitudeRegenerateDeviceId();

    setGoogleUserId(null);
    dispatch(userLogout());
    history.push(paths.SIGN_IN);
    // if (isFunction(setTheme)) setTheme(EThemes.dark); // TODO: Deprecated
  }, []);

  return (<>
    <YandexMetricaInitializer/>
    <ScrollToTop>
      <GoogleAnalytics>
        <GoogleOAuth>
          <LoadingLayout>
            <LayoutAppNameContext.Provider
              value={{ ...getAppNameValue, ...headerMobile, setHeaderMobile, routePath, logout }}
            >
              {children}
            </LayoutAppNameContext.Provider>
          </LoadingLayout>
        </GoogleOAuth>
      </GoogleAnalytics>
    </ScrollToTop>
  </>);
};

export default LayoutProviders;
