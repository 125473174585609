import { FC } from "react";
import cn from "classnames";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { EQueries } from "config/types";

import Button from "components/Buttons/Button";
import ShareIcon from "components/Icons/ShareIcon";

import useQuery from "hooks/useQuery";

import { openModal } from "store/modals/reducers";
import { EModals } from "store/modals/types";
import { amlChecksDetailsSelector } from "store/amlChecks/selectors";
import { EAmlCheckType } from "store/amlChecks/types";

import { useShareLink } from "./hooks";

export type ShareLinkProps = {
  path: string
  title?: string
  className?: string
  disabled?: boolean
}

const ShareLink: FC<ShareLinkProps> = ({ 
  className, title, path, disabled
}) => {
  const history = useHistory();
  const query = useQuery();
  const dispatch = useDispatch();
  const { data } = useSelector(amlChecksDetailsSelector);
  const checkType = data?.type !== undefined &&
    [EAmlCheckType.single_address, EAmlCheckType.deposit, EAmlCheckType.withdrawal].includes(data.type);

  const modalTitle = "BitOk";

  const handleClick = useShareLink({
    title: modalTitle,
    path,
    share: () => {
      query.append(EQueries.title, modalTitle);
      query.append(EQueries.path, path);
      history.replace({
        search: query.toString()
      });
      dispatch(openModal(EModals.shareLink));
    }
  });

  return <Button
    title={title}
    onClick={handleClick}
    transparent={true}
    className={checkType ? cn("bn-dropdown__menu-share-item") :
      cn("share-link", className, { disabled })}
    icon={checkType ? null : <ShareIcon/>}
    disabled={disabled}
  />;
};

export default ShareLink;
