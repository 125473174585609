import { colors } from "config/constants";

{/* eslint-disable max-len */}
const PasswordHiddenIcon = (): JSX.Element => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M7.93759 9.74995C8.40172 9.74995 8.84684 9.56557 9.17503 9.23738C9.50322 8.90919 9.68759 8.46407 9.68759 7.99995C9.68759 7.9487 9.68525 7.89791 9.68087 7.84776L7.7854 9.74323C7.83556 9.7476 7.88618 9.74995 7.93759 9.74995ZM13.7301 2.58682L13.0626 1.91994C13.0392 1.89652 13.0074 1.88336 12.9742 1.88336C12.9411 1.88336 12.9093 1.89652 12.8859 1.91994L11.1777 3.62854C10.2355 3.14698 9.17624 2.90619 8.00009 2.90619C4.99697 2.90619 2.75634 4.47026 1.27822 7.59838C1.2188 7.72419 1.18799 7.8616 1.18799 8.00073C1.18799 8.13986 1.2188 8.27726 1.27822 8.40307C1.86884 9.64713 2.58004 10.6443 3.41181 11.3945L1.75884 13.0468C1.73542 13.0703 1.72226 13.102 1.72226 13.1352C1.72226 13.1683 1.73542 13.2001 1.75884 13.2235L2.42587 13.8906C2.44931 13.914 2.48109 13.9272 2.51423 13.9272C2.54737 13.9272 2.57915 13.914 2.60259 13.8906L13.7301 2.76369C13.7417 2.75209 13.7509 2.7383 13.7572 2.72312C13.7635 2.70795 13.7668 2.69168 13.7668 2.67526C13.7668 2.65883 13.7635 2.64256 13.7572 2.62739C13.7509 2.61221 13.7417 2.59843 13.7301 2.58682ZM5.18759 7.99995C5.18755 7.52495 5.31053 7.05805 5.54457 6.64472C5.77861 6.23139 6.11571 5.88572 6.52305 5.6414C6.93038 5.39707 7.39406 5.26242 7.8689 5.25055C8.34374 5.23869 8.81356 5.35002 9.23259 5.57369L8.4729 6.33338C8.16653 6.23528 7.83905 6.22347 7.5264 6.29923C7.21376 6.375 6.92802 6.53542 6.70054 6.7629C6.47307 6.99037 6.31265 7.27611 6.23688 7.58876C6.16111 7.90141 6.17293 8.22888 6.27103 8.53526L5.51134 9.29495C5.29815 8.89663 5.18693 8.45172 5.18759 7.99995Z"
      fill={colors.gray6}
    />
    <path
      d="M14.7217 7.59684C14.1717 6.43851 13.517 5.49397 12.7578 4.76324L10.5056 7.01559C10.6954 7.51186 10.7375 8.05249 10.6267 8.57216C10.516 9.09183 10.2571 9.56831 9.88136 9.94403C9.50564 10.3197 9.02916 10.5786 8.50949 10.6894C7.98982 10.8002 7.44919 10.7581 6.95292 10.5682L5.0426 12.4786C5.92885 12.8887 6.91458 13.0937 7.99979 13.0937C11.0029 13.0937 13.2435 11.5297 14.7217 8.40153C14.7811 8.27572 14.8119 8.13832 14.8119 7.99918C14.8119 7.86005 14.7811 7.72264 14.7217 7.59684Z"
      fill={colors.gray6}
    />
  </svg>
);

export default PasswordHiddenIcon;
