import { all, put, takeLatest } from "redux-saga/effects";

import delay from "utils/delay";

import { IPayloadAction } from "../rootInterface";
import { closeModalActionOnOnboardingState } from "../common/actions";

import { closeModal } from "./reducers";
import { EModals } from "./types";

function* closeModalAction(action: IPayloadAction<EModals>) {
  if (action.payload === EModals.assetModal) {
    yield delay(1000);
    yield put(closeModalActionOnOnboardingState());
  }
}

function* Saga(): Generator {
  yield all([
    takeLatest(closeModal.type, closeModalAction),
  ]);
}

export default Saga;
