import { FC } from "react";
import { Popover as PopoverAnt } from "antd";
import { PopoverProps } from "antd/lib/popover";
import { useMediaQuery } from "react-responsive";

import { maxMobileMediaWidth } from "config/constants";

const Hint: FC<PopoverProps> = ({
  content,
  trigger,
  children,
  open,
  align,
  className,
  style,
}) => {
  const isMaxMobile = useMediaQuery({ query: `(max-width: ${maxMobileMediaWidth}px)` });

  return (
    <PopoverAnt
      placement="bottomLeft"
      content={content}
      trigger={isMaxMobile ? "click" : trigger}
      overlayClassName="hint-overlay"
      align={align || { targetOffset: [6, 15] }}
      open={open}
      mouseEnterDelay={0.7}
      className={className}
    >
      <div className="hint-content" style={style}>
        {children}
      </div>
    </PopoverAnt>
  );
};

export default Hint;
