import { put, takeLatest, all, call } from "redux-saga/effects";
import { AxiosResponse } from "axios";
import isFunction from "lodash/isFunction";

import request from "../../API";

import { IPayloadAction } from "../rootInterface";

import {
  apiKeysAllAppend,
  apiKeysAllEdit,
  apiKeysAllFailure,
  apiKeysAllRemove,
  apiKeysAllRequest,
  apiKeysAllSuccess,
  apiKeysCreateFailure,
  apiKeysCreateRequest,
  apiKeysCreateSuccess,
  apiKeysDeleteFailure,
  apiKeysDeleteRequest,
  apiKeysDeleteSuccess,
  apiKeysEditFailure,
  apiKeysEditRequest,
  apiKeysEditSuccess
} from "./reducers";
import { ApiKeysCreateOptions, ApiKeysDeleteOptions, ApiKeysEditOptions } from "./types";

function* apiKeysAll() {
  try {
    const response: AxiosResponse = yield call(request.get, "/api-keys/all/");

    yield put(apiKeysAllSuccess(response.data));
  } catch (e) {
    yield put(apiKeysAllFailure(e));
  }
}

function* apiKeysCreate(action: IPayloadAction<ApiKeysCreateOptions>) {
  try {
    const response: AxiosResponse = yield call(request.post, "/api-keys/create/", action.payload.params);

    yield put(apiKeysCreateSuccess(response.data));
    yield put(apiKeysAllAppend(response.data));
    if (isFunction(action.payload.callOnSuccess)) action.payload.callOnSuccess();

  } catch (e) {
    yield put(apiKeysCreateFailure(e));
  }
}

function* apiKeysEditState(action: IPayloadAction<ApiKeysEditOptions>) {
  const { id, data } = action.payload;
  try {
    const response: AxiosResponse = yield call(request.patch, `/api-keys/${id}/edit/`, data);

    yield put(apiKeysEditSuccess(response.data));
    yield put(apiKeysAllEdit(response.data));
    if (isFunction(action.payload.callOnSuccess)) action.payload.callOnSuccess();
  } catch (e) {
    yield put(apiKeysEditFailure(e));
  }
}

function* apiKeysDelete(action: IPayloadAction<ApiKeysDeleteOptions>) {
  const { id } = action.payload;
  try {
    const response: AxiosResponse = yield call(request.delete, `/api-keys/${id}/delete/`);

    yield put(apiKeysDeleteSuccess(response.data));
    yield put(apiKeysAllRemove({ id }));
    if (isFunction(action.payload.callOnSuccess)) action.payload.callOnSuccess();
  } catch (e) {
    yield put(apiKeysDeleteFailure(e));
  }
}

function* Saga(): Generator {
  yield all([
    takeLatest(apiKeysAllRequest.type, apiKeysAll),
    takeLatest(apiKeysCreateRequest.type, apiKeysCreate),
    takeLatest(apiKeysEditRequest.type, apiKeysEditState),
    takeLatest(apiKeysDeleteRequest.type, apiKeysDelete),
  ]);
}

export default Saga;
