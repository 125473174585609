import { useMediaQuery } from "react-responsive";
import { FC } from "react";
import { useSelector } from "react-redux";
import { DownloadOutlined } from "@ant-design/icons";

import { maxMobileMediaWidth } from "config/constants";

import { amlChecksDetailsSelector } from "store/amlChecks/selectors";

const AMLPublicDownload: FC = () => {
  const isMaxMobile = useMediaQuery({ query: `(max-width: ${maxMobileMediaWidth}px)` });
  const { data } = useSelector(amlChecksDetailsSelector);

  if (!isMaxMobile) {
    return null;
  }

  return data?.report_url ? (
    <a href={data.report_url} rel="noreferrer" target="_blank">
      <DownloadOutlined className='aml-public__download__icon' />
    </a>
  ) : (
    <a rel="noreferrer" className='pdf-report-disabled'>
      <DownloadOutlined className='aml-public__download__icon' />
    </a>
  );
};
export default AMLPublicDownload;