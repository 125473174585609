import { createSlice } from "@reduxjs/toolkit";

import { TMyAssetsData } from "store/assets/types";

import { transactionInfoMapper as mapper } from "utils/transactionUtils";
import { TQueryParams } from "utils/url";

import { AxiosDataError } from "../../API/types";

import {
  defaultCreateTransitionExport,
  defaultCreatTaxReportSteps,
  defaultState, reportTransactionsDefault,
  taxAmountReportDefault
} from "../constants";
import { IPayloadAction } from "../rootInterface";

import {
  ECreateTaxReportSteps,
  EReportTransactionType,
  ESingleTaxReportTransactionStatus,
  ETaxTxTypeTransactionType,
  IReportTransactionsSuccess,
  TChangeReportCheckStatusPayload,
  TCreateTransitionExportStepTwo,
  TGetReportTransactionsPayload,
  TReportsStoreState,
  TReportTaxTransactionTypesStateData,
  TReportTransactionResult,
  TResultReportTaxTransactionStateData,
  TSingleTaxReportData,
  TSingleUnifiedTaxReportData,
  TUnifiedTaxReportFormOptions,
  TUnifiedTaxReportTransactionTypesData,
} from "./types";

const initialState: TReportsStoreState = {
  reportsState: defaultState,
  singleTaxReport: {},
  singlePersonalData: {},
  taxReportResultState: defaultState,
  createPersonalData: defaultState,
  createPersonalDataComplete: defaultState,
  newReportState: defaultState,
  deleteReportState: defaultState,
  taxAmountReport: taxAmountReportDefault,
  createTaxReportSteps: {},
  createTransitionExport: {},
  createTaxReportingProject: defaultState,
  createUnifiedTaxReportState: defaultState,
  updateUnifiedTaxReportState: defaultState,
  singleUnifiedTaxReport: {},
  unifiedTaxReportTransactionTypesState: {},
  unifiedTaxReportFormState: {},
  updateTaxReportAccounts: defaultState,
  reportTaxTransactionTypesState: {},
  reportTaxResultTransactionTypesState: {},
  reportTransactions: {},
  reportAgreement: defaultState,
  changeReportCheckStatus: {},
  changeAllReportCheckStatus: defaultState,
  addTransactionToTaxReportState: defaultState,
  createTransactionExportState: defaultState,
  createSourcesExportState: defaultState,
  reportSingleState: { ...defaultState, initialLoading: true },
  reportsTypesState: defaultState,
};

const reportsSlice = createSlice({
  name: "reports",
  initialState,
  reducers: {
    getReportsRequest(state) {
      state.reportsState = {
        ...state.reportsState,
        fetching: true,
        failure: null,
      };
    },
    getReportsSuccess(state, action) {
      state.reportsState = {
        fetching: false,
        data: action?.payload,
        failure: null,
      };
    },
    getReportsFailure(state, action) {
      state.reportsState = {
        fetching: false,
        data: null,
        failure: action?.payload,
      };
    },

    getSingleTaxReportRequest(state, action) {
      state.singleTaxReport = {
        ...state.singleTaxReport,
        [action.payload.id]: {
          ...state.singleTaxReport[action.payload.id],
          fetching: true,
          failure: null,
        },
      };
    },

    getSingleTaxReportSuccess(state, action: IPayloadAction<{ id: string; data: TSingleTaxReportData }>) {
      const { id, data } = action.payload;
      const createTaxReportStepsState = state.createTaxReportSteps[id] || defaultCreatTaxReportSteps;

      state.singleTaxReport = {
        ...state.singleTaxReport,
        [id]: {
          fetching: false,
          data: action.payload.data,
          failure: null,
        },
      };

      state.createTaxReportSteps = {
        ...state.createTaxReportSteps,
        [id]: {
          ...createTaxReportStepsState,
          stepOne: {
            ...createTaxReportStepsState.stepOne,
            assetsCheckList: data.accounts,
            includeAmls: data.include_amls
          }
        }
      };

      if (!!data.transactions_status) {
        switch (data.transactions_status.toLowerCase()) {
        case ESingleTaxReportTransactionStatus.error:
          state.createTaxReportSteps[id].currentStep = ECreateTaxReportSteps.one;
          break;
        case ESingleTaxReportTransactionStatus.forming:
        case ESingleTaxReportTransactionStatus.formed:
          state.createTaxReportSteps[id].currentStep = ECreateTaxReportSteps.two;
          break;
        case ESingleTaxReportTransactionStatus.confirmed:
          state.createTaxReportSteps[id].currentStep = ECreateTaxReportSteps.three;
          break;
        }
      }
    },

    createTaxReportInit(state, action: IPayloadAction<{ id: string | null }>) {
      if (!action.payload.id) return;

      state.createTaxReportSteps = {
        ...state.createTaxReportSteps,
        [action.payload.id]: defaultCreatTaxReportSteps
      };
    },
    changeSingleTaxReportCurrent(state, action: IPayloadAction<{ id: string, data: number }>) {
      const createTaxReportStepsState = state.createTaxReportSteps[action.payload.id] || defaultCreatTaxReportSteps;

      state.createTaxReportSteps = {
        [action.payload.id]: {
          ...createTaxReportStepsState,
          currentStep: action.payload.data
        }
      };
    },

    changeAssetsCheckList(state, action: IPayloadAction<{ id: string, data: string[] }>) {
      const createTaxReportStepsState = state.createTaxReportSteps[action.payload.id] || defaultCreatTaxReportSteps;

      state.createTaxReportSteps = {
        ...state.createTaxReportSteps,
        [action.payload.id]: {
          ...createTaxReportStepsState,
          stepOne: {
            ...createTaxReportStepsState.stepOne,
            assetsCheckList: action.payload.data
          }
        }
      };
    },

    changeIncludeAmlStatus(state, action: IPayloadAction<{ id: string, data: boolean }>) {
      const createTaxReportStepsState = state.createTaxReportSteps[action.payload.id] || defaultCreatTaxReportSteps;

      state.createTaxReportSteps = {
        ...state.createTaxReportSteps,
        [action.payload.id]: {
          ...createTaxReportStepsState,
          stepOne: {
            ...createTaxReportStepsState.stepOne,
            includeAmls: action.payload.data
          }
        }
      };
    },

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    getCreateTaxReportAssetsListRequest(state, action: IPayloadAction<{id: string, data: TQueryParams[]}>) {
      const createTaxReportStepsState = state.createTaxReportSteps[action.payload.id] || defaultCreatTaxReportSteps;

      state.createTaxReportSteps = {
        ...state.createTaxReportSteps,
        [action.payload.id]: {
          ...createTaxReportStepsState,
          stepOne: {
            ...createTaxReportStepsState.stepOne,
            assetsList: {
              ...createTaxReportStepsState.stepOne.assetsList,
              fetching: true,
            }
          }
        }
      };
    },
    getCreateTaxReportAssetsListSuccess(state, action: IPayloadAction<{ id: string, data: TMyAssetsData[] }>) {
      const { id, data: assetsList } = action.payload;
      const createTaxReportStepsState = state.createTaxReportSteps[id] || defaultCreatTaxReportSteps;
      const synchronizedList = assetsList.filter(item => !!item.synchronized_at).map(item => item.id);

      const assetsCheckList = !createTaxReportStepsState.stepOne.assetsCheckList?.length
        ? synchronizedList : createTaxReportStepsState.stepOne.assetsCheckList;

      state.createTaxReportSteps = {
        ...state.createTaxReportSteps,
        [id]: {
          ...createTaxReportStepsState,
          stepOne: {
            ...createTaxReportStepsState.stepOne,
            assetsList: {
              ...createTaxReportStepsState.stepOne.assetsList,
              fetching: false,
              data: assetsList,
              failure: null
            },
            assetsCheckList,
            synchronizedList
          }
        }
      };
    },
    getCreateTaxReportAssetsListFailure(state, action: IPayloadAction<{ id: string, error: AxiosDataError }>) {
      const { id, error } = action.payload;
      const createTaxReportStepsState = state.createTaxReportSteps[id] || defaultCreatTaxReportSteps;

      state.createTaxReportSteps = {
        ...state.createTaxReportSteps,
        [id]: {
          ...createTaxReportStepsState,
          stepOne: {
            ...createTaxReportStepsState.stepOne,
            assetsList: {
              ...createTaxReportStepsState.stepOne.assetsList,
              fetching: false,
              failure: error,
            }
          }
        }
      };
    },
    changeCreateTaxReportAnonymousStatus(state, action: IPayloadAction<{ id: string, data: boolean }>) {
      const createTaxReportStepsState = state.createTaxReportSteps[action.payload.id] || defaultCreatTaxReportSteps;

      state.createTaxReportSteps = {
        ...state.createTaxReportSteps,
        [action.payload.id]: {
          ...createTaxReportStepsState,
          isAnonymous: action.payload.data
        }
      };
    },

    resetCreateTaxReport(state) {
      state.createTaxReportSteps = {};
    },

    getSingleTaxReportFailure(state, action) {
      state.singleTaxReport = {
        ...state.singleTaxReport,
        [action.payload.id]: {
          fetching: false,
          data: null,
          failure: action.payload.error,
        },
      };
    },

    getSinglePersonalDataRequest(state, action) {
      state.singlePersonalData = {
        ...state.singlePersonalData,
        [action.payload.id]: {
          fetching: true,
          data: null,
          failure: null,
        },
      };
    },

    getSinglePersonalDataSuccess(state, action) {
      state.singlePersonalData = {
        ...state.singlePersonalData,
        [action.payload.id]: {
          fetching: false,
          data: action.payload.data,
          failure: null,
        },
      };
    },

    getSinglePersonalDataFailure(state, action) {
      state.singlePersonalData = {
        ...state.singlePersonalData,
        [action.payload.id]: {
          fetching: false,
          data: null,
          failure: action.payload.error,
        },
      };
    },

    // TODO: fix @typescript-eslint/no-unused-vars
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    createReportRequest(state, _) {
      state.newReportState = {
        fetching: true,
        data: null,
        failure: null,
      };
    },
    createReportSuccess(state, action) {
      if (state.reportsState.data && state.reportsState.data.results) {
        const { results } = state.reportsState.data;
        results.unshift(action.payload);
      }
      state.newReportState = {
        fetching: false,
        data: null,
        failure: null,
      };
    },
    createReportFailure(state, action) {
      state.newReportState = {
        fetching: false,
        data: null,
        failure: action.payload,
      };
    },

    getReportTaxAmountRequest(state) {
      state.taxAmountReport = {
        ...state.taxAmountReport,
        fetching: true,
        failure: null,
      };
    },
    getReportTaxAmountSuccess(state, action) {
      state.taxAmountReport = {
        fetching: false,
        data: action?.payload,
        failure: null,
      };
    },
    getReportTaxAmountFailure(state, action) {
      state.taxAmountReport = {
        fetching: false,
        data: null,
        failure: action?.payload,
      };
    },
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    deleteReportRequest(state, _) {
      state.deleteReportState = {
        fetching: true,
        data: null,
        failure: null,
      };
    },
    deleteReportSuccess(state, action) {
      state.deleteReportState = {
        fetching: false,
        data: action?.payload,
        failure: null,
      };
    },
    deleteReportFailure(state, action) {
      state.deleteReportState = {
        fetching: false,
        data: null,
        failure: action?.payload,
      };
    },
    updateStateAfterDeletion(state, action) {
      state.reportsState = {
        ...state.reportsState,
        data: {
          ...state.reportsState.data,
          results: state.reportsState?.data?.results.filter(report => report.id !== action.payload) || []
        }
      };
    },

    createTransitionExportInit(state, action: IPayloadAction<{ id: string | null }>) {
      if (!action.payload.id) return;

      state.createTransitionExport = {
        ...state.createTransitionExport,
        [action.payload.id]: defaultCreateTransitionExport
      };
    },
    setCreateTransitionExportClear(state) {
      state.createTransitionExport = {};
    },
    setCreateTransitionExportCurrentStep(state, action: IPayloadAction<{ id: string|null, currentStep: number }>) {
      if (!action.payload.id) return;

      state.createTransitionExport = {
        ...state.createTransitionExport,
        [action.payload.id]: {
          ...state.createTransitionExport[action.payload.id],
          currentStep: action.payload.currentStep
        }
      };
    },
    changeCreateTransitionExportAssetsCheckList(
      state, action: IPayloadAction<{ id: string|null, assetsCheckList: string[]|null|undefined }>
    ) {
      if (!action.payload.id) return;

      state.createTransitionExport = {
        ...state.createTransitionExport,
        [action.payload.id]: {
          ...state.createTransitionExport[action.payload.id],
          stepOne: {
            ...state.createTransitionExport[action.payload.id].stepOne,
            assetsCheckList: action.payload.assetsCheckList || []
          }
        }
      };
    },
    changeCreateTransitionExportIncludeAmlStatus(
      state, action: IPayloadAction<{ id: string|null, includeAmls: boolean }>
    ) {
      if (!action.payload.id) return;

      state.createTransitionExport = {
        ...state.createTransitionExport,
        [action.payload.id]: {
          ...state.createTransitionExport[action.payload.id],
          stepOne: {
            ...state.createTransitionExport[action.payload.id].stepOne,
            includeAmls: action.payload.includeAmls
          }
        }
      };
    },
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    getCreateTransitionExportAssetsListRequest(
      state, action: IPayloadAction<{ id: string|null, query?: TQueryParams[] }>
    ) {
      if (!action.payload.id) return;

      state.createTransitionExport = {
        ...state.createTransitionExport,
        [action.payload.id]: {
          ...state.createTransitionExport[action.payload.id],
          stepOne: {
            ...state.createTransitionExport[action.payload.id].stepOne,
            assetsList: {
              ...state.createTransitionExport[action.payload.id].stepOne.assetsList,
              fetching: true
            }
          }
        }
      };
    },
    getCreateTransitionExportAssetsListSuccess(
      state, action: IPayloadAction<{id: string|null, data: TMyAssetsData[]}>
    ) {
      const { id, data: assetsList } = action.payload;
      if (!id) return;
      const synchronizedList = assetsList.filter(item => !!item.synchronized_at).map(item => item.id);

      const createTransitionExport = state.createTransitionExport[id];
      if (createTransitionExport.stepOne.assetsCheckList === null) {
        createTransitionExport.stepOne.assetsCheckList = synchronizedList;
      }

      createTransitionExport.stepOne.assetsList = {
        fetching: false,
        data: assetsList,
        failure: null,
      };
      createTransitionExport.stepOne.synchronizedList = synchronizedList;
    },
    getCreateTransitionExportAssetsListFailure(
      state, 
      action: IPayloadAction<{id: string|null, error: AxiosDataError}>) {
      if (!action.payload.id) return;
      const { id, error } = action.payload;
      state.createTransitionExport[id].stepOne.assetsList = {
        fetching: false,
        data: [],
        failure: error,
      };
    },
    setCreateTransitionExportStepTwoParams(
      state, action: IPayloadAction<{ id: string|null, stepTwo: TCreateTransitionExportStepTwo }>
    ) {
      if (!action.payload.id) return;
      const { id, stepTwo } = action.payload;
      state.createTransitionExport[id].stepTwo = {
        ...state.createTransitionExport[id].stepTwo,
        ...stepTwo
      };
    },

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    createTaxReportingProjectRequest(state, _) {
      state.createTaxReportingProject = {
        fetching: true,
        data: null,
        failure: null,
      };
    },
    createTaxReportingProjectSuccess(state, action) {
      state.createTaxReportingProject = {
        fetching: false,
        data: action?.payload,
        failure: null,
      };
    },
    createTaxReportingProjectFailure(state, action) {
      state.createTaxReportingProject = {
        fetching: false,
        data: null,
        failure: action?.payload,
      };
    },

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    createUnifiedTaxReportRequest(state, _) {
      state.createUnifiedTaxReportState = {
        fetching: true,
        data: null,
        failure: null,
      };
    },
    createUnifiedTaxReportSuccess(state, action) {
      state.createUnifiedTaxReportState = {
        fetching: false,
        data: action?.payload.data,
        failure: null,
      };
    },
    createUnifiedTaxReportFailure(state, action) {
      state.createUnifiedTaxReportState = {
        fetching: false,
        data: null,
        failure: action?.payload,
      };
    },

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    updateUnifiedTaxReportRequest(state, _) {
      state.updateUnifiedTaxReportState = {
        fetching: true,
        data: null,
        failure: null,
      };
    },
    updateUnifiedTaxReportSuccess(state, action) {
      state.updateUnifiedTaxReportState = {
        fetching: false,
        data: action?.payload,
        failure: null,
      };
    },
    updateUnifiedTaxReportFailure(state, action) {
      state.updateUnifiedTaxReportState = {
        fetching: false,
        data: null,
        failure: action?.payload,
      };
    },

    singleUnifiedTaxReportRequest(state, action) {
      const singleUnifiedTaxReportPrevState = state.singleUnifiedTaxReport[action.payload.id]
        || defaultCreatTaxReportSteps;

      state.singleUnifiedTaxReport = {
        ...state.singleUnifiedTaxReport,
        [action.payload.id]: {
          ...singleUnifiedTaxReportPrevState,
          fetching: true,
          failure: null,
        },
      };
    },
    singleUnifiedTaxReportSuccess(state, action: IPayloadAction<{ id: string; data: TSingleUnifiedTaxReportData }>) {
      const { id, data } = action.payload;
      const createTaxReportStepsState = state.createTaxReportSteps[id] || defaultCreatTaxReportSteps;

      state.singleUnifiedTaxReport = {
        ...state.singleUnifiedTaxReport,
        [id]: {
          fetching: false,
          data: action.payload.data,
          failure: null,
        },
      };

      state.createTaxReportSteps = {
        ...state.createTaxReportSteps,
        [id]: {
          ...createTaxReportStepsState,
          currentStep: ECreateTaxReportSteps.three,
          stepOne: {
            ...createTaxReportStepsState.stepOne,
            assetsCheckList: data.accounts,
            includeAmls: data.include_amls
          },
        }
      };

      // if (!!data.transactions_status) {
      //   switch (data.transactions_status.toLowerCase()) {
      //   case ESingleTaxReportTransactionStatus.forming:
      //     break;
      //   case ESingleTaxReportTransactionStatus.error:
      //     state.createTaxReportSteps[id].currentStep = 1;
      //     break;
      //   case ESingleTaxReportTransactionStatus.formed:
      //     state.createTaxReportSteps[id].currentStep = 2;
      //     break;
      //   case ESingleTaxReportTransactionStatus.confirmed:
      //     state.createTaxReportSteps[id].currentStep = 3;
      //     break;
      //   }
      // }
    },
    singleUnifiedTaxReportFailure(state, action) {
      state.singleUnifiedTaxReport = {
        ...state.singleUnifiedTaxReport,
        [action.payload.id]: {
          fetching: false,
          data: null,
          failure: action.payload.error,
        },
      };
    },

    unifiedTaxReportTransactionTypesRequest(state, action: IPayloadAction<{ id: string }>) {
      const unifiedTaxReportTransactionTypesState =
        state.unifiedTaxReportTransactionTypesState[action.payload.id] || defaultState;
      state.unifiedTaxReportTransactionTypesState = {
        ...state.unifiedTaxReportTransactionTypesState,
        [action.payload.id]: {
          ...unifiedTaxReportTransactionTypesState,
          fetching: true,
        },
      };
    },
    unifiedTaxReportTransactionTypesSuccess(state, action: IPayloadAction<{
      id: string, data: TUnifiedTaxReportTransactionTypesData }>
    ) {
      state.unifiedTaxReportTransactionTypesState = {
        ...state.unifiedTaxReportTransactionTypesState,
        [action.payload.id]: {
          fetching: false,
          data: action.payload.data,
          failure: null
        },
      };
    },
    unifiedTaxReportTransactionTypesFailure(state, action: IPayloadAction<{
      id: string, error: AxiosDataError }>
    ) {
      const unifiedTaxReportTransactionTypesState =
        state.unifiedTaxReportTransactionTypesState[action.payload.id] || defaultState;

      state.unifiedTaxReportTransactionTypesState = {
        ...state.unifiedTaxReportTransactionTypesState,
        [action.payload.id]: {
          ...unifiedTaxReportTransactionTypesState,
          fetching: false,
          failure: action.payload.error
        },
      };
    },

    unifiedTaxReportFormRequest(state, action: IPayloadAction<TUnifiedTaxReportFormOptions>) {
      const unifiedTaxReportForm = state.unifiedTaxReportFormState[action.payload.id] || defaultState;
      state.unifiedTaxReportFormState = {
        ...state.unifiedTaxReportFormState,
        [action.payload.id]: {
          ...unifiedTaxReportForm,
          fetching: true,
        }
      };
    },
    unifiedTaxReportFormSuccess(state, action: IPayloadAction<{ id: string }>) {
      state.unifiedTaxReportFormState = {
        ...state.unifiedTaxReportFormState,
        [action.payload.id]: {
          fetching: false,
          data: null,
          failure: null,
        }
      };
    },
    unifiedTaxReportFormFailure(state, action: IPayloadAction<{ id: string, error: AxiosDataError }>) {
      const unifiedTaxReportForm = state.unifiedTaxReportFormState[action.payload.id] || defaultState;
      state.unifiedTaxReportFormState = {
        ...state.unifiedTaxReportFormState,
        [action.payload.id]: {
          ...unifiedTaxReportForm,
          fetching: false,
          failure: action.payload.error,
        }
      };
    },

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    updateTaxReportAccountsRequest(state, _) {
      state.updateTaxReportAccounts = {
        fetching: true,
        data: null,
        failure: null,
      };
    },
    updateTaxReportAccountsData(state, action) {
      state.updateTaxReportAccounts = {
        fetching: false,
        data: action?.payload,
        failure: null,
      };
    },
    updateTaxReportAccountsFailure(state, action) {
      state.updateTaxReportAccounts = {
        fetching: false,
        data: null,
        failure: action?.payload,
      };
    },

    clearReportsStoreState() {
      return initialState;
    },

    reportTransactionsRequest(state: TReportsStoreState, action: IPayloadAction<TGetReportTransactionsPayload>) {
      const reportTransactionsSingle = state.reportTransactions[action.payload.id] || reportTransactionsDefault;

      state.reportTransactions = {
        ...state.reportTransactions,
        [action.payload.id]: {
          ...reportTransactionsSingle,
          fetching: true
        }
      };
    },

    reportTransactionsSuccess(state: TReportsStoreState, action: IPayloadAction<{
      id: string, data: IReportTransactionsSuccess
    }>) {
      const reportTransactionsSingle = state.reportTransactions[action.payload.id] || reportTransactionsDefault;
      const { page = 1, data, next, count } = action.payload.data;
      const formedData = data.map(transaction => ({
        ...transaction,
        formedInfo: mapper.map(transaction)
      }));

      state.reportTransactions = {
        ...state.reportTransactions,
        [action.payload.id]: {
          ...reportTransactionsSingle,
          fetching: false,
          data: page > 1 ? [...reportTransactionsSingle.data, ...formedData] : formedData,
          next,
          count,
          finish: !next,
          allCheck: !!data.length && !data.some(item => !item.checked)
        }
      };
    },

    changeReportTransactionsItemCheckStatus(
      state: TReportsStoreState,
      action: IPayloadAction<TChangeReportCheckStatusPayload>
    ) {
      const { id, checked, reportId } = action.payload;
      const reportTransactionsSingle = state.reportTransactions[reportId] || reportTransactionsDefault;

      const newData = reportTransactionsSingle.data.map((item: TReportTransactionResult) => {
        if (item.tax_report_transaction_id === id) return ({ ...item, checked, formedInfo: mapper.map(item) });
        else return item;
      });

      state.reportTransactions = {
        ...state.reportTransactions,
        [reportId]: {
          ...reportTransactionsSingle,
          data: newData,
          allCheck: !newData.some(item => !item.checked)
        }
      };
    },

    changeAllReportTransactionsItemCheckStatus(state: TReportsStoreState, action: IPayloadAction<{
      id: string, checked: boolean
    }>) {
      const reportTransactionsSingle = state.reportTransactions[action.payload.id] || reportTransactionsDefault;
      const newData = reportTransactionsSingle.data.map((item: TReportTransactionResult) => ({
        ...item,
        checked: action.payload.checked,
      }));

      state.reportTransactions = {
        ...state.reportTransactions,
        [action.payload.id]: {
          ...reportTransactionsSingle,
          data: newData,
          allCheck: action.payload.checked
        }
      };
    },

    reportTransactionsFailure(state: TReportsStoreState, action: IPayloadAction<{ id: string, error: AxiosDataError}>) {
      const reportTransactionsSingle = state.reportTransactions[action.payload.id] || reportTransactionsDefault;

      state.reportTransactions = {
        ...state.reportTransactions,
        [action.payload.id]: {
          ...reportTransactionsSingle,
          fetching: false,
          error: action.payload.error
        }
      };
    },
    changeReportTransactionsType(state, action: IPayloadAction<{ id: string, reportType: EReportTransactionType }>) {
      const reportTransactionsSingle = state.reportTransactions[action.payload.id] || reportTransactionsDefault;

      state.reportTransactions = {
        ...state.reportTransactions,
        [action.payload.id]: {
          ...reportTransactionsSingle,
          report_type: action.payload.reportType
        }
      };
    },
    changeTaxTxTypeTransactionsType(
      state,
      action: IPayloadAction<{ id: string, taxTxType: ETaxTxTypeTransactionType }>
    ) {
      const reportTransactionsSingle = state.reportTransactions[action.payload.id] || reportTransactionsDefault;

      state.reportTransactions = {
        ...state.reportTransactions,
        [action.payload.id]: {
          ...reportTransactionsSingle,
          tax_tx_type: action.payload.taxTxType
        }
      };
    },
    changeReportTransactionsShowMobileFilter(state, action: IPayloadAction<{ id: string, reportType: boolean }>) {
      const reportTransactionsSingle = state.reportTransactions[action.payload.id] || reportTransactionsDefault;

      state.reportTransactions = {
        ...state.reportTransactions,
        [action.payload.id]: {
          ...reportTransactionsSingle,
          showMobileFilter: action.payload.reportType
        }
      };
    },
    reportTransactionsDeleteItem(state, action: IPayloadAction<{ id:string, transactionId: string }>) {
      const reportTransactionsSingle = state.reportTransactions[action.payload.id] || reportTransactionsDefault;

      const newData = reportTransactionsSingle.data.filter(
        transaction => transaction.id !== action.payload.transactionId
      );

      state.reportTransactions = {
        ...state.reportTransactions,
        [action.payload.id]: {
          ...reportTransactionsSingle,
          data: newData
        }
      };
    },
    reportTransactionsSetItemValues(state, action) {
      const { id, data } = action.payload;

      const reportTransactionsSingle = state.reportTransactions[id] || reportTransactionsDefault;

      const newData = reportTransactionsSingle.data.map(transaction => {
        if (transaction.id === data.id) {
          return {
            ...transaction,
            ...data,
            formedInfo: mapper.map(data)
          };
        }
        return transaction;
      });

      state.reportTransactions = {
        ...state.reportTransactions,
        [id]: {
          ...reportTransactionsSingle,
          data: newData
        }
      };
    },
    reportTransactionsDataClear(state, action) {
      const reportTransactionsSingle = state.reportTransactions[action.payload.id] || reportTransactionsDefault;

      state.reportTransactions = {
        ...state.reportTransactions,
        [action.payload.id]: {
          ...reportTransactionsSingle,
          data: []
        }
      };
    },

    reportTaxTransactionTypesRequest(state, action: IPayloadAction<string>) {
      const reportTaxTransactionTypesSingle = state.reportTaxTransactionTypesState[action.payload] || defaultState;
      state.reportTaxTransactionTypesState = {
        ...state.reportTaxTransactionTypesState,
        [action.payload]: {
          ...reportTaxTransactionTypesSingle,
          fetching: true,
        }
      };
    },
    reportTaxTransactionTypesSuccess(state, action: IPayloadAction<{
      id: string, data: TReportTaxTransactionTypesStateData}>) {
      state.reportTaxTransactionTypesState = {
        ...state.reportTaxTransactionTypesState,
        [action.payload.id]: {
          fetching: false,
          data: action.payload.data,
          failure: null,
        }
      };
    },
    reportTaxTransactionTypesFailure(state, action: IPayloadAction<{ id: string, error: AxiosDataError }>) {
      const reportTaxTransactionTypesSingle = state.reportTaxTransactionTypesState[action.payload.id] || defaultState;
      state.reportTaxTransactionTypesState = {
        ...state.reportTaxTransactionTypesState,
        [action.payload.id]: {
          ...reportTaxTransactionTypesSingle,
          fetching: false,
          failure: action.payload.error,
        }
      };
    },

    reportTaxResultTransactionTypesRequest(state, action: IPayloadAction<string>) {
      const reportTaxResultTransactionTypesSingle =
        state.reportTaxResultTransactionTypesState[action.payload] || defaultState;

      state.reportTaxResultTransactionTypesState = {
        ...state.reportTaxResultTransactionTypesState,
        [action.payload]: {
          ...reportTaxResultTransactionTypesSingle,
          fetching: true,
        }
      };
    },
    reportTaxResultTransactionTypesSuccess(state, action: IPayloadAction<{
      id: string, data: TResultReportTaxTransactionStateData
    }>) {
      state.reportTaxResultTransactionTypesState = {
        ...state.reportTaxResultTransactionTypesState,
        [action.payload.id]: {
          fetching: false,
          data: action.payload.data,
          failure: null,
        }
      };
    },
    reportTaxResultTransactionTypesFailure(state, action: IPayloadAction<{ id: string, error: AxiosDataError }>) {
      const reportTaxResultTransactionTypesSingle =
        state.reportTaxResultTransactionTypesState[action.payload.id] || defaultState;

      state.reportTaxResultTransactionTypesState = {
        ...state.reportTaxResultTransactionTypesState,
        [action.payload.id]: {
          ...reportTaxResultTransactionTypesSingle,
          fetching: false,
          failure: action.payload.error,
        }
      };
    },

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    reportAgreementRequest(state, _) {
      state.reportAgreement = {
        fetching: true,
        data: null,
        failure: null,
      };
    },
    reportAgreementSuccess(state, action) {
      state.reportAgreement = {
        fetching: false,
        data: action.payload,
        failure: null,
      };
    },
    reportAgreementFailure(state, action) {
      state.reportAgreement = {
        data: null,
        fetching: false,
        failure: action.payload,
      };
    },

    changeReportCheckStatusRequest(state, action) {
      state.changeReportCheckStatus = {
        ...state.changeReportCheckStatus,
        [action.payload.id]: {
          ...state.changeReportCheckStatus[action.payload.id],
          fetching: true,
          failure: null,
        },
      };
    },
    changeReportCheckStatusSuccess(state, action) {
      state.changeReportCheckStatus = {
        ...state.changeReportCheckStatus,
        [action.payload.id]: {
          fetching: false,
          data: action.payload.data,
          failure: null,
        },
      };
    },
    changeReportCheckStatusFailure(state, action) {
      state.changeReportCheckStatus = {
        ...state.changeReportCheckStatus,
        [action.payload.id]: {
          fetching: false,
          data: null,
          failure: action.payload.error,
        },
      };
    },

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    changeAllReportCheckStatusRequest(state, _) {
      state.changeAllReportCheckStatus = {
        fetching: true,
        data: null,
        failure: null,
      };
    },
    changeAllReportCheckStatusSuccess(state, action) {
      state.changeAllReportCheckStatus = {
        fetching: false,
        data: action.payload,
        failure: null,
      };
    },
    changeAllReportCheckStatusFailure(state, action) {
      state.changeAllReportCheckStatus = {
        fetching: false,
        data: null,
        failure: action.payload,
      };
    },

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    createPersonalDataRequest(state, _) {
      state.createPersonalData = {
        fetching: true,
        data: null,
        failure: null,
      };
    },
    createPersonalDataSuccess(state, action) {
      state.createPersonalDataComplete.fetching = true;
      state.createPersonalData = {
        fetching: false,
        data: action.payload,
        failure: null,
      };
    },
    createPersonalDataFailure(state, action) {
      state.createPersonalData = {
        fetching: false,
        data: null,
        failure: action.payload,
      };
    },

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    createPersonalDataCompleteRequest(state, _) {
      state.createPersonalDataComplete = {
        fetching: true,
        data: null,
        failure: null,
      };
    },
    createPersonalDataCompleteSuccess(state, action) {
      state.createPersonalDataComplete = {
        fetching: false,
        data: action.payload,
        failure: null,
      };
    },
    createPersonalDataCompleteFailure(state, action) {
      state.createPersonalDataComplete = {
        fetching: false,
        data: null,
        failure: action.payload,
      };
    },

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    taxReportResultRequest(state, _) {
      state.taxReportResultState = {
        ...state.taxReportResultState,
        fetching: true,
      };
    },
    taxReportResultSuccess(state, action) {
      state.taxReportResultState = {
        failure: null,
        fetching: false,
        data: action.payload,
      };
    },
    taxReportResultFailure(state, action) {
      state.taxReportResultState = {
        data: null,
        fetching: false,
        failure: action.payload,
      };
    },

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    addTransactionToTaxReportRequest(state, _) {
      state.addTransactionToTaxReportState = {
        ...state.addTransactionToTaxReportState,
        fetching: true,
      };
    },
    addTransactionToTaxReportSuccess(state, action) {
      state.addTransactionToTaxReportState = {
        failure: null,
        fetching: false,
        data: action.payload,
      };
    },
    addTransactionToTaxReportFailure(state, action) {
      state.addTransactionToTaxReportState = {
        data: null,
        fetching: false,
        failure: action.payload,
      };
    },

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    createTransactionExportRequest(state, _) {
      state.createTransactionExportState = {
        ...state.createTransactionExportState,
        fetching: true,
      };
    },
    createTransactionExportSuccess(state, action) {
      state.createTransactionExportState = {
        failure: null,
        fetching: false,
        data: action.payload,
      };
    },
    createTransactionExportFailure(state, action) {
      state.createTransactionExportState = {
        ...state.createTransactionExportState,
        fetching: false,
        failure: action.payload,
      };
    },

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    createSourcesExportRequest(state, _) {
      state.createSourcesExportState = {
        ...state.createSourcesExportState,
        fetching: true,
      };
    },
    createSourcesExportSuccess(state, action) {
      state.createSourcesExportState = {
        failure: null,
        fetching: false,
        data: action.payload,
      };
    },
    createSourcesExportFailure(state, action) {
      state.createSourcesExportState = {
        ...state.createSourcesExportState,
        fetching: false,
        failure: action.payload,
      };
    },

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    reportSingleRequest(state, _) {
      state.reportSingleState = {
        ...state.reportSingleState,
        fetching: true,
      };
    },
    reportSingleSuccess(state, action) {
      state.reportSingleState = {
        initialLoading: false,
        fetching: false,
        data: action.payload,
        failure: null,
      };
    },
    reportSingleFailure(state, action) {
      state.reportSingleState = {
        ...state.reportSingleState,
        initialLoading: false,
        fetching: false,
        failure: action.payload,
      };
    },

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    reportsTypesRequest(state, _) {
      state.reportsTypesState = {
        ...state.reportsTypesState,
        fetching: true,
      };
    },
    reportsTypesSuccess(state, action) {
      state.reportsTypesState = {
        failure: null,
        fetching: false,
        data: action.payload,
      };
    },
    reportsTypesFailure(state, action) {
      state.reportsTypesState = {
        ...state.reportsTypesState,
        fetching: false,
        failure: action.payload,
      };
    },
  },
});

export const {
  getReportsRequest,
  getReportsFailure,
  getReportsSuccess,

  createReportRequest,
  createReportSuccess,
  createReportFailure,

  deleteReportFailure,
  deleteReportRequest,
  deleteReportSuccess,
  updateStateAfterDeletion,

  clearReportsStoreState,
  getReportTaxAmountFailure,
  getReportTaxAmountRequest,
  getReportTaxAmountSuccess,

  changeAssetsCheckList,
  changeIncludeAmlStatus,
  getCreateTaxReportAssetsListFailure,
  getCreateTaxReportAssetsListSuccess,
  getCreateTaxReportAssetsListRequest,

  createTransitionExportInit,
  setCreateTransitionExportClear,
  setCreateTransitionExportCurrentStep,
  changeCreateTransitionExportAssetsCheckList,
  changeCreateTransitionExportIncludeAmlStatus,
  getCreateTransitionExportAssetsListRequest,
  getCreateTransitionExportAssetsListSuccess,
  getCreateTransitionExportAssetsListFailure,
  setCreateTransitionExportStepTwoParams,

  createTaxReportingProjectRequest,
  createTaxReportingProjectSuccess,
  createTaxReportingProjectFailure,

  createUnifiedTaxReportRequest,
  createUnifiedTaxReportSuccess,
  createUnifiedTaxReportFailure,

  updateUnifiedTaxReportRequest,
  updateUnifiedTaxReportSuccess,
  updateUnifiedTaxReportFailure,

  singleUnifiedTaxReportRequest,
  singleUnifiedTaxReportSuccess,
  singleUnifiedTaxReportFailure,

  unifiedTaxReportTransactionTypesRequest,
  unifiedTaxReportTransactionTypesSuccess,
  unifiedTaxReportTransactionTypesFailure,

  unifiedTaxReportFormRequest,
  unifiedTaxReportFormSuccess,
  unifiedTaxReportFormFailure,

  getSingleTaxReportRequest,
  getSingleTaxReportSuccess,
  getSingleTaxReportFailure,

  changeSingleTaxReportCurrent,
  createTaxReportInit,

  getSinglePersonalDataSuccess,
  getSinglePersonalDataFailure,
  getSinglePersonalDataRequest,

  reportTransactionsFailure,
  reportTransactionsRequest,
  reportTransactionsSuccess,
  reportTransactionsDeleteItem,
  reportTransactionsSetItemValues,
  reportTransactionsDataClear,

  changeReportTransactionsType,
  changeTaxTxTypeTransactionsType,
  changeReportTransactionsShowMobileFilter,
  changeCreateTaxReportAnonymousStatus,

  reportTaxTransactionTypesRequest,
  reportTaxTransactionTypesSuccess,
  reportTaxTransactionTypesFailure,

  reportTaxResultTransactionTypesRequest,
  reportTaxResultTransactionTypesSuccess,
  reportTaxResultTransactionTypesFailure,

  reportAgreementRequest,
  reportAgreementFailure,
  reportAgreementSuccess,

  changeReportCheckStatusRequest,
  changeReportCheckStatusSuccess,
  changeReportCheckStatusFailure,

  changeReportTransactionsItemCheckStatus,
  changeAllReportTransactionsItemCheckStatus,

  changeAllReportCheckStatusSuccess,
  changeAllReportCheckStatusFailure,
  changeAllReportCheckStatusRequest,

  createPersonalDataRequest,
  createPersonalDataFailure,
  createPersonalDataSuccess,

  createPersonalDataCompleteSuccess,
  createPersonalDataCompleteFailure,
  createPersonalDataCompleteRequest,

  taxReportResultFailure,
  taxReportResultRequest,
  taxReportResultSuccess,

  updateTaxReportAccountsFailure,
  updateTaxReportAccountsData,
  updateTaxReportAccountsRequest,

  addTransactionToTaxReportRequest,
  addTransactionToTaxReportSuccess,
  addTransactionToTaxReportFailure,

  createTransactionExportRequest,
  createTransactionExportSuccess,
  createTransactionExportFailure,

  createSourcesExportRequest,
  createSourcesExportSuccess,
  createSourcesExportFailure,

  reportSingleRequest,
  reportSingleSuccess,
  reportSingleFailure,

  reportsTypesRequest,
  reportsTypesSuccess,
  reportsTypesFailure,

  resetCreateTaxReport,
} = reportsSlice.actions;

export default reportsSlice.reducer;
