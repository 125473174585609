import { FC } from 'react';
import { Dropdown } from "antd";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";

import { ECurrency } from "config/types";

import ArrowIcon from "components/Icons/ArrowIcon";

import { analyticEvent } from "store/analytics/effects";
import { EEventType } from "store/analytics/types";

import { CURRENCIES } from '../../constants';

export type TPriceActivatePaymentCurrencyProps = {
  currency: ECurrency;
  setCurrency: (val: ECurrency) => void
}

const PriceActivatePaymentCurrency: FC<TPriceActivatePaymentCurrencyProps> = ({ currency, setCurrency }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  return (
    <div className="pricing__activate__currency">
      <div className="pricing__activate__currency__text">
        {t("pricing.paymentCurrency")}
      </div>
      <Dropdown
        menu={{
          items: CURRENCIES.map(($currency, index) => ({
            key: index,
            disabled: $currency.disabled,
            label: $currency.currency,
            onClick: () => {
              setCurrency($currency.currency);
              if (currency !== $currency.currency) {
                dispatch(analyticEvent(EEventType.PAYMENT_STEP1_CURRENCY_CHANGED));
              }
            },
          }))
        }}
        trigger={["click"]}
        placement="bottomLeft"
        className="pricing__activate__currency__dropdown__container"
        overlayClassName="pricing__activate__currency__dropdown"
      >
        <div>{currency} <ArrowIcon className="arrow"/></div>
      </Dropdown>
    </div>
  );
};

export default PriceActivatePaymentCurrency;
