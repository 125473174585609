import { FC } from 'react';

import Pricing from 'components/Pricing';

import { usePricingState } from "../Pricing/hooks";

export type TPricingPageProps = {
  showHeader?: boolean;
  isModal?: boolean;
}
const PricingPage: FC<TPricingPageProps> = ({ showHeader, isModal }) => {
  const [plan, setPlan] = usePricingState();

  return <Pricing plan={plan} setPlan={setPlan} showHeader={showHeader} isModal={isModal}/>;
};

export default PricingPage;