import { TBNDropdownChildren, TBNDropdownItemProps } from "components/BNDropdown/types";

export enum EAssetsSingleMenuStatesKeys {
  delete = 'delete',
  rename = 'rename',
  connect = 'connect',
}

export type TAssetsSingleMenuState = Record<EAssetsSingleMenuStatesKeys, boolean>

export type TAssetsSingleMenuDropdownProps = {
  setVisibleTrue: (value: EAssetsSingleMenuStatesKeys) => void
}

export type TAssetSingleMenuItemProps = Pick<TBNDropdownChildren, "setActive"> & TBNDropdownItemProps

