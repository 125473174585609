import i18n from "../../../../i18n";

import { TFeatures } from "../PricingCardFeatures";

export const availableFeatures = (): TFeatures => ( [
  [
    {
      title: i18n.t("pricingFeatures.portfolioTracker"),
      subTitle: null,
      checked: true
    },
    {
      title: i18n.t("pricingFeatures.unlimitedAPIs"),
      subTitle: null,
      checked: true
    },
    {
      title: i18n.t("pricingFeatures.wallets10kTransactions"),
      subTitle: null,
      checked: false
    },
    {
      title: i18n.t("pricing.notesAndTags"),
      subTitle: i18n.t("pricingFeatures.pcsPerMonth", { number: 25 }),
      checked: true
    },
    {
      title: i18n.t("pricingFeatures.proofDocumentStorage"),
      subTitle: i18n.t("pricingFeatures.numberMB", { number: 50 }),
      checked: true
    }
  ],
  [
    {
      title: i18n.t("pricingFeatures.amlControl"),
      checked: false,
    },
    {
      title: i18n.t("pricingFeatures.amlChecks"),
      checked: false,
    },
  ],
  [
    {
      title: i18n.t("pricingFeatures.taxForms"),
      // subTitle: i18n.t("pricingFeatures.numberTransactionsCurrentYear", { number: 50 }),
      checked: false
    },
    {
      title: i18n.t("pricingFeatures.proofOfSourceOfFunds"),
      checked: false
    },
    {
      title: i18n.t("pricingFeatures.transactionsExport"),
      subTitle: i18n.t("pricingFeatures.last35Days"),
      checked: true
    },
    {
      title: i18n.t("pricingFeatures.advancedCryptoDocuments"),
      checked: false
    },
  ],
  [
    {
      title: i18n.t("pricingFeatures.oKmanBotAndOnlineChat"),
      checked: true
    },
    {
      title: i18n.t("pricingFeatures.academy"),
      checked: false
    },
    {
      title: i18n.t("pricingFeatures.emailSupport"),
      checked: false
    },
  ]
]);
