import { FC } from 'react';
import { useGoogleLogin } from '@react-oauth/google';
import { useDispatch } from "react-redux";

import GoogleIcon from "components/Icons/GoogleIcon";

import { authorizeOAuthRequest } from "store/auth/reducers";
import { TOAuthProvider } from "store/auth/types";

import { analyticEvent } from "../../../../store/analytics/effects";
import { EEventType } from "../../../../store/analytics/types";

import { TIsSignIn } from "../../types";

const { REACT_APP_REDIRECT_URI } = process.env;

const AuthGoogle: FC<TIsSignIn> = () =>{
  const dispatch = useDispatch();

  const login = useGoogleLogin({
    onSuccess: tokenResponse => {
      dispatch(authorizeOAuthRequest({
        provider: TOAuthProvider.googleOauth2,
        code: tokenResponse.code,
      }));
    },
    flow: 'auth-code',
    redirect_uri: REACT_APP_REDIRECT_URI,
  });

  if (!process.env.REACT_APP_GOOGLE_CLIENT_ID) {
    return <></>;
  }

  return (
    <div className="item" onClick={() => {
      dispatch(analyticEvent(EEventType.AUTH_OAUTH_GOOGLE_CLICK));
      login();
    }} >
      <div>
        <GoogleIcon />
      </div>
      <span>Google</span>
    </div>
  );
};

export default AuthGoogle;