import { FC, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import { ECurrency } from "config/types";
import { getAccountPublicPath, getPublicAccountId, paths } from "config/paths";

import VectorIcon from "components/Icons/VectorIcon";
import { useLayoutAppNameContext } from "components/Layout/hooks";

import { publicAccountAssetsStateSelector, publicAccountInfoStateSelector } from "store/public/selectors";

import { abbreviateCurrency } from "utils/fractions";

import { publicAccountAssetsRequest, publicAccountInfoRequest } from "../../../../../store/public/reducers";

const PublicHeaderAccount: FC = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const publicAccountId = getPublicAccountId();
  const { routePath } = useLayoutAppNameContext();
  const { data: publicAccountInfo, fetching: publicAccountInfoFetching } = useSelector(publicAccountInfoStateSelector);
  const { data: publicAccountAssets, fetching: publicAccountAssetsFetching }
    = useSelector(publicAccountAssetsStateSelector);
  
  const vectorBack: Record<string, () => void> = useMemo(() => ({
    [paths.PUBLIC_ACCOUNT_TRANSACTIONS]: () => {
      if (!publicAccountId) return;
      history.push(getAccountPublicPath(publicAccountId));
    },
  }), [publicAccountId]);

  const handleClickBack = () => {
    if (!routePath) return;

    const call = vectorBack[routePath];
    if (call) call();
  };

  useEffect(() => {
    if (!publicAccountInfo && !publicAccountInfoFetching) {
      dispatch(publicAccountInfoRequest({ id: publicAccountId }));
    }
    if (!publicAccountAssets && !publicAccountAssetsFetching) {
      dispatch(publicAccountAssetsRequest({ id: publicAccountId }));
    }
  }, []);

  return <div className="public-header__account" onClick={handleClickBack}>
    <VectorIcon className="public-header__back" onClick={handleClickBack}/>
    <div className="public-header__account__icon">
      {publicAccountInfo?.icon
        ? <img src={publicAccountInfo?.icon} alt="assets-icon"/>
        : <div className="public-header__account__icon__skeleton"/>}
    </div>
    <div className="public-header__account__data">
      <div className="public-header__account__data__top">
        <span className="public-header__account__data__name">{publicAccountInfo?.name}</span>
      </div>
      <div className="public-header__account__data__const">
        {abbreviateCurrency(
          publicAccountAssets?.currency || ECurrency.rub,
          Number(publicAccountAssets?.total_cost) || 0)}
      </div>
    </div>
  </div>;
};

export default PublicHeaderAccount;