import { FC } from 'react';

import { IIconsProps } from "config/types";

{/* eslint-disable max-len */}
const UpgradePlanNextIcon:FC<IIconsProps> = ({ className, onClick }):JSX.Element => (
  <svg width="72" height="72" viewBox="0 0 72 72" fill="none" xmlns="http://www.w3.org/2000/svg" className={className} onClick={onClick}>
    <circle cx="36" cy="36" r="36" fill="#D64686"/>
    <path d="M36 50L23.8756 29L48.1244 29L36 50Z" fill="white"/>
  </svg>
);

export default UpgradePlanNextIcon;
