import { FC, useMemo, createElement } from 'react';
import { Dropdown } from "antd";
import cn from "classnames";
import isFunction from "lodash/isFunction";
import { ItemType } from "antd/es/menu/hooks/useItems";

import ArrowIcon from "../Icons/ArrowIcon";

export type DropdownSelectOption = {
  label: string;
  value: string|null|number;
  icon?:  () => JSX.Element
}


export type DropdownSelectorProps = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  value: any;
  options: DropdownSelectOption[];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onSelect?: (value: any) => void;
  placeholder?: string
  disabled?: boolean;
  className?: string;
  overlayClassName?: string;
  contentClassName?: string;
  error?: boolean
}

const DropdownSelector: FC<DropdownSelectorProps> = ({
  value,
  options,
  onSelect,
  disabled,
  className,
  placeholder,
  overlayClassName,
  contentClassName,
  error
}) => {
  const selected = options.find(option => option.value === value);
  const handleOnClick = ($value: string | number | null) => {
    if (isFunction(onSelect)) onSelect($value);
  };

  const items = useMemo(() => (
    options.map(option => ({
      key: option.value,
      className: cn({ "selected": option.value === value }),
      onClick: () => handleOnClick(option.value),
      label: option.label
    }))
  ), [value, options, onSelect]) as ItemType[];

  return (
    <div className={cn("dropdown-selector", className, { disabled })}>
      <Dropdown
        disabled={disabled}
        menu={{ items, className: "dropdown-selector__overlay" }}
        trigger={["click"]}
        placement="bottomLeft"
        className={cn("dropdown-selector__content", contentClassName, { disabled, error })}
        overlayClassName={cn("dropdown-selector__dropdown", overlayClassName)}
      >
        {selected ? <div>
          {selected?.icon && <span className="dropdown-selector__icon">{createElement(selected.icon)}</span>}
          {selected?.label} <ArrowIcon className="arrow"/>
        </div> : <div className="dropdown-selector__placeholder">{placeholder}<ArrowIcon className="arrow"/></div>}
      </Dropdown>
    </div>
  );
};

export default DropdownSelector;
