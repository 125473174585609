import { useFormikContext } from "formik";
import { useEffect } from "react";
import isEqual from "lodash/isEqual";

export type FormObserverProps<T> = {
  onChangeValues: (value: T) => void
}

const FormObserver = <T,>({ onChangeValues }: FormObserverProps<T>): null => {
  const { values, initialValues } = useFormikContext<T>();

  useEffect(() => {
    if (!isEqual(values, initialValues)) {
      onChangeValues(values);
    }
  }, [values]);

  return null;
};

export default FormObserver;
