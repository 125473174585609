import { FC, useMemo, useState, TouchEvent } from "react";
import { useTranslation } from "react-i18next";
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import { useDispatch, useSelector } from "react-redux";
import cn from "classnames";

import OkManIcon from "components/Icons/OkManIcon";
import Button from "components/Buttons/Button";

import { openModal } from "store/modals/reducers";
import { EModals } from "store/modals/types";
import { dashboardOnboardingFinish } from "store/common/actions";
import { dashboardOnboardingSelector } from "store/common/selectors";

import { ELanguages } from "../../../../../../i18n/constants";

const getSteps = (lng: ELanguages = ELanguages.enUS) => [
  {
    title: 'dashboardOnboarding.itsOverview',
    content: "dashboardOnboarding.itsOverviewText",
    img: <img src={process.env.PUBLIC_URL + `/img/mobile-onboarding/${lng}/dashboard.png`}/>
  },
  {
    title: 'dashboardOnboarding.justConnectAccounts',
    content: "dashboardOnboarding.justConnectAccountsText",
    img: <img src={process.env.PUBLIC_URL + `/img/mobile-onboarding/${lng}/accounts.png`}/>
  },
  {
    title: 'dashboardOnboarding.allTransactions',
    content: "dashboardOnboarding.allTransactionsText",
    img: <img src={process.env.PUBLIC_URL + `/img/mobile-onboarding/${lng}/transaction.png`}/>
  },
  {
    title: 'dashboardOnboarding.controlYourRisks',
    content: "dashboardOnboarding.controlYourRisksText",
    img: <img src={process.env.PUBLIC_URL + `/img/mobile-onboarding/${lng}/risks.png`}/>
  },
  {
    title: 'dashboardOnboarding.formAnyDocs',
    content: "dashboardOnboarding.formAnyDocsText",
    img: <img src={process.env.PUBLIC_URL + `/img/mobile-onboarding/${lng}/documents.png`}/>
  },
  {
    title: 'dashboardOnboarding.analyzeYourTrades',
    content: "dashboardOnboarding.analyzeYourTradesText",
    img: <img src={process.env.PUBLIC_URL + `/img/mobile-onboarding/${lng}/trading-insights.png`}/>
  },
];

const DashboardMobileOnboardingCarousel: FC = () => {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const { howToUse } = useSelector(dashboardOnboardingSelector);
  const [selected, setSelected] = useState(0);

  const close = () => {
    dispatch(dashboardOnboardingFinish());
    if (howToUse) {
      dispatch(openModal(EModals.assetModal));
    }
  };

  const steps = useMemo(() => getSteps(i18n.language as ELanguages), [i18n.language]);

  const onClickNext = () => {
    if (selected+1 === steps.length) {
      close();
    } else {
      setSelected(selected + 1);
    }
  };

  const [touchStart, setTouchStart] = useState<number|null>(null);
  const [touchEnd, setTouchEnd] = useState<number|null>(null);

  // the required distance between touchStart and touchEnd to be detected as a swipe
  const minSwipeDistance = 50;

  const onTouchStart = (e: TouchEvent<HTMLDivElement>) => {
    setTouchEnd(null); // otherwise the swipe is fired even with usual touch events
    setTouchStart(e.targetTouches[0].clientX);
  };

  const onTouchMove = (e: TouchEvent<HTMLDivElement>) => setTouchEnd(e.targetTouches[0].clientX);

  const onTouchEnd = () => {
    if (!touchStart || !touchEnd) return;
    const distance = touchStart - touchEnd;
    const isLeftSwipe = distance > minSwipeDistance;
    const isRightSwipe = distance < -minSwipeDistance;
    if (isLeftSwipe) {
      if (selected+1 === steps.length) {
        close();
      } else {
        setSelected(selected + 1);
      }
    }
    if (isRightSwipe && selected > 0) {
      setSelected(selected - 1);
    }
  };

  return (
    <div className="dashboard-mobile-onboarding__carousel"
      onTouchStart={onTouchStart} onTouchMove={onTouchMove} onTouchEnd={onTouchEnd}
    >
      <div className="dashboard-mobile-onboarding__carousel__img">
        <TransitionGroup>
          <CSSTransition
            key={selected}
            classNames="auth-carousel__transition"
            timeout={350}
          >
            {steps[selected].img}
          </CSSTransition>
        </TransitionGroup>
      </div>

      <div className="dashboard-mobile-onboarding__carousel__content">
        <div className="dashboard-mobile-onboarding__carousel__steps">
          {steps.map((_, index) => (
            <div
              key={index}
              className={cn("dashboard-mobile-onboarding__carousel__step", { active: selected === index })}
            />
          ))}
        </div>
        <div className="dashboard-mobile-onboarding__carousel__title">
          <div className="dashboard-mobile-onboarding__carousel__title__icon">
            <OkManIcon/>
          </div>
          {t(steps[selected].title)}
        </div>
        <div className="dashboard-mobile-onboarding__carousel__text">
          {t(steps[selected].content)}
        </div>
        <div className="dashboard-mobile-onboarding__carousel__footer">
          {selected !== 0 && (
            <Button
              onClick={close}
              title={t("naming.skip")}
              transparent={true}
              className="dashboard-mobile-onboarding__carousel__skip"/>
          )}
          <Button
            onClick={onClickNext}
            transparent={true}
            className="dashboard-mobile-onboarding__carousel__next"
            title={t("naming.next")}
          />
        </div>
      </div>
    </div>
  );
};

export default DashboardMobileOnboardingCarousel;
