import { FC } from "react";

import SyncIcon from "../Icons/SyncIcon";
import SyncLoader from "../SyncLoader";

export type TSyncProps = {
  loading?: boolean;
  onClick: () => void;
};

const Sync: FC<TSyncProps> = ({ loading, onClick }) => (
  <div className="sync">
    {!loading ? (
      <SyncIcon onClick={onClick}/>
    ) : (
      <SyncLoader isSpin={true} />
    )}
  </div>
);

export default Sync;