import axios, { AxiosResponse } from "axios";
import { put, takeLatest, all, call, select } from "redux-saga/effects";
import isEmpty from "lodash/isEmpty";

import { LinkIds } from "../../config/constants";

import { TMyAssetsData } from "../assets/types";
import { IApplicationState, IPayloadAction } from "../rootInterface";
import { openModal } from "../modals/reducers";
import { EModals } from "../modals/types";

import {
  getCountriesFailure,
  getCountriesISOFailure,
  getCountriesISORequest,
  getCountriesISOSuccess,
  getCountriesRequest,
  getCountriesSuccess,
  getTimezonesFailure,
  getTimezonesRequest,
  getTimezonesSuccess,
  setDashboardOnboarding,
} from "./reducers";
import {
  START_DASHBOARD_ONBOARDING,
  START_DASHBOARD_ONBOARDING_HOW_TO_USE,
  NOT_REDIRECT_ON_CREATE_ASSETS,
  CLOSE_MODAL_ACTION_ON_ONBOARDING_STATE,
  DASHBOARD_ONBOARDING_FINISH,
  SET_DASHBOARD_ONBOARDING_TARGET,
} from "./actions";


function* getCountries() {
  // eslint-disable-next-line max-len
  // load countries from "https://data.gov.ru/sites/default/files/opendata/7710168515-ObscherossiyskiyklassifikatorstranmiraOXM/data-2016-09-21T00-00-00-structure-2016-09-21T00-00-00.json";

  try {
    const response: AxiosResponse = yield call(axios.get, '/countries.json');

    yield put(getCountriesSuccess(response.data));
  } catch (e) {
    yield put(getCountriesFailure(e));
  }
}

function* getCountriesISO() {
  try {
    const response: AxiosResponse = yield call(axios.get, '/country-ISO-3166-1.json');

    yield put(getCountriesISOSuccess(response.data));
  } catch (e) {
    yield put(getCountriesISOFailure(e));
  }
}

function* getTimezones() {
  try {
    const response: AxiosResponse = yield call(axios.get, '/timezones.json');
    yield put(getTimezonesSuccess(response.data));
  } catch (e) {
    yield put(getTimezonesFailure(e));
  }
}


function* _startDashboardOnboarding() {
  const myAssets: TMyAssetsData[] =
    yield select((state: IApplicationState) => state.assets.myAssetsState.data);

  yield put(setDashboardOnboarding({
    isRun: true,
    fake: isEmpty(myAssets),
    target: "#" + LinkIds.OVERVIEW,
  }));
}

function* _startDashboardOnboardingHowToUse() {
  yield put(setDashboardOnboarding({
    isRun: true,
    fake: true,
    howToUse: true,
    target: "#" + LinkIds.OVERVIEW
  }));
}

function* _setDashboardOnboardingTarget(action: IPayloadAction<string>) {
  yield put(setDashboardOnboarding({
    target: action.payload,
  }));
}

function* _notRedirectOnCreateAssets() {
  yield put(setDashboardOnboarding({
    redirectOnCreateAssets: false,
  }));
}

function* _closeModalActionOnOnboardingState() {
  yield put(setDashboardOnboarding({
    redirectOnCreateAssets: true,
  }));
}

function* _dashboardOnboardingFinish() {
  const howToUse: boolean =
    yield select((state: IApplicationState) => state.common.dashboardOnboardingState.howToUse);
  const myAssets: TMyAssetsData[] =
    yield select((state: IApplicationState) => state.assets.myAssetsState.data);

  const isFake: boolean|undefined =
    yield select((state: IApplicationState) => state.assets.myAssetsState.isFake);

  yield put(setDashboardOnboarding({
    isRun: false,
    fake: false,
    target: null
  }));

  if (howToUse && (isEmpty(myAssets) || isFake)) {
    yield put(openModal(EModals.assetModal));
  }
}

function* Saga(): Generator {
  yield all([
    takeLatest(getCountriesRequest.type, getCountries),
    takeLatest(getCountriesISORequest.type, getCountriesISO),
    takeLatest(getTimezonesRequest.type, getTimezones),
    takeLatest(START_DASHBOARD_ONBOARDING, _startDashboardOnboarding),
    takeLatest(START_DASHBOARD_ONBOARDING_HOW_TO_USE, _startDashboardOnboardingHowToUse),
    takeLatest(SET_DASHBOARD_ONBOARDING_TARGET, _setDashboardOnboardingTarget),
    takeLatest(NOT_REDIRECT_ON_CREATE_ASSETS, _notRedirectOnCreateAssets),
    takeLatest(CLOSE_MODAL_ACTION_ON_ONBOARDING_STATE, _closeModalActionOnOnboardingState),
    takeLatest(DASHBOARD_ONBOARDING_FINISH, _dashboardOnboardingFinish),
  ]);
}

export default Saga;
