import { FC } from "react";

import { IIconsProps } from "config/types";

{/* eslint-disable max-len */}
const CarouselVectorIcon: FC<IIconsProps> = ({ className, onClick }) => (
  <svg width="24" height="16" viewBox="0 0 24 16" fill="none" xmlns="http://www.w3.org/2000/svg" className={className} onClick={onClick}>
    <g clipPath="url(#clip0_9503_3784)">
      <path d="M12.6744 12.5304L22.1056 4.48036C22.1305 4.45932 22.1459 4.43404 22.1502 4.40742C22.1544 4.3808 22.1473 4.35392 22.1297 4.32987C22.1121 4.30581 22.0846 4.28556 22.0505 4.27143C22.0164 4.2573 21.977 4.24987 21.9369 4.25H19.8663C19.7351 4.25 19.6092 4.29107 19.5288 4.35893L11.9994 10.7875L4.46991 4.35893C4.38955 4.28929 4.26366 4.25 4.13241 4.25H2.06187C1.88241 4.25 1.7833 4.3875 1.89312 4.48036L11.3244 12.5304C11.4044 12.5988 11.5068 12.6541 11.6238 12.6922C11.7408 12.7303 11.8692 12.7501 11.9994 12.7501C12.1295 12.7501 12.258 12.7303 12.3749 12.6922C12.4919 12.6541 12.5943 12.5988 12.6744 12.5304Z" fill="#B9BBC2"/>
    </g>
    <defs>
      <clipPath id="clip0_9503_3784">
        <rect width="16" height="24" fill="white" transform="translate(24) rotate(90)"/>
      </clipPath>
    </defs>
  </svg>
);

export default CarouselVectorIcon;
