import { createSelector } from "@reduxjs/toolkit";

import { IApplicationState } from "../rootInterface";

const selectState = (state: IApplicationState) => state.common;

export const countriesSelector = createSelector(selectState, common => common?.countriesState);
export const countriesISOSelector = createSelector(selectState, common => common?.countriesISOState);
export const dashboardOnboardingSelector = createSelector(selectState, common => common?.dashboardOnboardingState);
export const timezonesSelector = createSelector(selectState, common => common?.timezonesState);
export const authCarouseSelector = createSelector(selectState, common => common?.authCarouseState);
export const mobileAdditionalMenuSelector = createSelector(selectState, common => common?.mobileAdditionalMenu);
export const headerDrawerSelector = createSelector(selectState, common => common?.headerDrawer);
