import { createContext, FC, useCallback, useContext, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { Radio } from "antd";
import { useHistory } from "react-router";

import ModalWrapper from "components/ModalWrapper";
import Button from "components/Buttons/Button";

import { modalStateSelector } from "store/modals/selectors";
import { EModals } from "store/modals/types";
import { closeModal } from "store/modals/reducers";
import { EServiceCode, ETariffPlan } from "store/services/types";

import AMLActivatePacks from "../AMLActivatePacks";

type TFormValueProps = {
  code: EServiceCode
  plan?: ETariffPlan
}
type TAMLActivateModalContextProps = {
  formValue?: (val: TFormValueProps) => void
}
export const AMLActivateModalContext = createContext<TAMLActivateModalContextProps>({});
export const useAMLActivateModalContext = (): TAMLActivateModalContextProps => useContext(AMLActivateModalContext);

const AMLActivateModal: FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const visible = useSelector(modalStateSelector(EModals.amlActivate));
  const [value, setValue] = useState<EServiceCode>();

  const close = () => {
    dispatch(closeModal(EModals.amlActivate));
    setValue(undefined);
  };

  const handleNext = useCallback(() => {
    if (!value) return;

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [code, plan] = value.split("/");

    let search = `?code=${code}&modal=${EModals.activatePricing}`;

    if (plan) {
      search = search + `&tariff=${plan}`;
    }

    history.push({ search });
    dispatch(closeModal(EModals.amlActivate));
    setValue(undefined);
  }, [value, history]);

  const formValue = ({ code, plan }: TFormValueProps) => {
    if (plan) return code + "/" + plan;
    return code;
  };

  return <ModalWrapper // ---
    visible={!!visible}
    title={t("amlPricing.amlActivation")}
    closeModal={close}
    className="pricing__activate__modal"
    destroyOnClose={true}
    width={636}
  >
    <Radio.Group
      className="aml-activate-modal__radio-group"
      onChange={e => setValue(e.target.value)}
      value={value}
    >
      <AMLActivateModalContext.Provider value={{ formValue }}>
        <AMLActivatePacks/>
      </AMLActivateModalContext.Provider>
    </Radio.Group>
    <Button title={t("naming.next")} className="aml-activate-modal__next" onClick={handleNext}/>
  </ModalWrapper>;
};

export default AMLActivateModal;
