import { FC } from "react";

import { IIconsProps } from "config/types";

{/* eslint-disable max-len */}
const ShareIcon: FC<IIconsProps> = ({ className, onClick }) => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" className={className} onClick={onClick}>
    <path d="M12 2C6.5 2 2 6.5 2 12C2 17.5 6.5 22 12 22C17.5 22 22 17.5 22 12C22 6.5 17.5 2 12 2ZM14 16V13C10.39 13 7.81 14.43 6 17C6.72 13.33 8.94 9.73 14 9V6L19 11L14 16Z" fill="#626368"/>
  </svg>

);

export default ShareIcon;
