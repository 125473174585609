import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import { useParams } from "react-router-dom";
import get from "lodash/get";

import { EQueries } from "config/types";

import { userPlanInfoSelector } from "store/user/selectors";
import { openModal } from "store/modals/reducers";
import { EModals } from "store/modals/types";
import { EReportType, EReportMode } from "store/reports/types";

import { reportsTypesSelector, reportTransactionSingleSelector } from "../store/reports/selectors";
import { ETariffPlan } from "../store/services/types";

import useQuery from "./useQuery";

export enum ELimitations {
  directToPlan = "directToPlan",
  note = "note",
  draft = "draft",
  limit_35_days = "limit_35_days",
  aml_checks = "aml_checks",
  limit_1000_txs = "limit_1000_txs",
}
export type TUsePlanLimitationProps = {
  limitType: ELimitations
  reportType?: EReportType
}
export type TUsePlanLimitationReturn = {
  modeIsDraft: boolean
  reachedNoteLimit: boolean
  modeIsLimit35Days: boolean
  openUpgradePlan: () => void
  loadReportTypes?: boolean
  fullMode: boolean
  isFreePlan: boolean
  isLimit1000txs: boolean
}

export const usePlanLimitation = ({
  limitType,
  reportType,
}: TUsePlanLimitationProps): TUsePlanLimitationReturn => {
  const history = useHistory();
  const dispatch = useDispatch();
  const query = useQuery();
  const { data: planInfo } = useSelector(userPlanInfoSelector);
  const { data: reportTypes } = useSelector(reportsTypesSelector);
  const { id } = useParams<{id : string}>();
  const reportTransactionSingle = useSelector(reportTransactionSingleSelector(id));
  const reportTransactionsCount = get(reportTransactionSingle, ["count"], 0) as number;
  const reportMode = reportTypes?.find(item => item.code === reportType)?.params.mode;

  const reachedNoteLimit = (planInfo && planInfo?.notes?.used && planInfo?.notes?.max)
    ? planInfo?.notes?.used >= planInfo?.notes?.max : false;
  const modeIsLimit35Days = reportType === EReportType?.transaction_export
    && reportMode === EReportMode.limit_35_days;
  const modeIsDraft = reportMode === EReportMode.draft;
  const isFreePlan = !!(planInfo && planInfo.current_plan && planInfo.current_plan.tariff_plan === ETariffPlan.free);
  const isLimit1000txs = reportTransactionsCount > 1000 && reportMode === EReportMode.limit_1000_txs;

  const openUpgradePlan = () => {
    let limit = ELimitations.directToPlan;
    if (reachedNoteLimit && limitType === ELimitations.note) {
      limit = ELimitations.note;
    } else if (modeIsLimit35Days && limitType === ELimitations.limit_35_days) {
      limit = ELimitations.limit_35_days;
    } else if (modeIsDraft && limitType === ELimitations.draft) {
      limit = ELimitations.draft;
    } else if (isFreePlan && limitType === ELimitations.aml_checks) {
      limit = ELimitations.aml_checks;
    } else if (isLimit1000txs && limitType === ELimitations.limit_1000_txs) {
      limit = ELimitations.limit_1000_txs;
    }

    if (limit) {
      query.append(EQueries.limit, limit);
      if (reportType) query.append(EQueries.reportType, reportType);
      history.replace({
        search: query.toString()
      });
    }
    dispatch(openModal(EModals.upgradePlan));
  };

  return {
    modeIsDraft,
    modeIsLimit35Days,
    reachedNoteLimit,
    isFreePlan,
    openUpgradePlan,
    fullMode: reportMode === EReportMode.full,
    isLimit1000txs
  };
};
