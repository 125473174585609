import { FC } from "react";

import { usePublicChildLoaderContext } from "../../Layout/components/PublicChildLoader";

import PublicMobileLeft from "./components/PublicMobileLeft";
import PublicMobileRight from "./components/PublicMobileRight";
import PublicMobileTitle from "./components/PublicMobileTitle";
import PublicMobileSettingsDrawer from "./components/PublicMobileSettingsDrawer";

const PublicMobileHeader: FC = () => {
  const { loading, show404 } = usePublicChildLoaderContext();
  return (
    <div className="header-mobile__wrapper container mob-p-16">
      { !loading && !show404 && <PublicMobileLeft/>}
      <div className="header-mobile">
        <PublicMobileTitle/>
      </div>
      { !loading && !show404 && <PublicMobileRight/>}
      <PublicMobileSettingsDrawer/>
    </div>
  );
};

export default PublicMobileHeader;