import { ERiskStatus } from "components/FormedRiskIcon/types";

export const prepareRiskValue = (risk?: number): number => risk ? Math.ceil(risk * 100) : 0;

export const getRiskStatus = (risk?: number):ERiskStatus => {
  const $risk = prepareRiskValue(risk);
  if ($risk >= 75) {
    return ERiskStatus.ERROR;
  } else if ($risk >= 25) {
    return ERiskStatus.WARNING;
  } else {
    return ERiskStatus.OK;
  }
};



