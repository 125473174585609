{/* eslint-disable max-len */}

import { RGB } from "utils/hexToRgb";

// import { IPaginationResponse, any } from "../../api/types";
import { TNullable } from "../../config/types";

// import { AlertStatus, EAlertSubjects, EProximity, TExposure } from "../alerts/types";
import { IPaginationResponse, TRequestHandler } from "../rootInterface";

import { EExposureStatus, TPaginationOptions } from "./effects";
// import { ECounterpartyStatus, EExposureStatus, any, any } from "../transfers/types";

export enum EAmlCheckType {
  deposit = "deposit",
  withdrawal = "withdrawal",
  single_address = "single_address",
}

export type TAmlMobileFilterGroups = {
  group: EAmlCheckGroups,
  count: number
}

export enum ERiskLevel {
  no_risk = "no_risk",
  none = "none",
  unknown = "unknown",
  low = "low",
  medium = "medium",
  high = "high",
  severe = "severe",
}

export type TAmlCheck = {
  id: string
  created_at: string
  type: EAmlCheckType
  risk_level: TNullable<ERiskLevel>
  network_code: string
  network_name: string
  subject: string
  status?: 'error' | 'checked' | 'checking' | null;
  asset_symbol: TNullable<string>
  asset_amount: TNullable<number>
}
export type TAmlChecksData = IPaginationResponse<TAmlCheck>

export enum TAmlChecksFilterKeys {
  type = "type",
  group = 'group',
  risk_level = "risk_level",
  network = "network",
  date_from = "date_from",
  date_to = "date_to",
  search = "search",
  date_range = "date_range"
}
// export enum EAmlChecksCheckTypes {
//   awaiting = "awaiting",
//   awaitingPartialPaid = "awaiting_partial_paid",
//   completing = "completing",
//   completed = "completed",
//   expired = "expired",
//   actionRequiredPartialPaid = "action_required_partial_paid",
//   actionRequiredAMLFailed = "action_required_aml_failed",
//   actionRequiredAMLCompleting = "action_required_aml_completing",
//   canceled = "canceled",
// }
export type TAmlChecksFilter = {
  [TAmlChecksFilterKeys.type]?: EAmlCheckType
  [TAmlChecksFilterKeys.group]?: EAmlCheckGroups
  [TAmlChecksFilterKeys.risk_level]?: ERiskLevel
  [TAmlChecksFilterKeys.network]?: string
  [TAmlChecksFilterKeys.date_from]?: TNullable<string>
  [TAmlChecksFilterKeys.date_to]?: TNullable<string>
  [TAmlChecksFilterKeys.date_range]?: TNullable<string>
  [TAmlChecksFilterKeys.search]?: string
}
export type TAmlChecksOptions = {
  filter?: TAmlChecksFilter
  pagination?: TPaginationOptions
  infiniteScroll?: boolean;
}
export type TAmlChecksState = TRequestHandler<TAmlChecksData> & {
  initialLoading: boolean
}

export type TAmlChecksCheckTransferBody = {
  token: string // - ID токена в формате UUID.
  tx_hash: string // - хэш транзакции, где произошел трансфер.
  output_address: string // - адрес получения в трансфере (и для входящих, и для исходящих трансферов).
  check_type: string // - тип проверки. Возможные значения: deposit, withdrawal.
}
export type TAmlChecksCheckTransferOptions = {
  body: TAmlChecksCheckTransferBody
  callOnSuccess?: (id: string) => void
}
export type TAmlChecksCheckTransfer = {
  id: string
}
export type TAmlChecksCheckTransferState = TRequestHandler<TAmlChecksCheckTransfer>

export type TAmlChecksCheckAddressBody = {
  network: string // - ID сети в формате UUID.
  token: TNullable<string> // - ID токена в формате UUID. Может быть null, если осуществляется проверка по всем токенам.
  address: string // - проверяемый адрес.
}

export type TAmlChecksCheckAddressOptions = {
  body: TAmlChecksCheckAddressBody
  callOnSuccess?: (id: string) => void
}
export type TAmlChecksCheckAddress = {
  id: string
}
export type TAmlChecksCheckAddressState = TRequestHandler<TAmlChecksCheckAddress>

export type TAmlCheckAddressInfo = {
  address: string // - адрес.
  network_code: string // - код сети.
  network_name: string // - название сети.
  balance_in_fiat: TNullable<number> // - баланс (в фиате) на момент проверки. Может быть null.
  transaction_count: TNullable<number> // - число транзакций на момент проверки. Может быть null.
  inflow_in_fiat: TNullable<number> // - сумма входящих транзакций в фиате на момент проверки. Может быть null.
  outflow_in_fiat: TNullable<number> // - сумма исходящих транзакций в фиате на момент проверки. Может быть null.
  first_transaction_at: TNullable<string> // - дата и время первой транзакции. Может быть null.
  last_transaction_at: TNullable<string> // - дата и время последней транзакции. Может быть null.
}
export type TExposure = {
  entity_category: string
  share: number
  risk_score: number
  color?: string // formed after load
  rgb?: RGB // formed after load
}

export type TAmlCheckAddressExposure = {
  checked_at: TNullable<string> // - время проверки экспожура. Может быть null.
  entity_name: TNullable<string> // - название выявленной сущности (сервиса, компании, лица), к которой относится контрагент. Может быть null.
  entity_category: TNullable<string> // - название выявленной категории сущности, к которой относится контрагент. Может быть null.
  entity_risk_score: TNullable<number> // - уровень выявленной риска категории, к которой относится контрагент. От 0 до 1. Может быть null.
  report_url: TNullable<string> // - ссылка на PDF генератор
  exposure: TNullable<TExposure[]> // - взаимодействие с внешними сущностями. Если определено значение поля entity_name, то поле exposure будет null. Представлен массивом объектов структуры IndirectEntityConnection.
}

export type TAmlCheckTransferInfo = {
  tx_hash: string // - хэш тразакции, к которой относится трансфер.
  network_code: string // - код сети.
  network_name: string // - название сети.
  asset_symbol: string // - код актива, с которым ассоциирован трансфер.
  input_address: string // - адрес отправителя.
  output_address: string // - адрес получателя
  amount: number;
  value_in_fiat: TNullable<number> // - сумма трансфера в фиате. Может быть null.
}
export enum EProximity {
  direct = "direct",
  indirect = "indirect",
  any = "any",
}

export type TAmlCheckTransferExposure = {
  checked_at: string // - время проверки экспожура. Может быть null.
  interaction: EProximity // - "близость" источника. Возможные варианты: direct, indirect.
  counterparty: TNullable<TAmlCheckTransferDirectExposure> // - прямой контрагент. Применимо для interaction в значении direct, иначе null. Представлен объектом DirectEntityConnection.
  report_url: TNullable<string> // - ссылка на PDF генератор
  indirect_exposure: TNullable<TExposure[]> // - непрямые источники. Применимо для interaction в значении indirect, иначе null. Представлен массивом объектов структуры IndirectEntityConnection.
}
export type TAmlCheckTransferDirectExposure = {
  entity_name: string
  entity_category: string
  risk_score: number
}

export enum ETransferDirection {
  incoming = "incoming",
  outgoing = "outgoing",
}

export type TAmlChecksDetailsOptions = {
  id: string
  prev_address_exposure_status?: ECounterpartyStatus
  prev_transfer_exposure_status?: EExposureStatus
  isReport?: boolean
}
export enum EAlertSubjects {
  sender_entity = "sender_entity",
  recipient_entity = "recipient_entity",
  origin_of_funds = "origin_of_funds",
  destination_of_funds = "destination_of_funds",
  sender_exposure = "sender_exposure",
  recipient_exposure = "recipient_exposure",
  attempt_sender_entity = "attempt_sender_entity",
  attempt_recipient_entity = "attempt_recipient_entity",
  attempt_sender_exposure = "attempt_sender_exposure",
  attempt_recipient_exposure = "attempt_recipient_exposure",
  address_exposure = "address_exposure",
}
export type TAmlChecksChecksRisk = {
  alert_assignee_name: TNullable<string>
  alert_id: TNullable<string>
  amount: TNullable<number>
  asset: TNullable<string>
  client_ext_id: TNullable<string>
  created_at: string
  entity_category: string
  fiat_currency: string
  fiat_value: TNullable<number>
  full_value: TNullable<number>
  id: string
  network_code: string
  network_name: string
  occurred_at: string
  risk_level: any
  risky_value: TNullable<number>
  risky_value_share: number
  type: EAlertSubjects

  // client_ext_id: string // - всегда null для всех типов проверок.
  // risky_value: string // - всегда null для рисков адреса.
  // asset: string // - может быть null для риска адреса.
  // amount: string // и fiat_value - всегда null для риска адреса.
  // alert_id: string // alert_status, alert_assignee_name - всегда null для всех типов проверок.
}
export type TNetwork = {
  id: string
  name: string
  code: string
}
export type TAmlChecksRisksData = TAmlChecksChecksRisk[]
export type TAmlChecksRisksState = TRequestHandler<TAmlChecksRisksData> & {
  initialLoading: boolean
}
export type TAmlChecksChecksRiskOptions = {
  id: string
  callOnSuccess?: (data: TAmlChecksRisksData) => void
} & Pick<TAmlChecksDetailsOptions, 'isReport'>;

export type TAmlChecksRecheckState = TRequestHandler<unknown> & {
  initialLoading: boolean
}
export enum EAmlCheckGroups {
  transfer_checks  = 'transfer_checks',
  address_checks = 'address_checks',
  all = 'all',
}
export type TExplorerFoundAddressNetwork = {
  id: string
  code: string
  name: string
  is_added?: boolean
}
export enum ECounterpartyStatus {
  not_checked = "not_checked",
  checking = "checking",
  checked = "checked",
  error = "error",
  not_supported = "not_supported",
  not_available = "not_available",
}
export type TAmlChecksDetails = {
  id: string // - ID проверки.
  created_at: string // - дата и время создания проверки.
  occurred_at?: TNullable<string> // - дата и время создания проверки.
  type: EAmlCheckType // - тип проверки. Возможные значения: deposit, withdrawal, single_address.
  risk_level: TNullable<ERiskLevel> // - уровень риска. Может быть null, если проверка не завершена.
  risk_score: number // - уровень риска. Может быть null, если проверка не завершена.
  address_info: TAmlCheckAddressInfo // - данные, связанные с проверкой адреса. См. структуру AmlCheckAddressInfo.
  address_exposure_status: ECounterpartyStatus // - статус проверки адреса и его экспожура.
  address_exposure: TAmlCheckAddressExposure // - результаты проверки адреса и его экспожура. См. структуру AddressExposure.
  transfer_info: TAmlCheckTransferInfo // - данные, связанные с проверкой трансфера. См. структуру AmlCheckTransferInfo.
  transfer_exposure_status: EExposureStatus // - статус проверки экспожура трансфера.
  transfer_exposure: TAmlCheckTransferExposure // - результат проверки экспожура трансфера. См. структуру TransferExposure.
  fiat_currency: string // - валюта для расчета стоимости активов в фиате.
  report_url?:string;
  prev_address_exposure_status?: ECounterpartyStatus,
  prev_transfer_exposure_status?: EExposureStatus,
}
export type TExplorerTransfer = {
  network_code: string;
  network_name: string;
  amount: number;
  token_id: string;
  token_symbol: string;
  token_name: string;
  input_address: string;
  output_address: string;
}
export type TExplorerFoundAddress = {
  address: string
  networks: TExplorerFoundAddressNetwork[]
}
export type TExplorerFoundTransaction = {
  tx_hash: string
  transfers: TExplorerTransfer[]
}
export type TExplorerFindAddressOrTransaction = {
  query: string
  address: TExplorerFoundAddress
  transaction?: TExplorerFoundTransaction
}
export type TFindAddressOrTransactionBody = {
  query: string
}
export type TFindAddressOrTransactionSOptions = {
  body: TFindAddressOrTransactionBody
  callOnSuccess?: (data: TExplorerFindAddressOrTransaction) => void
}
export type TRiskOverview = {
  accounts_monitored:number;
  risk_score?: number;
  risk_level: ERiskLevel;
  transactions_unchecked: number;
  most_risky_account_id: string;
}

export type TNetworksState = TRequestHandler<TNetwork[]>
export type TAmlGroupsState = TRequestHandler<TAmlMobileFilterGroups[]>;
export type TFindAddressOrTransactionState = TRequestHandler<TExplorerFindAddressOrTransaction>
export type TAmlChecksDetailsState = TRequestHandler<TAmlChecksDetails> & {
  initialLoading: boolean
}
export type TRiskOverviewState = TRequestHandler<TRiskOverview>
export type TAmlChecksDetailsDeleteState = TRequestHandler<TAmlChecksDetails[]>

export type TAmlChecksStoreState = {
  amlChecks: TAmlChecksState
  amlChecksDetails: TAmlChecksDetailsState
  amlChecksDetailsDelete:TAmlChecksDetailsDeleteState
  amlChecksCheckTransfer: TAmlChecksCheckTransferState
  amlChecksCheckAddress: TAmlChecksCheckAddressState
  amlChecksRisks: TAmlChecksRisksState
  amlChecksRecheck: TAmlChecksRecheckState
  amlFilterMobileGroups: TAmlGroupsState
  networks: TNetworksState
  explorerFindAddressOrTransaction: TFindAddressOrTransactionState
  amlRisksOverview:TRiskOverviewState
};
