import { FC, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Modal as AntdModal } from "antd";
import { useHistory } from "react-router-dom";

import { EQueries } from "config/types";

import useQuery from "hooks/useQuery";

import { getAssetsDetailRequest } from "store/assets/reducers";
import { TAssetsData } from "store/assets/types";
import { analyticEvent } from "store/analytics/effects";
import { EEventType } from "store/analytics/types";
import { modalStateSelector } from "store/modals/selectors";
import { EModals } from "store/modals/types";
import { closeModal, openModal } from "store/modals/reducers";
import { getMyAssetsSelector } from "store/assets/selectors";

import ConnectAssetsModal from "../ConnectAssetsModal";
import AddAccountModal from "../AddAccountModal";
import { useAddAccountType } from "../AddAccountModal/hooks";

import { AssetsModalContext } from "./hooks";

const AssetsModal: FC = () => {
  const history = useHistory();
  const query = useQuery();
  const modal = query.get(EQueries.modal);
  const isAmlQuery = query.get(EQueries.isAml);
  const isAml = isAmlQuery === "true";
  const [selectedAsset, setSelectedAsset] = useState<TAssetsData | null>(null);
  const [showPopUp, setShowPopUp] = useState(false);
  const { onClose } = useAddAccountType();
  const visible = useSelector(modalStateSelector(EModals.assetModal));
  const { data: myAssets } = useSelector(getMyAssetsSelector);
  const dispatch = useDispatch();

  const handleCancel = () => {
    dispatch(closeModal(EModals.assetModal));

    if (!selectedAsset) {
      dispatch(analyticEvent(EEventType.WALLET_NEW_ACCOUNT_STEP_1_CLOSE));
    } else {
      dispatch(analyticEvent(EEventType.WALLET_NEW_ACCOUNT_STEP_2_CLOSE));
    }
    onClose();

    query.delete(EQueries.modal);
    query.delete(EQueries.isAml);
    query.delete(EQueries.assetsType);
    history.replace({
      search: query.toString(),
    });
  };

  useEffect(() => {
    if(!visible) {
      setSelectedAsset(null);
      setShowPopUp(false);
    }
  }, [visible]);

  useEffect(() => {
    if (selectedAsset?.id) {
      dispatch(getAssetsDetailRequest({ id: selectedAsset?.id }));
    }
  }, [dispatch, selectedAsset]);

  useEffect(() => {
    if (visible && (myAssets && myAssets?.length === 0)) {
      setShowPopUp(true);
    } else {
      setShowPopUp(false);
    }
  }, [visible, myAssets]);

  useEffect(() => {
    if (visible) {
      dispatch(analyticEvent(EEventType.WALLET_ADD_NEW));
    }
  }, [visible]);

  useEffect(() => {
    if (modal === EModals.assetModal) {
      dispatch(openModal(EModals.assetModal));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modal]);

  return (
    <AntdModal
      centered={true}
      title={false}
      open={visible}
      footer={false}
      onCancel={handleCancel}
      width={636}
      destroyOnClose={true}
      className={showPopUp ? "pop-up__in-antd-model" : ""}
    >
      <AssetsModalContext.Provider value={{ isAml }}>
        {(selectedAsset) ? (
          <ConnectAssetsModal
            selectedAsset={selectedAsset}
            setSelectedAsset={setSelectedAsset}
            showPopUp={showPopUp}
            setShowPopUp={setShowPopUp}
            visible={visible}
          />
        ) : (
          <AddAccountModal
            setSelectedAsset={setSelectedAsset}
            showPopUp={showPopUp}
            setShowPopUp={setShowPopUp}
            visible={visible}
          />
        )}
      </AssetsModalContext.Provider>
    </AntdModal>
  );
};

export default AssetsModal;
