import { RangeValue } from "rc-picker/lib/interface";
import { Dayjs } from "dayjs";

import { TNullable } from "config/types";

import { EReportTransactionType, ETaxTxTypeTransactionType } from "../reports/types";

export enum ETransactionsFilterKeys {
  account='account',
  asset='asset',
  symbol='symbol',
  type='type',
  report_type='report_type',
  tax_tx_type='tax_tx_type',
  date_from='date_from',
  date_to='date_to',
  text='text',
}

export type TTransactionsFilterState = {
  [ETransactionsFilterKeys.account]?: TNullable<string>;
  [ETransactionsFilterKeys.asset]?: TNullable<string>;
  [ETransactionsFilterKeys.symbol]?: TNullable<string>;
  [ETransactionsFilterKeys.type]?: TNullable<string>;
  [ETransactionsFilterKeys.report_type]?: TNullable<EReportTransactionType>;
  [ETransactionsFilterKeys.tax_tx_type]?: TNullable<ETaxTxTypeTransactionType>;
  [ETransactionsFilterKeys.date_from]?: TNullable<string>;
  [ETransactionsFilterKeys.date_to]?: TNullable<string>;
  [ETransactionsFilterKeys.text]?: TNullable<string>;
};

export const defaultTransactionsFilter: TTransactionsFilterState = {
  [ETransactionsFilterKeys.account]: null,
  [ETransactionsFilterKeys.asset]: null,
  [ETransactionsFilterKeys.symbol]: null,
  [ETransactionsFilterKeys.type]: null,
  [ETransactionsFilterKeys.report_type]: null,
  [ETransactionsFilterKeys.date_from]: null,
  [ETransactionsFilterKeys.date_to]: null,
  [ETransactionsFilterKeys.text]: null,
};

export type TSelectedDate = RangeValue<Dayjs>;

export type TFilterStoreState = {
  transactionsFilterState: TTransactionsFilterState;
  transactionsFilterAction: boolean;
  selectedDates: TSelectedDate;
};
