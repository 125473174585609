import { PayloadAction } from "@reduxjs/toolkit";

import { TPaymentCalculateOptions } from "./types";

export const PAYMENT_PROMO_CODE_APPLY_REQUEST = "PAYMENT_PROMO_CODE_APPLY_REQUEST";

export const paymentPromoCodeApplyRequest = 
  (payload: TPaymentCalculateOptions): PayloadAction<TPaymentCalculateOptions> => ({
    type: PAYMENT_PROMO_CODE_APPLY_REQUEST,
    payload
  });
