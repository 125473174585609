import { FC, useMemo } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import { paths } from "config/paths";

import { useLayoutAppNameContext } from "components/Layout/hooks";
import LoginIcon from "components/Icons/LoginIcon";

import { isAuthorizedSelector } from "store/auth/selectors";

const PublicMobileRight: FC = () => {
  const { routePath } = useLayoutAppNameContext();
  const history = useHistory();
  const isAuthorized = useSelector(isAuthorizedSelector);

  const handleRedirect = () => {
    if (isAuthorized) {
      history.push(paths.HOME);
    } else {
      history.push(paths.SIGN_UP);
    }
  };
  
  const rightComponent = useMemo(() => {
    switch (routePath) {
    case paths.PUBLIC_ACCOUNT:
    case paths.PUBLIC_AML:
      return <LoginIcon className="public-header-mobile__login" onClick={handleRedirect}/>;  
    }
    return null;
  }, [routePath]);
  
  return (
    <div className="header-mobile__right">
      {rightComponent}
    </div>
  );
};

export default PublicMobileRight;