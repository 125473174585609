import { FC, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

import { paths } from "config/paths";

import { useLayoutAppNameContext } from "components/Layout/hooks";

import HeaderBack, { HeaderBackProps } from "../HeaderBack";
import HeaderChecksLeft from "../HeaderChecksLeft";

const HeaderLeft: FC = () => {
  const { routePath } = useLayoutAppNameContext();
  const { t } = useTranslation();
  const history = useHistory();
  const doesAnyHistoryEntryExist = history.location.key;

  const handleClickBreadCrumb = (path: string, withGoBack = false) => {
    if (doesAnyHistoryEntryExist && withGoBack) {
      history.goBack();
    } else {
      history.push(path);
      history.location.key = "";
    }
  };

  const vectorBack: Record<string,HeaderBackProps>
  = useMemo(() => ({
    [paths.AML_CHEKS_SINGLE]: {
      title: t("amlChecks.title"),
      onClick: () => handleClickBreadCrumb(paths.AML_CHEKS, true),
    }
  }), [ doesAnyHistoryEntryExist]);

  return <div className="new-header__left">
    {routePath && vectorBack[routePath] && doesAnyHistoryEntryExist
      ? <HeaderBack onClick={vectorBack[routePath].onClick} title={vectorBack[routePath].title} />
      : <HeaderChecksLeft/>}
  </div>;
};

export default HeaderLeft;
