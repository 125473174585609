import React, { FC } from "react";
import cn from "classnames";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import { getAmlChecksSinglePath, getAssetSingleId, getAssetsSinglePath, paths } from "config/paths";
import { ESyncStatus } from "config/types";

import SyncBadge from "components/SyncBadge";

import { analyticEvent } from "store/analytics/effects";
import { EEventType } from "store/analytics/types";
import { getAssetsDataSelector } from "store/assets/selectors";
import { TMyAssetsData } from "store/assets/types";

import { formAssetsName } from "utils/assetsHelper";
import { getRiskStatus, prepareRiskValue } from "utils/risks";

import { TSidebarCollapseProps } from "../SidebarCollapse";

type TSidebarAccountProps = { account: TMyAssetsData } & Pick<TSidebarCollapseProps, "showRiskScore"|"path">
const SidebarAccount: FC<TSidebarAccountProps> = ({ account , path, showRiskScore }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const isAsset = path === paths.ASSETS;
  const assetSingleId = getAssetSingleId();
  const id = isAsset ? assetSingleId : null;
  const assets = useSelector(getAssetsDataSelector);

  const getAssetType = (myAsset?: TMyAssetsData) => assets?.find($asset => $asset.id === myAsset?.stock)?.type;
  const showBadge = [
    ESyncStatus.synchronizing,
    ESyncStatus.error,
  ].includes(account.status);
  const onClick = (e: React.MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault();
    if (account.id === id) return;

    if (isAsset) {
      history.push(getAssetsSinglePath(account.id));
      dispatch(analyticEvent(EEventType.WALLET_SHOW_ACCOUNT_INFO));
    } else {
      dispatch(analyticEvent(EEventType.AML_SHOW_ACCOUNT_INFO));
      history.push(getAmlChecksSinglePath(account.id));
    }
  };

  return <a
    className={cn("sidebar-account", { active: account.id === id })}
    onClick={onClick}
  >
    <span className="sidebar-account-logo__wrapper">
      <img src={account.icon} alt="assets-icon" className="sidebar-account-logo" />
      {showBadge &&
        <SyncBadge
          isSpin={account.status === ESyncStatus.synchronizing}
          error={account.status === ESyncStatus.error}
          className="sidebar-account-logo__sync-badge"
        />}
    </span>
    <span className={cn("sidebar-account-title", { riskScore: !!account?.risk_score })}>
      {formAssetsName(account.name, getAssetType(account))}
    </span>
    {(account?.risk_score !== null && account?.risk_score !== undefined && showRiskScore)
      && <div className={cn("sidebar-account-risk", getRiskStatus(account?.risk_score) )}>
        {prepareRiskValue(account?.risk_score)}%
      </div>}
  </a>;
};

export default SidebarAccount;
