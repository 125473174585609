import { FC } from "react";
import { useMediaQuery } from "react-responsive";
import { useTranslation } from "react-i18next";

import { maxMobileMediaWidth } from "config/constants";

import { usePricingContext } from "../../hooks";

import PricingSwitch from "../PricingSwitch";

const PricingHeader: FC = ( ) => {
  const { t } = useTranslation();
  const { isModal } = usePricingContext();
  const isMaxMobile = useMediaQuery({ query: `(max-width: ${maxMobileMediaWidth}px)` });
  
  return (
    <div className="pricing__header container">
      {(!isMaxMobile && !isModal) && <div className="pricing__header__title">
        {t("naming.pricing")}
      </div>}
      <PricingSwitch/>
    </div>
  );
};

export default PricingHeader;