import { FC } from "react";
import cn from "classnames";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import { getAssetSingleId } from "config/paths";

import BNDropdown from "components/BNDropdown";

import { startAccountSyncLoadingSelector } from "store/assets/selectors";
import { startAccountSyncRequest } from "store/assets/reducers";
import { analyticEvent } from "store/analytics/effects";
import { EEventType } from "store/analytics/types";

import { TAssetSingleMenuItemProps } from "../../types";

const AssetSingleMenuSynchronize: FC<TAssetSingleMenuItemProps> = ({ setActive }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const id = getAssetSingleId();
  const loading = useSelector(startAccountSyncLoadingSelector(id));

  const handleClickAccountSync = () => {
    dispatch(startAccountSyncRequest({ id }));
    dispatch(analyticEvent(EEventType.WALLETS_ACCOUNT_SYNC));
  };

  return (
    <BNDropdown.Item
      className={cn({ disabled: loading })}
      onClick={() => {
        handleClickAccountSync();
        setActive(false);
      }}
    >
      <span>{loading ? t('accountSync.synchronizing') : t('accountSync.synchronize')}</span>
    </BNDropdown.Item>
  );
};

export default AssetSingleMenuSynchronize;
