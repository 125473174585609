import { FC, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import { servicesAllRequest } from "store/services/reducers";
import { AmlPacksCount, EServiceCode, EServiceSet, ETariffPlan } from "store/services/types";
import { getUserInfoDataSelector } from "store/user/selectors";
import { servicesExistsSelector } from "store/services/selectors";

import AMLActivatePack from "../AMLActivatePack";

const AMLActivatePacks: FC = ({}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const userPlanInfo = useSelector(getUserInfoDataSelector);
  const servicesExists = useSelector(servicesExistsSelector);

  const showSmart = userPlanInfo?.tariff_plan &&
    ![ETariffPlan.pro, ETariffPlan.smart].includes(userPlanInfo?.tariff_plan);
  const showPro = userPlanInfo?.tariff_plan !== ETariffPlan.pro;

  useEffect(() => {
    dispatch(servicesAllRequest({ params: { set: EServiceSet.amlCheck } }));
  }, [dispatch]);

  return (
    <div className="aml-activate-packs">

      {servicesExists[EServiceCode.amlPacks1] && <AMLActivatePack
        code={EServiceCode.amlPacks1}
        count={AmlPacksCount[EServiceCode.amlPacks1]}
        title={t("amlPricing.check", { count: AmlPacksCount[EServiceCode.amlPacks1] })} />}

      {servicesExists[EServiceCode.amlPacks5] && <AMLActivatePack
        code={EServiceCode.amlPacks5}
        count={AmlPacksCount[EServiceCode.amlPacks5]}
        title={t("amlPricing.check", { count: AmlPacksCount[EServiceCode.amlPacks5] })} />}
      {servicesExists[EServiceCode.amlPacks10] && <AMLActivatePack
        code={EServiceCode.amlPacks10}
        count={AmlPacksCount[EServiceCode.amlPacks10]}
        title={t("amlPricing.check", { count: AmlPacksCount[EServiceCode.amlPacks10] })} />}

      {servicesExists[EServiceCode.amlPacks50] && <AMLActivatePack
        code={EServiceCode.amlPacks50}
        count={AmlPacksCount[EServiceCode.amlPacks50]}
        title={t("amlPricing.check", { count: AmlPacksCount[EServiceCode.amlPacks50] })} />}

      {servicesExists[EServiceCode.amlPacks100] && <AMLActivatePack
        code={EServiceCode.amlPacks100}
        count={AmlPacksCount[EServiceCode.amlPacks100]}
        title={t("amlPricing.check", { count: AmlPacksCount[EServiceCode.amlPacks100] })} />}

      {(servicesExists[EServiceCode.plansSmartMonth] || servicesExists[EServiceCode.plansProMonth]) &&
        <div className="aml-activate-packs__subscriptions">
          {t("amlPricing.subscriptionsChecks")}:
        </div>}
      {servicesExists[EServiceCode.plansSmartMonth] && showSmart && <AMLActivatePack
        code={EServiceCode.plansSmartMonth} count={50} title="SMART"  plan={ETariffPlan.smart}
        lowTitle={t("amlPricing.check", { count: AmlPacksCount[EServiceCode.amlPacks50] })}
      />}
      {servicesExists[EServiceCode.plansProMonth] && showPro && <AMLActivatePack
        code={EServiceCode.plansProMonth} count={100} title="PRO" plan={ETariffPlan.pro}
        lowTitle={t("amlPricing.check", { count: AmlPacksCount[EServiceCode.amlPacks100] })}
      />}
    </div>
  );
};

export default AMLActivatePacks;
