import { FC } from "react";
import { Dropdown } from "antd";

import { ECurrency } from "config/types";

import ArrowIcon from "../Icons/ArrowIcon";

const CURRENCIES = [
  {
    currency: ECurrency.usd,
  },
  {
    currency: ECurrency.eur,
  },
  {
    currency: ECurrency.rub,
  },
  {
    currency: ECurrency.gbp,
  },
  {
    currency: ECurrency.try,
  },
  {
    currency: ECurrency.jpy,
  },
  {
    currency: ECurrency.aud,
  },
  {
    currency: ECurrency.uah,
  },
  {
    currency: ECurrency.thb,
  },
];

export type CurrencySelectorProps = {
  currency: ECurrency
  setCurrency: (val: ECurrency) => void
}
const CurrencySelector: FC<CurrencySelectorProps> = ({ currency, setCurrency }) => (
  <Dropdown
    menu={{ items: CURRENCIES.map(($currency, index) => ({
      key: index,
      onClick: () => setCurrency($currency.currency),
      label: $currency.currency
    })) }}
    trigger={["click"]}
    placement="bottomLeft"
    className="pricing__activate__currency__dropdown__container"
    overlayClassName="pricing__activate__currency__dropdown"
  >
    <div>{currency} <ArrowIcon className="arrow"/></div>
  </Dropdown>
);

export default CurrencySelector;