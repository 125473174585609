import { FC, useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { ECurrency } from "config/types";

import CurrencySelector from "components/CurrencySelector";

import { getUserInfoSelector } from "store/user/selectors";
import { userSaveLocalSettingsRequest } from "store/user/reducers";
import { isAuthorizedSelector } from "store/auth/selectors";

import { EStorageKeys } from "utils/storageHeplers";


const PublicHeaderCurrency: FC = () => {
  const dispatch = useDispatch();
  const isAuthorized = useSelector(isAuthorizedSelector);
  const [currency, setCurrency] = useState<ECurrency>(ECurrency.usd);
  const storageCurrency = localStorage.getItem(EStorageKeys.CURRENCY) as ECurrency|undefined;
  const { data: userInfo } = useSelector(getUserInfoSelector);

  useEffect(() => {
    if (userInfo?.currency && isAuthorized) {
      setCurrency(userInfo?.currency);
      localStorage.setItem(EStorageKeys.CURRENCY, userInfo?.currency);
    } else if (storageCurrency) {
      setCurrency(storageCurrency);
    }
  }, [storageCurrency, userInfo]);

  const handleSetCurrency = useCallback(($currency: ECurrency) => {
    if (userInfo?.currency && isAuthorized) {
      dispatch(userSaveLocalSettingsRequest({
        data: {
          currency: $currency
        },
        callOnSuccess: () => {
          location.reload();
        }
      }));
    } else {
      setCurrency($currency);
      localStorage.setItem(EStorageKeys.CURRENCY, $currency);
      location.reload();
    }
  }, [userInfo?.currency, isAuthorized]);

  return <div className="new-header__currency">
    {currency && <CurrencySelector currency={currency} setCurrency={handleSetCurrency}/>}
  </div>;
};

export default PublicHeaderCurrency;