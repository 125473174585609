import { FC } from "react";
import cn from "classnames";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import { getAssetSingleId } from "config/paths";

import BNDropdown from "components/BNDropdown";

import { EEventType } from "store/analytics/types";
import { analyticEvent } from "store/analytics/effects";
import { getSingleAssetDeleteFetchingSelector } from "store/assets/selectors";

import { EAssetsSingleMenuStatesKeys, TAssetSingleMenuItemProps, TAssetsSingleMenuDropdownProps } from "../../types";

const AssetSingleMenuDelete: FC<TAssetSingleMenuItemProps & TAssetsSingleMenuDropdownProps> = ({
  setActive, setVisibleTrue
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const id = getAssetSingleId();
  const deletingAsset = useSelector(getSingleAssetDeleteFetchingSelector(id));

  const handleClickDelete = () => {
    setVisibleTrue(EAssetsSingleMenuStatesKeys.delete);
    dispatch(analyticEvent(EEventType.WALLETS_ACCOUNT_DELETE_START));
  };

  return (
    <BNDropdown.Item
      className={cn("assets-single__header_menu_delete", { disabled: deletingAsset })}
      onClick={() => {
        handleClickDelete();
        setActive(false);
      }}
    >
      <span>{t('accountSync.deleteAccount')}</span>
    </BNDropdown.Item>
  );
};

export default AssetSingleMenuDelete;
