import { abbreviateNumber } from "js-abbreviation-number";

import i18n from "../i18n";
import { ELanguages } from "../i18n/constants";

export const abbreviate = (value: number): string => {

  if (value > 9999999999 || (value < 0.0000001 && value > 0)) {
    return value.toExponential(4).replace(".", ",");
  }

  let symbols = ["", "K", "M", "B", "T", "P", "E"];
  if (i18n.language === ELanguages.ruRU) {
    symbols = ["", " тыс", " млн", " млрд", " трл", " квадр", " квинт"];
  }
  return abbreviateNumber(value, 0, { symbols });
};
