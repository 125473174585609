import { FC } from "react";
import { useTranslation } from "react-i18next";

import BNDropdown from "components/BNDropdown";
import CopyIcon from "components/Icons/CopyIcon";

import { showSuccess } from "utils/notifications";

export type TAddressCopyMenuItemProps = {
  address: string
  onClick: () => void
}

const AddressCopyMenuItem: FC<TAddressCopyMenuItemProps> = ({ address, onClick }) => {
  const { t } = useTranslation();

  const handleClick = () => {
    navigator.clipboard.writeText(address || "");
    showSuccess(t("accountAddressСopied"));
  };

  const formAddress = () => {
    if (!address) return;

    return `${address.slice(0, 8)}...${
      address.slice(address.length - 6, address.length)}`;
  };

  return (
    <BNDropdown.Item
      onClick={() => {
        handleClick();
        onClick();
      }}
      className="assets-single__header_menu_copy-address"
    >
      <span>{formAddress()}</span>
      <CopyIcon/>
    </BNDropdown.Item>
  );
};

export default AddressCopyMenuItem;
