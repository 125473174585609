import { colors } from "config/constants";

{/* eslint-disable max-len */}
const TradingInsideIcon = (): JSX.Element => (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
    <path
      d="M22.071 19.5008H3.64244V2.78655C3.64244 2.66869 3.54601 2.57227 3.42815 2.57227H1.92815C1.8103 2.57227 1.71387 2.66869 1.71387 2.78655V21.2151C1.71387 21.333 1.8103 21.4294 1.92815 21.4294H22.071C22.1889 21.4294 22.2853 21.333 22.2853 21.2151V19.7151C22.2853 19.5973 22.1889 19.5008 22.071 19.5008ZM6.47637 15.3678C6.5594 15.4508 6.69333 15.4508 6.77905 15.3678L10.4835 11.6821L13.9014 15.1214C13.9844 15.2044 14.121 15.2044 14.204 15.1214L21.5808 7.74727C21.6639 7.66423 21.6639 7.52762 21.5808 7.44459L20.5201 6.38387C20.4798 6.34399 20.4255 6.32162 20.3688 6.32162C20.3121 6.32162 20.2577 6.34399 20.2174 6.38387L14.0567 12.5419L10.6442 9.10798C10.604 9.0681 10.5496 9.04573 10.4929 9.04573C10.4362 9.04573 10.3818 9.0681 10.3415 9.10798L5.41833 14.0017C5.37845 14.042 5.35608 14.0964 5.35608 14.1531C5.35608 14.2097 5.37845 14.2641 5.41833 14.3044L6.47637 15.3678Z"
      fill={colors.gray8} />
  </svg>
);

export default TradingInsideIcon;