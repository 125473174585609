import { FC } from 'react';
import cn from "classnames";
import { useTranslation } from "react-i18next";
import { useMediaQuery } from "react-responsive";

import { mobileMediaWidth } from "config/constants";

import { ETariffPlan } from "store/services/types";

import { TPricingPlanProps } from "../../types";

import PricingCard from '../PricingCard';
import PricingCardFeatures from '../PricingCardFeatures';
import PricingProgress from "../PricingProgress";

import PricingPlanFreeDowngradeText from './components/PricingPlanFreeDowngradeText';
import { availableFeatures } from "./constants";

const PricingPlanFree: FC<TPricingPlanProps> = ({ active }) => {
  const { t } = useTranslation();
  const isMobile = useMediaQuery({ query: `(max-width: ${mobileMediaWidth}px)` });

  return (
    <PricingCard active={active}>
      <div className="pricing__card__title">
        {t("pricing.newbie")}
      </div>
      {(!isMobile || (isMobile && active))
      && <div className={cn("pricing__card__price free", { "not-active": !active })}>
        {t("pricing.free")}
      </div>}
      {active
        ? <PricingProgress/>
        : <PricingPlanFreeDowngradeText/>}
      <PricingCardFeatures features={availableFeatures()} tariffPlan={ETariffPlan.free}/>
    </PricingCard>
  );
};

export default PricingPlanFree;
