import { FC, SVGProps } from "react";

import { colors } from "config/constants";

{/* eslint-disable max-len */}
const InfoIcon: FC<SVGProps<SVGSVGElement>> = props => (
  <svg width="16" height="16" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M12 0C5.37321 0 0 5.37321 0 12C0 18.6268 5.37321 24 12 24C18.6268 24 24 18.6268 24 12C24 5.37321 18.6268 0 12 0ZM12 21.9643C6.49821 21.9643 2.03571 17.5018 2.03571 12C2.03571 6.49821 6.49821 2.03571 12 2.03571C17.5018 2.03571 21.9643 6.49821 21.9643 12C21.9643 17.5018 17.5018 21.9643 12 21.9643Z"
      fill={colors.main}
    />
    <path
      d="M10.7139 7.28571C10.7139 7.62671 10.8493 7.95373 11.0904 8.19485C11.3316 8.43597 11.6586 8.57143 11.9996 8.57143C12.3406 8.57143 12.6676 8.43597 12.9087 8.19485C13.1498 7.95373 13.2853 7.62671 13.2853 7.28571C13.2853 6.94472 13.1498 6.61769 12.9087 6.37658C12.6676 6.13546 12.3406 6 11.9996 6C11.6586 6 11.3316 6.13546 11.0904 6.37658C10.8493 6.61769 10.7139 6.94472 10.7139 7.28571ZM12.6424 10.2857H11.3567C11.2389 10.2857 11.1424 10.3821 11.1424 10.5V17.7857C11.1424 17.9036 11.2389 18 11.3567 18H12.6424C12.7603 18 12.8567 17.9036 12.8567 17.7857V10.5C12.8567 10.3821 12.7603 10.2857 12.6424 10.2857Z"
      fill={colors.main}
    />
  </svg>
);

export default InfoIcon;
