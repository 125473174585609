import React, { useState, useEffect, memo } from "react";
import cn from "classnames";

import { TNullable } from "config/types";

import Hint from "components/Hint";

import { TCryptoProps } from "./types";
import IconNotListedInCatalog from "./components/IconNotListedInCatalog";

export type TCheckImage = {
  path: string
  exists: boolean
}
const CryptoIcon: React.FC<TCryptoProps> = memo(({
  asset,
  className,
  isExternalType,
  style,
  assetVerified,
  assetIcon,
  hint=false,
  align,
}) => {
  const [loading, setLoading] = useState(false);
  const [icon, setIcon] = useState<TNullable<string>>(null);

  const getVerifiedIcon = async () => isExternalType
    ? import(`common/assets/icons/black-crypto-icons/${asset?.toLowerCase()}.svg`)
    : import(`common/assets/icons/crypto-icons/${asset?.toLowerCase()}.svg`);

  const checkImage = (path: string) =>
    new Promise<TCheckImage>(resolve => {
      const img = new Image();
      img.src = path;
      img.onload = () => resolve({ path, exists: true });
      img.onerror = () => resolve({ path, exists: false });
    });

  const getAssetIcon = async () => {
    if (!process.env.REACT_APP_BASE_URL || !assetIcon) return false;
    const { path, exists: imageExists } = await checkImage(process.env.REACT_APP_BASE_URL + assetIcon);
    if (imageExists) {
      setIcon(path);
      return true;
    }
    return false;
  };

  useEffect(() => {
    let isSubscribed = true;

    const handleDynamicIcon = async () => {
      setLoading(true);
      let importedIcon = null;

      if (assetVerified || assetVerified === undefined) {
        try {
          importedIcon = await getVerifiedIcon();
        }
        catch {
          const iconModified = await getAssetIcon();
          if (iconModified) {
            setLoading(false);
            return;
          }
        }
      }
      if (!asset && !importedIcon) {
        importedIcon = await import("common/assets/icons/unknown-asset.svg");
      }

      if (importedIcon && isSubscribed) {
        setIcon(importedIcon.default);
      }

      setLoading(false);
    };

    // tslint:disable-next-line:no-console
    handleDynamicIcon().catch(console.error);
    return () => {
      isSubscribed = false;
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [asset]);

  return (
    <>
      {
        loading
          ? <div className={cn("crypto-icon", className)} style={style} />
          :
          <Hint content={asset || "???"} trigger="hover" open={hint ? undefined : false}
            align={ align || { targetOffset: [0, 10] }}
          >
            {
              icon ? <img className={cn("crypto-icon", className, {
                isExternalCryptoIcon: isExternalType
              })} alt='crypto-icon' src={ icon } style={style}/>
                : <IconNotListedInCatalog asset={asset} style={style} className={cn(className, {
                  isExternalCryptoIcon: isExternalType
                })} />
            }
          </Hint>
      }
    </>
  );
});

export default CryptoIcon;
