import { createContext, Dispatch, SetStateAction, useContext } from "react";

import { paths } from "config/paths";

import { THeaderMobileProps } from "./components/LayoutProviders";

export type TLayoutContext = {
  name?: string,
  logo?: JSX.Element,
  routePath?: paths,
  mobileHeaderRight?: JSX.Element
  mobileHeaderLeft?: JSX.Element
  setHeaderMobile?: Dispatch<SetStateAction<THeaderMobileProps>>
  logout?: () => void;
}
export const LayoutAppNameContext = createContext<TLayoutContext>({});

export const useLayoutAppNameContext = (): TLayoutContext => useContext(LayoutAppNameContext);
