import { FC } from 'react';

import { IIconsProps } from "config/types";

{/* eslint-disable max-len */}
const OkManIcon:FC<IIconsProps> = () => (
  <svg width="56" height="56" viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M55.046 20.7531C56.6412 26.7068 56.2274 33.0206 53.8687 38.7152C51.5099 44.4098 47.3379 49.1667 42 52.2488C36.6621 55.3306 30.4562 56.5649 24.3452 55.7604C18.2342 54.9559 12.5596 52.1575 8.2009 47.7991C3.84249 43.4404 1.04405 37.7658 0.239567 31.6548C-0.564915 25.5438 0.669414 19.338 3.75123 14C6.83305 8.66204 11.5902 4.49015 17.2848 2.13138C22.9795 -0.227382 29.2932 -0.641211 35.2468 0.954083L28 28L55.046 20.7531Z" fill="#D64686"/>
  </svg>

);

export default OkManIcon;
