import { FC } from "react";
import { CSSTransition } from "react-transition-group";

export type TransitionProps = {
  show?: boolean
  ms?: number
}

const AnimatedVisibility: FC<TransitionProps> = ({ children, show, ms=350 }) => (
  <CSSTransition
    classNames="animated-visibility"
    timeout={ms}
    in={show}
  >
    {show ? children : <></>}
  </CSSTransition>
);

export default AnimatedVisibility;