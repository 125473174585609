import { FC } from "react";
import { Radio } from "antd";

import { EServiceCode, ETariffPlan } from "store/services/types";

import { useAMLActivateModalContext } from "../AMLActivateModal";

import AMLActivatePackContent from "./components/AMLActivatePackContent";

export type TAMLActivatePackProps = {
  code: EServiceCode
  count: number
  title: string
  toFixed?: number
  plan?: ETariffPlan
  lowTitle?:string;
}

const AMLActivatePack: FC<TAMLActivatePackProps> = props => {
  const { formValue } = useAMLActivateModalContext();

  const handleValue = () => {
    if (!formValue) {
      return props.code;
    }
    return formValue({ code: props.code , plan: props.plan });
  };

  return (
    <div className="aml-activate-pack" >
      <Radio
        key={props.code}
        value={handleValue()}
        className="aml-activate-modal__radio"
      >
        <AMLActivatePackContent {...props} />
      </Radio>
    </div>
  );
};

export default AMLActivatePack;
