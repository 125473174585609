import { createSlice } from "@reduxjs/toolkit";

import { defaultState } from "../constants";

import { TServicesStoreState } from "./types";

const initialState: TServicesStoreState = {
  servicesState: defaultState,
  serviceDetailsState: defaultState,
  servicesAllState: defaultState,
  paymentState: defaultState,
  paymentCalculateState: defaultState,
  userPaymentMethodState: defaultState,
  paymentPricingLoading: false
};

const modalsSlice = createSlice({
  name: "modals",
  initialState,
  reducers: {
    clearServicesState() {
      return initialState;
    },

    servicesRequest(state) {
      state.servicesState = {
        ...state.servicesState,
        fetching: true,
        failure: null,
      };
    },
    servicesSuccess(state, action) {
      state.servicesState = {
        fetching: false,
        data: action.payload,
        failure: null,
      };
    },
    servicesFailure(state, action) {
      state.servicesState = {
        ...state.servicesState,
        fetching: false,
        failure: action?.payload,
      };
    },

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    serviceDetailsRequest(state, _) {
      state.serviceDetailsState = {
        ...state.serviceDetailsState,
        fetching: true,
        failure: null,
      };
    },
    serviceDetailsSuccess(state, action) {
      state.serviceDetailsState = {
        fetching: false,
        data: action.payload,
        failure: null,
      };
    },
    serviceDetailsFailure(state, action) {
      state.serviceDetailsState = {
        ...state.serviceDetailsState,
        fetching: false,
        failure: action?.payload,
      };
    },

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    servicesAllRequest(state, _) {
      state.servicesAllState = {
        ...state.servicesAllState,
        fetching: true,
        failure: null,
      };
    },
    servicesAllSuccess(state, action) {
      state.servicesAllState = {
        fetching: false,
        data: action.payload,
        failure: null,
      };
    },
    servicesAllFailure(state, action) {
      state.servicesAllState = {
        ...state.servicesAllState,
        fetching: false,
        failure: action?.payload,
      };
    },
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    paymentRequest(state, _) {
      state.paymentState = {
        ...state.paymentState,
        fetching: true,
        failure: null,
      };
    },
    paymentSuccess(state, action) {
      state.paymentState = {
        fetching: false,
        data: action.payload,
        failure: null,
      };
    },
    paymentFailure(state, action) {
      state.paymentState = {
        ...state.paymentState,
        fetching: false,
        failure: action?.payload,
      };
    },
    calculatePaymentRequest(state, action) {
      state.paymentCalculateState = {
        ...state.paymentCalculateState,
        options: action.payload,
        fetching: true,
        failure: null,
      };
    },
    calculatePaymentSuccess(state, action) {
      state.paymentCalculateState = {
        ...state.paymentCalculateState,
        fetching: false,
        data: action.payload,
        failure: null,
      };
    },
    calculatePaymentFailure(state, action) {
      state.paymentCalculateState = {
        ...state.paymentCalculateState,
        fetching: false,
        failure: action?.payload,
      };
    },

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    userPaymentMethodStateRequest(state, _) {
      state.userPaymentMethodState = {
        ...state.userPaymentMethodState,
        fetching: true,
        failure: null,
      };
    },
    userPaymentMethodStateSuccess(state, action) {
      state.userPaymentMethodState = {
        ...state.userPaymentMethodState,
        fetching: false,
        data: action.payload,
        failure: null,
      };
    },
    userPaymentMethodStateFailure(state, action) {
      state.userPaymentMethodState = {
        ...state.userPaymentMethodState,
        fetching: false,
        failure: action?.payload,
      };
    },
    setPaymentPricingLoading(state, action) {
      state.paymentPricingLoading = action.payload;
    },
  },
});

export const {
  clearServicesState,

  servicesRequest,
  servicesSuccess,
  servicesFailure,

  serviceDetailsRequest,
  serviceDetailsSuccess,
  serviceDetailsFailure,

  servicesAllRequest,
  servicesAllSuccess,
  servicesAllFailure,

  paymentRequest,
  paymentSuccess,
  paymentFailure,

  calculatePaymentRequest,
  calculatePaymentSuccess,
  calculatePaymentFailure,

  userPaymentMethodStateRequest,
  userPaymentMethodStateSuccess,
  userPaymentMethodStateFailure,
  setPaymentPricingLoading,
} = modalsSlice.actions;

export default modalsSlice.reducer;
