import * as Yup  from "yup";
import { Assign, ObjectShape } from "yup/lib/object";
import StringSchema, { RequiredStringSchema } from "yup/lib/string";
import Lazy from "yup/lib/Lazy";

import {
  emailValidationField, emailValidationFieldRequired,
  loginValidationField, loginValidationFieldRequired,
  phoneValidationField, phoneValidationFieldRequired,
  stringField,
  validationText
} from "utils/validationRules";

import { EReceiptDestinationType } from "../PricingPaymentMethod/types";

type PricingPaymentFormSchema = {
  receipt_destination:
    RequiredStringSchema<string | undefined, Record<string, any>>
    | StringSchema<string | undefined, Record<string, any>>
    | Lazy<StringSchema<string | undefined, Record<string, any>, string | undefined>, Record<string, any>>
}
type TGetPricingPaymentFormSchema = Yup.ObjectSchema<Assign<
  ObjectShape, PricingPaymentFormSchema>>

export const getPricingPaymentFormSchema = 
  (type: EReceiptDestinationType, required: boolean): TGetPricingPaymentFormSchema => {
    const shape: PricingPaymentFormSchema = {
      receipt_destination: !required ? stringField : stringField.required(validationText.requiredText)
    };
    
    switch (type) {
    case EReceiptDestinationType.email: {
      shape.receipt_destination = !required ? emailValidationField : emailValidationFieldRequired;
      break;
    } 
    case EReceiptDestinationType.phone: {
      shape.receipt_destination = !required ? phoneValidationField : phoneValidationFieldRequired;
      break;
    }
    case EReceiptDestinationType.emailOrPhone:
      shape.receipt_destination = !required ? loginValidationField : loginValidationFieldRequired;
      break;
    }

    return Yup.object().shape(shape);
  };

