import { colors } from "config/constants";

{/* eslint-disable max-len */}
const AssetsIcon = (): JSX.Element => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M19.2 0H0.8C0.3575 0 0 0.3575 0 0.8V19.2C0 19.6425 0.3575 20 0.8 20H19.2C19.6425 20 20 19.6425 20 19.2V0.8C20 0.3575 19.6425 0 19.2 0ZM18.2 11.6H10.4V8.4H18.2V11.6ZM18.2 18.2H1.8V1.8H18.2V6.8H9.6C9.1575 6.8 8.8 7.1575 8.8 7.6V12.4C8.8 12.8425 9.1575 13.2 9.6 13.2H18.2V18.2ZM11.7 10C11.7 10.2652 11.8054 10.5196 11.9929 10.7071C12.1804 10.8946 12.4348 11 12.7 11C12.9652 11 13.2196 10.8946 13.4071 10.7071C13.5946 10.5196 13.7 10.2652 13.7 10C13.7 9.73478 13.5946 9.48043 13.4071 9.29289C13.2196 9.10536 12.9652 9 12.7 9C12.4348 9 12.1804 9.10536 11.9929 9.29289C11.8054 9.48043 11.7 9.73478 11.7 10Z"
      fill={colors.gray9}
    />
  </svg>
);

export default AssetsIcon;
