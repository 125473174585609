import { FC } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";

import { EQueries } from "config/types";

import { useShareLink } from "components/ShareLink/hooks";
import BNDropdown from "components/BNDropdown";

import useQuery from "hooks/useQuery";

import { openModal } from "store/modals/reducers";
import { EModals } from "store/modals/types";

export type ShareLinkProps = {
  path: string
}
const AssetSingleMenuitem: FC<ShareLinkProps> = (
  { path
  }) => {
  const { t } = useTranslation();
  const history = useHistory();
  const query = useQuery();
  const dispatch = useDispatch();

  const modalTitle = "BitOk";

  const handleClick = useShareLink({
    title: modalTitle,
    path,
    share: () => {
      query.append(EQueries.title, modalTitle);
      query.append(EQueries.path, path);
      history.replace({
        search: query.toString()
      });
      dispatch(openModal(EModals.shareLink));
    }
  });

  return ( <BNDropdown.Item
    onClick={() => {
      handleClick();
    }}
  >
    <span>{t('action.shareAccount')}</span>
  </BNDropdown.Item>
  );
};

export default AssetSingleMenuitem;
