import { FC } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import cn from "classnames";

import AMLActivate from "components/AMLActivate";

import { getUserInfoSelector } from "store/user/selectors";

import { LightningEmoji } from "utils/lightningEmoji";

const HeaderChecksLeft: FC = () => {
  const { t } = useTranslation();
  const { data, fetching } = useSelector(getUserInfoSelector);

  return (
    <AMLActivate.Dropdown>
      {(!fetching || data) && (
        <div className={cn("new-header__checks-left", { "no-checks": !data?.aml_checks_available })}>
          <span>
            {LightningEmoji}
            {data?.aml_checks_available
              ? t("amlPage.checksLeft", { number: data?.aml_checks_available })
              : t("amlPage.checksLeft", { number: 0 })
            }
          </span>
        </div>)}
    </AMLActivate.Dropdown>
  );
};

export default HeaderChecksLeft;
