import { FC } from "react";

const AuthCarouselSlider1: FC = () => (
  <div className="auth-carousel__slider-1">
    <img
      src={process.env.PUBLIC_URL + "/img/auth-slider-1/okman.png"}
      className="auth-carousel__slider-1__okman"
    />
    <img
      src={process.env.PUBLIC_URL + "/img/auth-slider-1/dashboard.png"}
      className="auth-carousel__slider-1__dashboard"
    />
  </div>
);

export default AuthCarouselSlider1;
