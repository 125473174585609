import { FC, useEffect } from "react";
import cn from "classnames";
import { useDispatch, useSelector } from "react-redux";

import { ESyncStatus } from "config/types";

import SyncLoader from "components/SyncLoader";
import SyncIcon from "components/Icons/SyncIcon";
import Button from "components/Buttons/Button";

import {
  getAssetsDashboardAssetsRequest,
  getAssetsDashboardSyncAccountRequest
} from "store/assets/reducers";
import { analyticEvent } from "store/analytics/effects";
import { EEventType } from "store/analytics/types";
import {
  assetsDashboardAssetsSelector,
  assetsDashboardSyncAccountSelector, startAccountSyncsSelector
} from "store/assets/selectors";
import { openModal } from "store/modals/reducers";
import { EModals } from "store/modals/types";

import { getRelativeTime } from "utils/dateHelpers";

import i18n from "../../../../../i18n";

enum SyncBtnStatus {
  synchronizing = "synchronizing",
  error = "error",
  noAccounts = "noAccounts",
  synced = "synced",
}

const HeaderSyncAccounts: FC = () => {
  const dispatch = useDispatch();
  const { data } = useSelector(assetsDashboardAssetsSelector);
  const { failure: error, syncLoading } = useSelector(assetsDashboardSyncAccountSelector);
  const startAccountsSyncStates = useSelector(startAccountSyncsSelector);
  const accountsSyncs = Object.values(startAccountsSyncStates).map(item => item.syncLoading).some(Boolean);

  const loading = syncLoading || accountsSyncs;

  const accountsExists = data?.accounts !== 0;

  const { text, status } = ((): { text: string, status: SyncBtnStatus } => {
    if (loading) {
      return { text: i18n.t("accountSync.synchronizing"), status: SyncBtnStatus.synchronizing };
    }
    if (data?.status === ESyncStatus.error || error) {
      return { text: i18n.t("dashboardYourAccounts.syncError"), status: SyncBtnStatus.error };
    }
    if (!accountsExists) {
      return { text: i18n.t("action.addAccount"), status: SyncBtnStatus.noAccounts };
    }
    if (data?.last_update && data?.status === ESyncStatus.synchronized) {
      return {
        text:`${i18n.t("dashboardYourAccounts.synced")} ${getRelativeTime(data?.last_update)}`,
        status: SyncBtnStatus.synced
      };
    }
    return { text: i18n.t("accountSync.synchronizing"), status: SyncBtnStatus.synchronizing };
  })();

  useEffect(() => {
    dispatch(getAssetsDashboardAssetsRequest({}));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const addAccount = () => {
    dispatch(openModal(EModals.assetModal));
  };

  const onClickSync = () => {
    if (!accountsExists) {
      dispatch(analyticEvent(EEventType.DASHBOARD_ACCOUNTS_ADD_FIRST));
      addAccount();
      return;
    }
    dispatch(analyticEvent(EEventType.DASHBOARD_ACCOUNTS_SYNC));
    dispatch(getAssetsDashboardSyncAccountRequest());
  };

  return (
    <div className="new-header__sync-account">
      <Button
        title={text}
        transparent={accountsExists}
        icon={(loading && <SyncLoader isSpin={true} />)
          || ((data?.status === ESyncStatus.synchronized || data?.status === ESyncStatus.error) && <SyncIcon/>)
        }
        onClick={onClickSync}
        className={cn("new-header__sync-account__btn", status)}
        disabled={loading}
      />
    </div>
  );
};

export default HeaderSyncAccounts;
