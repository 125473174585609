import { useMediaQuery } from "react-responsive";

import { maxMobileMediaWidth } from "config/constants";

type ShareLinkHandlerResult = () => void
type ShareLinkProps = {
  title: string
  path: string
  share: () => void
}
type ShareLink = ({ title, path }: ShareLinkProps) => ShareLinkHandlerResult;

export const useShareLink: ShareLink = ({ title, path, share }): ShareLinkHandlerResult => {
  const isMaxMobile = useMediaQuery({ query: `(max-width: ${maxMobileMediaWidth}px)` });

  return () => {

    if (navigator.share && isMaxMobile) {
      navigator.share({
        title,
        url : window.location.origin + path
      })
        // tslint:disable-next-line:no-console
        .catch(console.error);
    } else {
      share();
    }
  };
};

