import { Radio as $Radio } from 'antd';
import { FastField, Field, FieldProps } from 'formik';
import isFunction from "lodash/isFunction";

import { RadioGroupProps } from "./types";

const BNRadio = ({
  name,
  onChange,
  ...restProps
}: RadioGroupProps): JSX.Element => (
  <FastField name={name}>
    {({
      field: { value },
      form: { setFieldValue, setFieldTouched },
    }: FieldProps) => (
      <$Radio
        name={name}
        value={value}
        onChange={event => {
          setFieldValue(name, event.target.value);
          setFieldTouched(name, true, false);
          if (isFunction(onChange)) onChange(event);
        }}
        {...restProps}
      />
    )}
  </FastField>
);

BNRadio.Group = ({
  name,
  onChange,
  ...restProps
}: RadioGroupProps) => (
  <Field name={name}>
    {({
      field: { value },
      form: { setFieldValue, setFieldTouched },
    }: FieldProps) => (
      <$Radio.Group
        name={name}
        value={value}
        onChange={event => {
          setFieldValue(name, event.target.value);
          setFieldTouched(name, true, false);
          if (isFunction(onChange)) onChange(event);
        }}
        {...restProps}
      />
    )}
  </Field>
);

BNRadio.Button = $Radio.Button;

export default BNRadio;