import { FC } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import PreparedMobileDrawer from "components/PreparedMobileDrawer";
import VectorIcon from 'components/Icons/VectorIcon';

import { headerDrawerSelector } from "store/common/selectors";
import { setHeaderDrawer } from "store/common/reducers";

import PublicMobileSettingsForm from "../PublicMobileSettingsForm";


const PublicMobileSettingsDrawer: FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const visible = useSelector(headerDrawerSelector);

  const onClose = () => {
    dispatch(setHeaderDrawer(false));
  };

  return <PreparedMobileDrawer
    visible={visible}
    close={onClose}
    title={t("naming.settings")}
    closeIcon={<VectorIcon/>}
  >
    <div className="drawer-filter">
      <PublicMobileSettingsForm/>
    </div>
  </PreparedMobileDrawer>;
};

export default PublicMobileSettingsDrawer;