import { FC } from "react";
import { useSelector } from "react-redux";

import { paths } from "config/paths";
import { LinkIds } from "config/constants";

import { getMyAssetsSelector } from "store/assets/selectors";

import SidebarCollapse, { ECollapseKeys, TSidebarCollapseProps } from "../SidebarCollapse";

const SidebarAssets: FC<Pick<TSidebarCollapseProps, "title">> = ({ title }) => {
  const { data } = useSelector(getMyAssetsSelector);

  return (
    <SidebarCollapse
      title={title}
      id={LinkIds.ASSETS}
      assets={data || []}
      collapseKey={ECollapseKeys.accounts}
      path={paths.ASSETS}
      showRiskScore={false}
      showPlusIcon={true}
    />
  );
};

export default SidebarAssets;
