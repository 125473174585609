import { put, takeLatest, all, call, select } from "redux-saga/effects";
import { AxiosResponse } from "axios";
import omitBy from "lodash/omitBy";
import isFunction from "lodash/isFunction";
import request from "API";

import { ESyncStatus } from "config/types";
import { paths } from "config/paths";

import { TMyAssetsData } from "store/assets/types";
import { EEventType } from "store/analytics/types";

import { convertTransactionsFilterToList, convertUrlParams, TQueryParams } from "utils/url";
import { showError, showSuccess } from "utils/notifications";
import delay from "utils/delay";

import i18n from "../../i18n";
import { AxiosDataError } from "../../API/types";

import { IApplicationState, IPayloadAction } from "../rootInterface";
import { analyticEvent } from "../analytics/effects";
import { ETransactionsFilterKeys } from "../filter/types";
import { closeModal } from "../modals/reducers";
import { EModals } from "../modals/types";

import {
  getReportsRequest,
  getReportsFailure,
  getReportsSuccess,
  createReportSuccess,
  createReportFailure,
  createReportRequest,
  deleteReportFailure,
  deleteReportRequest,
  deleteReportSuccess,
  getReportTaxAmountRequest,
  getReportTaxAmountSuccess,
  getReportTaxAmountFailure,
  getCreateTaxReportAssetsListFailure,
  getCreateTaxReportAssetsListRequest,
  getCreateTaxReportAssetsListSuccess,
  createTaxReportingProjectFailure,
  createTaxReportingProjectSuccess,
  createTaxReportingProjectRequest,
  getSingleTaxReportRequest,
  getSingleTaxReportFailure,
  getSingleTaxReportSuccess,
  reportTransactionsFailure,
  reportTransactionsRequest,
  reportTransactionsSuccess,
  reportTaxTransactionTypesSuccess,
  reportTaxTransactionTypesFailure,
  reportTaxTransactionTypesRequest,
  reportAgreementFailure,
  reportAgreementRequest,
  reportAgreementSuccess,
  changeReportCheckStatusRequest,
  changeReportCheckStatusFailure,
  changeReportCheckStatusSuccess,
  changeReportTransactionsItemCheckStatus,
  changeAllReportCheckStatusFailure,
  changeAllReportCheckStatusSuccess,
  changeAllReportCheckStatusRequest,
  changeAllReportTransactionsItemCheckStatus,
  getSinglePersonalDataFailure,
  getSinglePersonalDataRequest,
  getSinglePersonalDataSuccess,
  createPersonalDataRequest,
  createPersonalDataFailure,
  createPersonalDataSuccess,
  createPersonalDataCompleteFailure,
  createPersonalDataCompleteSuccess,
  createPersonalDataCompleteRequest,
  taxReportResultFailure,
  taxReportResultRequest,
  taxReportResultSuccess,
  updateTaxReportAccountsData,
  updateTaxReportAccountsFailure,
  updateTaxReportAccountsRequest,
  reportTaxResultTransactionTypesRequest,
  reportTaxResultTransactionTypesSuccess,
  reportTaxResultTransactionTypesFailure,
  updateStateAfterDeletion,
  addTransactionToTaxReportRequest,
  addTransactionToTaxReportSuccess,
  addTransactionToTaxReportFailure,
  getCreateTransitionExportAssetsListSuccess,
  getCreateTransitionExportAssetsListFailure,
  getCreateTransitionExportAssetsListRequest,
  createTransactionExportSuccess,
  createTransactionExportFailure,
  createTransactionExportRequest,
  reportSingleRequest,
  reportSingleSuccess,
  reportSingleFailure,
  createSourcesExportRequest,
  createSourcesExportSuccess,
  createSourcesExportFailure,
  reportsTypesSuccess,
  reportsTypesFailure,
  reportsTypesRequest,
  createUnifiedTaxReportSuccess,
  createUnifiedTaxReportFailure,
  createUnifiedTaxReportRequest,
  updateUnifiedTaxReportRequest,
  updateUnifiedTaxReportSuccess,
  updateUnifiedTaxReportFailure,
  singleUnifiedTaxReportRequest,
  singleUnifiedTaxReportSuccess,
  singleUnifiedTaxReportFailure,
  unifiedTaxReportTransactionTypesRequest,
  unifiedTaxReportTransactionTypesSuccess,
  unifiedTaxReportTransactionTypesFailure,
  unifiedTaxReportFormSuccess,
  unifiedTaxReportFormFailure,
  unifiedTaxReportFormRequest,
} from "./reducers";
import {
  TOldReportOptions,
  TReport,
  TCreateTaxReportingProjectPayload,
  TCreateTaxReportingProjectData,
  TSingleTaxReportData,
  ESingleTaxReportTransactionStatus,
  TSingleReportPayload,
  TGetReportTransactionsPayload,
  TReportTransactionsData,
  TChangeReportCheckStatusPayload,
  TAllChangeReportCheckStatusPayload,
  TNewReportOptionData,
  TCreatePersonalDataPayload,
  TUpdateTaxReportAccountsPayload,
  TAddTransactionToTaxReportOptions,
  EReportTransactionType,
  TReportTaxAmountData,
  TCreateTransactionExportOptions,
  EReportStatus,
  TCreateSourcesExportOptions,
  TTaxReportResultData,
  TReportsTypesData,
  TReportData,
  TReportSingleData,
  TCreateUnifiedTaxReportOptions,
  TUpdateUnifiedTaxReportOptions,
  TSingleUnifiedTaxReportData, TUnifiedTaxReportTransactionTypesData, TUnifiedTaxReportFormOptions
} from "./types";

function* getReports() {
  try {
    const response: AxiosResponse<TReportData> = yield call(request.get, "/reports/");
    yield put(getReportsSuccess(response.data));
  } catch (e) {
    yield put(getReportsFailure(e));
  }
}

function* getTaxReportResult(action: IPayloadAction<string>) {
  try {
    const response: AxiosResponse<TTaxReportResultData>
      = yield call(request.get, `/reports/tax/${action.payload}/result/`);
    yield put(taxReportResultSuccess(response.data));
  } catch (e) {
    yield put(taxReportResultFailure(e));
  }
}

function* createReport(action: IPayloadAction<TOldReportOptions>) {
  try {
    const data = omitBy(
      action.payload.data,
      // TODO: Fix any
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      (value: any) => !value
    );
    const response: AxiosResponse<TReport> = yield call(request.post, "/reports/form-by-year/", data);

    yield put(createReportSuccess(response.data));
    action.payload.callOnSuccess();
  } catch (e) {
    yield put(createReportFailure(e));
  }
}

function* deleteReport(action: IPayloadAction<{ id: string, callOnSuccess?: (val: string) => void }>) { // 1
  const { id, callOnSuccess } = action.payload;
  try {
    const response: AxiosResponse = yield call(request.delete, `/reports/${id}/delete/`);
    yield put(deleteReportSuccess(response.data));
    yield put(updateStateAfterDeletion(id));

    yield put(closeModal(EModals.deleteReport));
    if (!!callOnSuccess) callOnSuccess(paths.DOCUMENTS);
    showSuccess(i18n.t("notification.deleteSingleTaxReport"));
  } catch (e) {
    yield put(deleteReportFailure(e));
  }
}

function* getReportsTaxAmount() {
  try {
    const response: AxiosResponse<TReportTaxAmountData[]> = yield call(request.get, "/reports/tax-amount-by-year/");
    yield reportsTaxAmountSetState(response.data);
  } catch (e) {
    yield put(getReportTaxAmountFailure(e));
  }
}

function* reportsTaxAmountSetState(data: TReportTaxAmountData[]) {
  const taxAmountState: TReportTaxAmountData[] =
    yield select((state: IApplicationState) => state.reports.taxAmountReport.data);

  const extra = data.slice(3, data.length);
  const updated = taxAmountState.map(item => data.find(el => el.year === item.year) || item);
  yield put(getReportTaxAmountSuccess([...extra, ...updated]));

}

function* getCreateDocumentAssets(action: IPayloadAction<{id: string, data: TQueryParams[]}>) {
  const { id, data } = action.payload;
  try {
    const params: URLSearchParams = convertUrlParams(data);
    const response: AxiosResponse<TMyAssetsData[]> = yield call(request.get, "/assets/accounts/", { params });
    yield put(getCreateTaxReportAssetsListSuccess({ id, data: response.data }));

    const needGetAssets = response.data.some(item => item.status === ESyncStatus.synchronizing);

    if (response.data.length && needGetAssets) {
      yield delay(5000);
      yield put(getCreateTaxReportAssetsListRequest(action.payload));
    }
  } catch (e) {
    const error = e as AxiosDataError;
    yield put(getCreateTaxReportAssetsListFailure({ id, error }));
  }
}

function* getCreateTransitionExportAssets(action: IPayloadAction<{ id: string, query: TQueryParams[]}>) {
  const { id, query } = action.payload;
  try {
    const params: URLSearchParams = convertUrlParams(query);
    const response: AxiosResponse<TMyAssetsData[]> = yield call(request.get, "/assets/accounts/", { params });
    yield put(getCreateTransitionExportAssetsListSuccess({ id, data: response.data }));

    const needGetAssets = response.data.some(item => item.status === ESyncStatus.synchronizing);

    if (response.data.length && needGetAssets) {
      yield delay(5000);
      yield put(getCreateTransitionExportAssetsListRequest(action.payload));
    }
  } catch (e) {
    const error = e as AxiosDataError;
    yield put(getCreateTransitionExportAssetsListFailure({ id, error }));
  }
}

function* createTaxReportProject(action: IPayloadAction<TCreateTaxReportingProjectPayload>) {
  const { callOnSuccess, data } = action.payload;
  try {
    const response: AxiosResponse<TCreateTaxReportingProjectData> = yield call(request.post, "/reports/tax/", data);
    yield put(createTaxReportingProjectSuccess(response.data));
    if (isFunction(callOnSuccess)) callOnSuccess(response.data.report_id);
  } catch (e) {
    yield put(createTaxReportingProjectFailure(e));
  }
}

function* createUnifiedTaxReport(action: IPayloadAction<TCreateUnifiedTaxReportOptions>) {
  const { data, callOnSuccess } = action.payload;

  try {
    const response: AxiosResponse<TCreateTaxReportingProjectData> =
      yield call(request.post, "/reports/unified-tax-reports/", data);
    yield put(createUnifiedTaxReportSuccess({ data: response.data }));
    if (isFunction(callOnSuccess)) callOnSuccess(response.data.report_id);
  } catch (e) {
    yield put(createUnifiedTaxReportFailure(e));
  }
}

function* updateUnifiedTaxReport(action: IPayloadAction<TUpdateUnifiedTaxReportOptions>) {
  const { data, callOnSuccess, id } = action.payload;

  try {
    const response: AxiosResponse<TCreateTaxReportingProjectData> =
      yield call(request.post, `/reports/unified-tax-reports/${id}/recalculate/`, data);
    yield put(updateUnifiedTaxReportSuccess(response.data));
    if (isFunction(callOnSuccess)) callOnSuccess();
  } catch (e) {
    yield put(updateUnifiedTaxReportFailure(e));
  }
}

function* singleUnifiedTaxReport(action: IPayloadAction<{ id: string, callOnError: () => void }>) {
  const { id, callOnError } = action.payload;

  try {
    const response: AxiosResponse<TSingleUnifiedTaxReportData> =
      yield call(request.get, `/reports/unified-tax-reports/${id}/`);
    yield put(singleUnifiedTaxReportSuccess({ id, data: response.data }));

    const { transactions_status, status } = response.data;

    if (status === EReportStatus.forming ||
      transactions_status === ESingleTaxReportTransactionStatus.forming
    ) {
      yield delay(5000);
      yield put(singleUnifiedTaxReportRequest({ id }));
    }

    if (status === EReportStatus.error) {
      yield showError(i18n.t("errors.getSingleTaxReport1"));
      return;
    }

    if (status === EReportStatus.draft &&
      transactions_status === ESingleTaxReportTransactionStatus.error) {
      yield showError(i18n.t("errors.getSingleTaxReport2"));
      return;
    }

  } catch (error) {
    yield put(singleUnifiedTaxReportFailure({ id, error }));
    if (isFunction(callOnError)) callOnError();
  }
}

function* unifiedTaxReportTransactionTypes(action: IPayloadAction<{ id: string }>) {
  const { id } = action.payload;

  try {
    const response: AxiosResponse<TUnifiedTaxReportTransactionTypesData> =
      yield call(request.get, `/reports/unified-tax-reports/${id}/transaction-types/`);
    yield put(unifiedTaxReportTransactionTypesSuccess({ id, data: response.data }));
  } catch (e) {
    const error = e as AxiosDataError;
    yield put(unifiedTaxReportTransactionTypesFailure({ id, error }));
  }
}

function* unifiedTaxReportForm(action: IPayloadAction<TUnifiedTaxReportFormOptions>) {
  const { id, callOnSuccess } = action.payload;

  try {
    yield call(request.post, `/reports/unified-tax-reports/${id}/form/`);
    yield put(unifiedTaxReportFormSuccess({ id }));
    if (isFunction(callOnSuccess)) callOnSuccess();
  } catch (e) {
    const error = e as AxiosDataError;
    yield put(unifiedTaxReportFormFailure({ id, error }));
  }
}

function* updateTaxReportAccounts(action: IPayloadAction<TUpdateTaxReportAccountsPayload>) {
  try {
    const { id, changeStepCallback, ...payload } = action.payload;

    const response: AxiosResponse<TCreateTaxReportingProjectData> = yield call(
      request.post, `/reports/tax/${id}/update-accounts/`, payload);
    yield put(updateTaxReportAccountsData(response.data));
    yield put(getSingleTaxReportRequest({ id }));

    yield put(analyticEvent(EEventType.TAXREPORT_STEP1_SAVED));

    changeStepCallback();
  } catch (e) {
    yield put(analyticEvent(EEventType.TAXREPORT_STEP1_TRANSACTIONS_ERROR));

    yield put(updateTaxReportAccountsFailure(e));
  }
}

function* getSingleTaxReport(action: IPayloadAction<TSingleReportPayload>) {
  const { id, callOnError } = action.payload;
  try {
    const response: AxiosResponse<TSingleTaxReportData> = yield call(request.get, `/reports/tax/${id}/`);
    yield put(getSingleTaxReportSuccess({ id, data: response.data }));

    const { transactions_status, status } = response.data;

    if (status === EReportStatus.forming ||
      transactions_status === ESingleTaxReportTransactionStatus.forming
    ) {
      yield delay(5000);
      yield put(getSingleTaxReportRequest({ id }));
    }

    if (status === EReportStatus.error) {
      yield showError(i18n.t("errors.getSingleTaxReport1"));
      return;
    }

    if (status === EReportStatus.draft &&
      transactions_status === ESingleTaxReportTransactionStatus.error) {
      yield showError(i18n.t("errors.getSingleTaxReport2"));
      return;
    }
  } catch (e) {
    yield put(getSingleTaxReportFailure({ id: action.payload, error: e }));
    if (!!callOnError) callOnError(paths.TAX_REPORT_CREATE);
  }
}

function* getSinglePerosnalDataReport(action: IPayloadAction<string>) {
  const id = action.payload;
  try {
    const response: AxiosResponse<TNewReportOptionData> = yield call(request.get, `/reports/tax/${id}/personal-data/`);
    yield put(getSinglePersonalDataSuccess({ id, data: response.data }));
  } catch (e) {
    yield put(getSinglePersonalDataFailure({ id: action.payload, error: e }));
  }
}

function* getReportTransactions(action: IPayloadAction<TGetReportTransactionsPayload>): Generator {
  const { id, size = 25, page = 1, isResult, taxTxType } = action.payload;
  try {

    if (action.payload.report_type === EReportTransactionType.filter) {
      action.payload.report_type = EReportTransactionType.all;
    }

    const params: URLSearchParams = convertUrlParams(convertTransactionsFilterToList({
      [ETransactionsFilterKeys.account]: action.payload[ETransactionsFilterKeys.account],
      [ETransactionsFilterKeys.asset]: action.payload[ETransactionsFilterKeys.asset],
      [ETransactionsFilterKeys.symbol]: action.payload[ETransactionsFilterKeys.symbol],
      [ETransactionsFilterKeys.type]: action.payload[ETransactionsFilterKeys.type],
      [ETransactionsFilterKeys.report_type]: action.payload[ETransactionsFilterKeys.report_type],
      [ETransactionsFilterKeys.tax_tx_type]: action.payload[ETransactionsFilterKeys.tax_tx_type],
      [ETransactionsFilterKeys.date_from]: action.payload[ETransactionsFilterKeys.date_from],
      [ETransactionsFilterKeys.date_to]: action.payload[ETransactionsFilterKeys.date_to],
    }));
    params.append('size', size.toString());
    params.append('page', page.toString());

    let response;
    if (taxTxType) {
      response = yield call(request.get, `/reports/unified-tax-reports/${id}/transactions/`, { params });
    } else if (isResult) {
      response = yield call(request.get, `/reports/tax/${id}/result-transactions/`, { params });
    } else {
      response = yield call(request.get, `/reports/tax/${id}/transactions/`, { params });
    }

    const { results, next, count } = (response as AxiosResponse<TReportTransactionsData>).data;

    yield put(reportTransactionsSuccess({ id, data: { page, data: results, next, count } }));
  } catch (e) {
    const error = e as AxiosDataError;
    yield put(reportTransactionsFailure({ id, error }));
  }
}

function* getReportTaxTransactionTypes(action: IPayloadAction<string>) {
  const id = action.payload;

  try {
    const response: AxiosResponse = yield call(request.get, `/reports/tax/${id}/transaction-types/`);
    yield put(reportTaxTransactionTypesSuccess({ id, data: response.data }));
  } catch (e) {
    const error = e as AxiosDataError;
    yield put(reportTaxTransactionTypesFailure({ id, error }));
  }
}

function* getReportTaxResultTransactionTypes(action: IPayloadAction<string>) {
  const id = action.payload;

  try {
    const response: AxiosResponse = yield call(request.get, `/reports/tax/${id}/result-transaction-types/`);
    yield put(reportTaxResultTransactionTypesSuccess({ id, data: response.data }));
  } catch (e) {
    const error = e as AxiosDataError;
    yield put(reportTaxResultTransactionTypesFailure({ id, error }));
  }
}

function* reportAgreement(action: IPayloadAction<string>) {
  try {
    const id = action.payload;
    yield put(analyticEvent(EEventType.TAXREPORT_STEP2_SAVED));

    const response: AxiosResponse = yield call(request.get, `/reports/tax/${id}/confirm-transactions/`);
    yield put(reportAgreementSuccess(response.data));
    yield put(getSingleTaxReportRequest({ id }));
  } catch (e) {
    yield put(reportAgreementFailure(e));
  }
}

function* changeReportCheckStatus(action: IPayloadAction<TChangeReportCheckStatusPayload>) {
  const { id, reportId, checked } = action.payload;
  try {
    const response: AxiosResponse = yield call(request.put, `reports/tax/${id}/check-transaction/`,
      { checked });

    yield put(changeReportCheckStatusSuccess({ id, data: response.data }));
    yield put(changeReportTransactionsItemCheckStatus({ ...action.payload, reportId }));

  } catch (error) {
    yield put(changeReportCheckStatusFailure({ id, error }));
  }
}

function* changeAllReportCheckStatus(action: IPayloadAction<TAllChangeReportCheckStatusPayload>) {
  try {
    yield put(analyticEvent(EEventType.TAXREPORT_STEP2_TRANSACTION_CHECKED_CHANGED));

    const { id, checked, type } = action.payload;

    const response: AxiosResponse = yield call(request.post, `reports/tax/${id}/check-transactions/`, {
      checked, type
    });

    yield put(changeAllReportCheckStatusSuccess(response.data));
    yield put(changeAllReportTransactionsItemCheckStatus({ id, checked }));

  } catch (e) {
    yield put(changeAllReportCheckStatusFailure(e));
  }
}

function* createPersonalData(action: IPayloadAction<TCreatePersonalDataPayload>) { // -1
  try {
    const { id, phone, isAnonymous, birthdate, ...payload } = action.payload;

    if (isAnonymous) {
      yield put(analyticEvent(EEventType.TAXREPORT_STEP3_SAVED_AS_ANONYMOUS));
    } else {

      yield put(analyticEvent(EEventType.TAXREPORT_STEP3_SAVED_FULLY));
    }

    const params: URLSearchParams = convertUrlParams([{ key: "is_anonymous", value: String(isAnonymous) }]);
    const newBirthdate = birthdate || null; // tepmorary, need refactoring, all empty string to null

    const newPhone = phone && phone.replace(/[^0-9]/g, "");

    const newPayload: TNewReportOptionData = {
      ...payload,
      phone: newPhone,
      birthdate: newBirthdate
    };

    const response: AxiosResponse = yield call(request.post, `reports/tax/${id}/personal-data/`,
      newPayload, { params });

    yield put(analyticEvent(EEventType.TAXREPORT_STEP3_COMPLETED));

    yield put(createPersonalDataSuccess(response.data));
    yield put(createPersonalDataCompleteRequest(id));

  } catch (e) {
    yield put(analyticEvent(EEventType.TAXREPORT_STEP3_FORMING_ERROR));
    yield put(createPersonalDataFailure(e));
  }
}

function* createPersonalDataComplete(action: IPayloadAction<string>) { // -2
  try {
    const id = action.payload;
    const response: AxiosResponse = yield call(request.post, `reports/tax/${id}/complete/`);

    yield put(createPersonalDataCompleteSuccess(response.data));
    yield put(getSingleTaxReportRequest({ id }));

  } catch (e) {
    yield put(createPersonalDataCompleteFailure(e));
  }
}

function* addTransactionToTaxReport(action: IPayloadAction<TAddTransactionToTaxReportOptions>) {
  try {
    const response: AxiosResponse =
      yield call(request.post, `reports/tax/${action.payload.report_id}/add-transaction/`, action.payload.data);
    yield put(addTransactionToTaxReportSuccess(response.data));

  } catch (e) {
    yield put(addTransactionToTaxReportFailure(e));
  }
}

function* createTransactionExport(
  action: IPayloadAction<{ data: TCreateTransactionExportOptions, callback: (v?: string) => void}>)
{
  const { data, callback } = action.payload;
  try {
    const response: AxiosResponse =
      yield call(request.post, "/reports/create-transaction-export/", data);
    yield put(createTransactionExportSuccess(response.data));
    if (isFunction(callback)) {
      callback(response.data.id);
    }
  } catch (e) {
    yield put(createTransactionExportFailure(e));
  }
}

function* createSourcesExport(
  action: IPayloadAction<TCreateSourcesExportOptions>)
{
  const { data, callback } = action.payload;
  try {
    const response: AxiosResponse =
      yield call(request.post, "/reports/create-sources-export/", data);
    yield put(createSourcesExportSuccess(response.data));
    if (isFunction(callback)) {
      callback(response.data.id);
    }
  } catch (e) {
    yield put(createSourcesExportFailure(e));
  }
}

function* getReportSingle(
  action: IPayloadAction<{id: string, callOnError: () => void}>)
{
  const { id, callOnError } = action.payload;
  try {
    const response: AxiosResponse<TReportSingleData> =
      yield call(request.get, `/reports/${id.trim()}/details/`);
    yield put(reportSingleSuccess(response.data));
    const { status } = response.data;

    if (status === EReportStatus.forming) {
      yield delay(5000);
      yield put(reportSingleRequest(action.payload));
    }
    if (status === EReportStatus.error) {
      yield showError(i18n.t("errors.getReportSingle"));
      if (isFunction(callOnError)) callOnError();
      return;
    }

  } catch (e) {
    yield put(reportSingleFailure(e));
  }
}

function* reportsTypes(action: IPayloadAction<{callOnSuccess: () => void}>) {
  const { callOnSuccess } = action.payload;
  try {
    const response: AxiosResponse<TReportsTypesData[]> =
      yield call(request.get, "/reports/types/");
    yield put(reportsTypesSuccess(response.data));
    if (isFunction(callOnSuccess)) callOnSuccess();
  } catch (e) {
    yield put(reportsTypesFailure(e));
  }
}

function* Saga(): Generator {
  yield all([
    takeLatest(getReportsRequest.type, getReports),
    takeLatest(taxReportResultRequest.type, getTaxReportResult),
    takeLatest(createReportRequest.type, createReport),
    takeLatest(deleteReportRequest.type, deleteReport),
    takeLatest(getReportTaxAmountRequest.type, getReportsTaxAmount),
    takeLatest(getCreateTaxReportAssetsListRequest.type, getCreateDocumentAssets),
    takeLatest(getCreateTransitionExportAssetsListRequest.type, getCreateTransitionExportAssets),
    takeLatest(createTaxReportingProjectRequest.type, createTaxReportProject),
    takeLatest(createUnifiedTaxReportRequest.type, createUnifiedTaxReport),
    takeLatest(updateUnifiedTaxReportRequest.type, updateUnifiedTaxReport),
    takeLatest(singleUnifiedTaxReportRequest.type, singleUnifiedTaxReport),
    takeLatest(unifiedTaxReportTransactionTypesRequest.type, unifiedTaxReportTransactionTypes),
    takeLatest(unifiedTaxReportFormRequest.type, unifiedTaxReportForm),
    takeLatest(updateTaxReportAccountsRequest.type, updateTaxReportAccounts),
    takeLatest(getSingleTaxReportRequest.type, getSingleTaxReport),
    takeLatest(reportTransactionsRequest.type, getReportTransactions),
    takeLatest(reportTaxTransactionTypesRequest.type, getReportTaxTransactionTypes),
    takeLatest(reportTaxResultTransactionTypesRequest.type, getReportTaxResultTransactionTypes),
    takeLatest(reportAgreementRequest.type, reportAgreement),
    takeLatest(changeReportCheckStatusRequest.type, changeReportCheckStatus),
    takeLatest(changeAllReportCheckStatusRequest.type, changeAllReportCheckStatus),
    takeLatest(createPersonalDataRequest.type, createPersonalData),
    takeLatest(createPersonalDataCompleteRequest.type, createPersonalDataComplete),
    takeLatest(getSinglePersonalDataRequest.type, getSinglePerosnalDataReport),
    takeLatest(addTransactionToTaxReportRequest.type, addTransactionToTaxReport),
    takeLatest(createTransactionExportRequest.type, createTransactionExport),
    takeLatest(createSourcesExportRequest.type, createSourcesExport),
    takeLatest(reportSingleRequest.type, getReportSingle),
    takeLatest(reportsTypesRequest.type, reportsTypes),
  ]);
}

export default Saga;
