import { FC } from 'react';
import isFunction from "lodash/isFunction";
import cn from "classnames";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";

import { EPlans, usePricingContext } from "components/Pricing/hooks";

import { analyticEvent } from "store/analytics/effects";
import { EEventType } from "store/analytics/types";

import i18n from "../../../../i18n";

const PricingSwitch: FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { plan, setPlan, isModal } = usePricingContext();
  const plans = [
    {
      title: i18n.t("pricing.monthly"),
      value: EPlans.monthly
    },
    {
      title: i18n.t("pricing.annually"),
      value: EPlans.annually
    },
  ];

  const handleClickPlan = ($plan: EPlans) => {
    if ($plan === EPlans.monthly) {
      dispatch(analyticEvent(EEventType.PRICING_SWITCHER_MONTH));
    } else {
      dispatch(analyticEvent(EEventType.PRICING_SWITCHER_YEAR));
    }
    if(isFunction(setPlan)) setPlan($plan);
  };

  return (
    <div className="pricing__switch">
      <div>
        <div className="pricing__switch__plans">
          {
            plans.map($plan => (
              <div
                key={$plan.value}
                className={cn("pricing__switch__plan", { active: $plan.value === plan })}
                onClick={() => handleClickPlan($plan.value)}
              >
                { $plan.title }
              </div>
            ))
          }
        </div>
      </div>

      <div className={cn("pricing__switch__text", { modal: isModal })}>
        {t("pricing.saveAnnualPlan")}
      </div>
    </div>
  );
};

export default PricingSwitch;