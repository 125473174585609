{/* eslint-disable max-len */}
const ExternalLinkIcon = (): JSX.Element => (
  <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M13.1812 2.77979L13.7198 3.31883L8.00937 9.02931L7.4707 8.49064L13.1812 2.77979Z" fill="#748ADA"/>
    <path d="M13.8334 6.47627H13.0715V3.42865H10.0239V2.66675H13.8334V6.47627ZM11.9287 13.3334H4.30961C3.66199 13.3334 3.16675 12.8382 3.16675 12.1906V4.57151C3.16675 3.92389 3.66199 3.42865 4.30961 3.42865H8.50008V4.19056H4.30961C4.08103 4.19056 3.92865 4.34294 3.92865 4.57151V12.1906C3.92865 12.4191 4.08103 12.5715 4.30961 12.5715H11.9287C12.1572 12.5715 12.3096 12.4191 12.3096 12.1906V8.00008H13.0715V12.1906C13.0715 12.8382 12.5763 13.3334 11.9287 13.3334Z" fill="#748ADA"/>
  </svg>

);

export default ExternalLinkIcon;
