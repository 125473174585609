import { FC } from "react";
import { Layout as AntLayout } from "antd";
import { useSelector } from "react-redux";
import cn from "classnames";

import { dashboardOnboardingSelector } from "store/common/selectors";

import SidebarBody from "./components/SidebarBody";

const { Sider: AntSider } = AntLayout;

const Sidebar: FC = () => {
  const { isRun } = useSelector(dashboardOnboardingSelector);

  return (
    <AntSider className={cn("sidebar", { onboardingIsRun: isRun })} theme="light">
      <SidebarBody/>
    </AntSider>
  );
};

export default Sidebar;
