import { ELinks, TLinks } from "config/types";
import { paths } from "config/paths";

import PushpinIcon from "components/Icons/PushpinIcon";
import ToolIcon from "components/Icons/ToolIcon";
import RocketIcon from "components/Icons/RocketIcon";
import UserIcon from "components/Icons/UserIcon";
import KeyIcon from "components/Icons/KeyIcon";

export const SETTINGS_LINKS: TLinks[] = [
  { id: "1", to: paths.SETTINGS, icon: ToolIcon, title: ELinks.SETTINGS_MAIN, active: true  },
  {
    id: "2",
    to: paths.SETTINGS_PLAN_AND_PAYMENTS,
    icon: RocketIcon,
    title: ELinks.SETTINGS_PLAN_AND_PAYMENT,
    active: process.env.REACT_APP_TARIFFS_DISABLED !== "true"
  },
  { id: "3", to: paths.SETTINGS_SAFETY, icon: UserIcon, title: ELinks.SETTINGS_SAFETY, active: true  },
  {
    id: "4",
    to: paths.SETTINGS_SERVICES,
    icon: PushpinIcon,
    title: ELinks.SETTINGS_SERVICES,
    active: false
  },
];

export const MOBILE_SETTINGS_LINKS: TLinks[] = [
  { id: "1", to: paths.SETTINGS, icon: ToolIcon, title: ELinks.SETTINGS_MAIN, active: true  },
  {
    id: "2",
    to: paths.SETTINGS_PLAN_AND_PAYMENTS,
    icon: RocketIcon,
    title: ELinks.SETTINGS_PLAN_AND_PAYMENT,
    active: process.env.REACT_APP_TARIFFS_DISABLED !== "true"
  },
  {
    id: "4",
    to: paths.KYT_API_KEYS,
    icon: KeyIcon,
    title: ELinks.KYT_API_KEYS,
    active: true
  },
  { id: "5", to: paths.SETTINGS_SAFETY, icon: UserIcon, title: ELinks.SETTINGS_SAFETY, active: true  },
  {
    id: "6",
    to: paths.SETTINGS_SERVICES,
    icon: PushpinIcon,
    title: ELinks.SETTINGS_SERVICES,
    active: false
  },
];
