import { FC } from "react";

const AuthCarouselSlider1: FC = () => (
  <div className="auth-carousel__slider-3">

    <img
      src={process.env.PUBLIC_URL + "/img/auth-slider-3/okman-big.png"}
      className="auth-carousel__slider-3__okman-big"
    />
    <img
      src={process.env.PUBLIC_URL + "/img/auth-slider-3/aml.png"}
      className="auth-carousel__slider-3__aml"
    />
    <img
      src={process.env.PUBLIC_URL + "/img/auth-slider-3/okman-small.png"}
      className="auth-carousel__slider-3__okman-small"
    />
  </div>
);

export default AuthCarouselSlider1;
