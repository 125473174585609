import { FC } from "react";
import { useTranslation } from "react-i18next";

const AccountBetaBadge: FC = () => {
  const { t } = useTranslation();

  return <div className="account-beta-badge">
    {t("naming.beta")}
  </div>;
};

export default AccountBetaBadge;
