import { FC, Fragment, useEffect } from "react";
import { Layout as AntLayout } from 'antd';
import cn from "classnames";
import { useMediaQuery } from "react-responsive";

import { maxMobileMediaWidth } from "config/constants";
import { ECurrency } from "config/types";
import { paths } from "config/paths";

import LoadingPage from "components/MainRouter/components/LoadingPage";

import Public404 from "pages/public/404";

import { EStorageKeys } from "utils/storageHeplers";

import PublicHeader from "../../../segments/PublicHeader";
import PublicMobileHeader from "../../../segments/PublicMobileHeader";

import { useLayoutAppNameContext } from "../../hooks";

import PublicChildLoader from "../PublicChildLoader";

const PublicContainer: FC = ({ children }) => {
  const isMaxMobile = useMediaQuery({ query: `(max-width: ${maxMobileMediaWidth}px)` });
  const currency = localStorage.getItem(EStorageKeys.CURRENCY) as ECurrency|undefined;
  const { routePath } = useLayoutAppNameContext();

  useEffect(() => {
    if (currency) return;
    localStorage.setItem(EStorageKeys.CURRENCY, ECurrency.usd);
  }, []);

  return (
    <Fragment>
      <AntLayout className="main-layout">
        <PublicChildLoader>
          {({ loading, show404 }) => (
            <>
              {!isMaxMobile ? <PublicHeader/> : <PublicMobileHeader/>}
              <main className={cn("main", {
                "p-t-0": routePath && [paths.PUBLIC_ACCOUNT_TRANSACTIONS].includes(routePath),
                "p-b-0": routePath && [paths.PUBLIC_AML].includes(routePath),
                croppedScreen: show404 || loading,
              })}
              >
                {!show404 && loading && <LoadingPage/>}
                {show404 && <Public404/>}
                {children}
              </main>
            </>
          )}
        </PublicChildLoader>
      </AntLayout>
    </Fragment>
  );
};

export default PublicContainer;
