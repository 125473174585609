{/* eslint-disable max-len */}
const TelegramIcon = (): JSX.Element => (
  <svg width="60" height="60" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M30 60C46.5685 60 60 46.5685 60 30C60 13.4315 46.5685 0 30 0C13.4315 0 0 13.4315 0 30C0 46.5685 13.4315 60 30 60Z" fill="url(#paint0_linear_8735_85551)"/>
    <path d="M11.1725 31.4674C14.6795 29.5357 18.5943 27.9234 22.252 26.3029C28.5448 23.6487 34.8625 21.0404 41.244 18.6122C42.4856 18.1984 44.7165 17.7939 44.9353 19.6338C44.8155 22.2383 44.3227 24.8276 43.9848 27.4168C43.1269 33.1108 42.1354 38.7853 41.1685 44.4606C40.8354 46.351 38.4673 47.3296 36.952 46.1198C33.3105 43.6601 29.641 41.2243 26.046 38.7075C24.8684 37.511 25.9604 35.7925 27.0121 34.938C30.0114 31.9823 33.1921 29.471 36.0346 26.3625C36.8014 24.511 34.5359 26.0714 33.7886 26.5496C29.6826 29.3791 25.6771 32.3813 21.3481 34.8681C19.1368 36.0853 16.5596 35.0451 14.3493 34.3659C12.3676 33.5454 9.46359 32.7187 11.1723 31.4677L11.1725 31.4674Z" fill="white"/>
    <defs>
      <linearGradient id="paint0_linear_8735_85551" x1="40.0025" y1="10.002" x2="25.0025" y2="45" gradientUnits="userSpaceOnUse">
        <stop stopColor="#37AEE2"/>
        <stop offset="1" stopColor="#1E96C8"/>
      </linearGradient>
    </defs>
  </svg>

);

export default TelegramIcon;
