import { FC } from 'react';
import { useHistory } from "react-router";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import cn from "classnames";

import { EQueries } from "config/types";

import Button from 'components/Buttons/Button';
import { TPricingPlanProps } from 'components/Pricing/types';
import { EPlans, usePricingContext } from "components/Pricing/hooks";

import { EModals } from "store/modals/types";
import { servicesAllSelectByCodeSelector } from "store/services/selectors";
import { EServiceCode, ETariffPlan, TServicesData } from "store/services/types";
import { userPlanInfoSelector } from "store/user/selectors";
import { analyticEvent } from "store/analytics/effects";
import { EEventType } from "store/analytics/types";

import useQuery from "../../../../hooks/useQuery";

import PricingCard from "../PricingCard";
import PricingCardFeatures from "../PricingCardFeatures";
import PricingProgress from "../PricingProgress";
import PricingCardPrice from "../PricingCardPrice";

import { availableFeatures } from "./constants";

const PricingPlanSmart: FC<TPricingPlanProps> = ({ active }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const query = useQuery();
  const { plan } = usePricingContext();
  const { isModal } = usePricingContext();
  const { data: userPlan } = useSelector(userPlanInfoSelector);
  const smartMonth = useSelector(servicesAllSelectByCodeSelector(EServiceCode.plansSmartMonth));
  const smartYear = useSelector(servicesAllSelectByCodeSelector(EServiceCode.plansSmartYear));
  const smart = (plan === EPlans.monthly ? smartMonth : smartYear) as TServicesData;
  const disabledSmart = userPlan && !(
    userPlan.plans_to_upgrade.includes(smart?.code)
    || userPlan.plans_to_renew.includes(smart?.code)
    || userPlan.plans_to_prepaid.includes(smart?.code)
    || userPlan.plans_to_change.includes(smart?.code)
  );

  const openPricingActivateModal = () => {
    if (active || !smart) return;

    dispatch(analyticEvent(EEventType.PRICING_SMART_CLICK));

    if (userPlan?.plans_to_upgrade.includes(smart.code)) {
      if (userPlan?.current_plan.paid_service_code) {
        openModal(EModals.pricingTariffUpgrade);
      } else {
        openModal();
      }
    } else if (userPlan?.plans_to_renew.includes(smart.code)) {
      openModal(EModals.pricingSelectTariffForNextRenewal);
    } else if (userPlan?.plans_to_prepaid.includes(smart.code)) {
      openModal(EModals.pricingTariffPrepayment);
    } else if (userPlan?.plans_to_change.includes(smart.code)) {
      openModal(EModals.pricingReplacingTariffWithReplaceCurrent);
    } else {
      openModal();
    }
  };

  const openModal = (modal: EModals|undefined = EModals.activatePricing) => {
    query.set(EQueries.modal, modal);
    query.set(EQueries.tariff, ETariffPlan.smart);
    query.set(EQueries.code, smart?.code);
    history.replace({
      search: query.toString()
    });
  };
  
  return (
    <PricingCard active={active} popular={true}>
      <div className="pricing__card__title">
        {t("pricing.smart")}
      </div>
      <PricingCardPrice active={active} currency={smart?.currency} price={smart?.price}/>

      {active
        ? <PricingProgress pricing={ETariffPlan.smart}/>
        : (
          <div className="pricing__container">
            <Button
              title={t("pricing.getSmart")}
              className={cn("pricing__card__btn smart", { modal: isModal })}
              lettuce={!isModal}
              onClick={openPricingActivateModal}
              disabled={!!disabledSmart}
            />
          </div>
        )
      }
      <PricingCardFeatures features={availableFeatures()} tariffPlan={ETariffPlan.smart}/>
    </PricingCard>
  );
};

export default PricingPlanSmart;