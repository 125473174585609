import { FC } from 'react';
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";

import { EModals } from 'store/modals/types';
import { EServiceCode } from "store/services/types";
import { userPlanSetNextPlanToRenewRequest } from "store/user/reducers";

import PricingConfirmModal from '../PricingConfirmModal';

const PricingModals: FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const modals =  [
    {
      modal: EModals.pricingTariffUpgrade,
      text: t("pricing.pricingTariffUpgradeText"),
    },
    {
      modal: EModals.pricingSelectTariffForNextRenewal,
      text: t("pricing.pricingSelectTariffForNextRenewalText"),
      submit: (serviceCode: EServiceCode) => {
        dispatch(userPlanSetNextPlanToRenewRequest({
          service_code: serviceCode,
        }));
      }
    },
    {
      modal: EModals.pricingTariffPrepayment,
      text: t("pricing.pricingTariffPrepaymentText"),
    },
    {
      modal: EModals.pricingReplacingTariffWithReplaceCurrent,
      text: t("pricing.pricingReplacingTariffWithReplaceCurrentText"),
    },
  ];

  return (
    <>
      {
        modals.map((item, index) => (
          <PricingConfirmModal
            key={index}
            text={item.text}
            modal={item.modal}
            submit={item.submit}
          />
        ))
      }
    </>
  );
};

export default PricingModals;
