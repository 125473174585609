import { MutableRefObject, useEffect, useRef, useState } from "react";

import { TNullable } from "../config/types";

type triggeredDebounce = (func: (val: any) => void, timeout: number|undefined) => (val: any) => void

const triggeredDebounce: triggeredDebounce = (func, ms ) => {
  let timer: ReturnType<typeof setTimeout>;
  return (...args: any[]) => {
    const loading = args[0];
    if (!timer || loading) {
      func.apply<any, any[], void>(this, args);
    }
    clearTimeout(timer);
    timer = setTimeout(() => { func.apply<any, any[], void>(this, args); }, ms);
  };
};

const useChainedLoading = (loaders: boolean[], ms=1000): boolean => {
  const [loading, setLoading] = useState(true);

  const func = ($loading: boolean) => {
    setLoading($loading);
  };

  const debounceRef = useRef<(val: TNullable<boolean>) =>
    void|null>(null) as MutableRefObject<(val: boolean) => void|null>;

  useEffect(() => {
    if (!debounceRef.current) {
      debounceRef.current = triggeredDebounce(func, ms);
    }
  }, []);

  useEffect(() => {
    if (!debounceRef.current) return;

    debounceRef.current(loaders.some(Boolean));
  }, [loaders]);

  return loading;
};

export default useChainedLoading;
