import { FC, HTMLAttributes } from "react";

{/* eslint-disable max-len */}
const GoogleIcon: FC<HTMLAttributes<SVGSVGElement>> = props => (
  <svg width="60" height="60" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path d="M0 30C0 13.4315 13.4315 0 30 0C46.5685 0 60 13.4315 60 30C60 46.5685 46.5685 60 30 60C13.4315 60 0 46.5685 0 30Z" fill="white"/>
    <path d="M30.0001 22.0267C32.4412 22.0267 34.0878 23.0811 35.0267 23.9622L38.6956 20.38C36.4423 18.2856 33.5101 17 30.0001 17C24.9156 17 20.5245 19.9178 18.3867 24.1644L22.5901 27.4289C23.6445 24.2944 26.5623 22.0267 30.0001 22.0267Z" fill="#EA4335"/>
    <path d="M42.48 30.2889C42.48 29.22 42.3933 28.44 42.2056 27.6311H30V32.4556H37.1644C37.02 33.6544 36.24 35.46 34.5067 36.6733L38.6089 39.8511C41.0644 37.5833 42.48 34.2467 42.48 30.2889Z" fill="#4285F4"/>
    <path d="M22.6044 32.5711C22.33 31.7622 22.1711 30.8956 22.1711 30C22.1711 29.1044 22.33 28.2378 22.59 27.4289L18.3867 24.1644C17.5056 25.9267 17 27.9056 17 30C17 32.0944 17.5056 34.0733 18.3867 35.8356L22.6044 32.5711Z" fill="#FBBC05"/>
    <path d="M29.9998 43C33.5098 43 36.4564 41.8445 38.6087 39.8511L34.5064 36.6733C33.4087 37.4389 31.9353 37.9733 29.9998 37.9733C26.562 37.9733 23.6442 35.7056 22.6042 32.5711L18.4009 35.8356C20.5387 40.0822 24.9153 43 29.9998 43Z" fill="#34A853"/>
  </svg>
);

export default GoogleIcon;
