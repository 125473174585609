import { FC, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { ECurrency } from "config/types";

import CurrencySelector from "components/CurrencySelector";

import { getUserInfoSelector } from "store/user/selectors";
import { userSaveLocalSettingsRequest } from "store/user/reducers";

const HeaderCurrency: FC = () => {
  const dispatch = useDispatch();
  const { data: userinfo } = useSelector(getUserInfoSelector);
  const [currency, setCurrency] = useState<ECurrency>();

  const handleSetCurrency = (value: ECurrency) => {
    setCurrency(value);
    dispatch(userSaveLocalSettingsRequest({
      data: {
        currency: value
      },
      callOnSuccess: () => location.reload()
    }));
  };

  useEffect(() => {
    setCurrency(userinfo?.currency || ECurrency.rub);
  }, [userinfo]);

  return (<div className="new-header__currency">
    {currency && <CurrencySelector currency={currency} setCurrency={handleSetCurrency}/>}
  </div>);
};

export default HeaderCurrency;
