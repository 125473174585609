import { FC } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";

import BNDropdown from "components/BNDropdown";

import { analyticEvent } from "store/analytics/effects";
import { EEventType } from "store/analytics/types";

import { EAssetsSingleMenuStatesKeys, TAssetSingleMenuItemProps, TAssetsSingleMenuDropdownProps } from "../../types";

const AssetSingleMenuRename: FC<TAssetSingleMenuItemProps & TAssetsSingleMenuDropdownProps> = ({
  setActive, setVisibleTrue
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const handleClickRename = () => {
    setVisibleTrue(EAssetsSingleMenuStatesKeys.rename);
    dispatch(analyticEvent(EEventType.WALLETS_ACCOUNT_RENAME_START));
  };

  return (
    <BNDropdown.Item
      onClick={() => {
        handleClickRename();
        setActive(false);
      }}
    >
      <span>{t('action.rename')}</span>
    </BNDropdown.Item>
  );
};

export default AssetSingleMenuRename;
