import { FC } from 'react';
import { useMediaQuery } from "react-responsive";

import { maxMobileMediaWidth } from "config/constants";

const UpgradePlanNoteImages: FC = () => {
  const isMobile = useMediaQuery({ query: `(max-width: ${maxMobileMediaWidth}px)` });
  return (
    <>
      <img
        src={process.env.PUBLIC_URL + "/img/upgrade-plan-modal/blue-polygon.png"}
        className="upgrade-plan-note__imgs__blue-polygon"
      />
      {!isMobile && <img
        src={process.env.PUBLIC_URL + "/img/upgrade-plan-modal/purple-opacity-group.png"}
        className="upgrade-plan-note__imgs__purple-opacity-group"
      />}
      <img
        src={process.env.PUBLIC_URL + "/img/upgrade-plan-modal/purple-group.png"}
        className="upgrade-plan-note__imgs__purple-group"
      />
      {!isMobile && <img
        src={process.env.PUBLIC_URL + "/img/upgrade-plan-modal/purple-vector.png"}
        className="upgrade-plan-note__imgs__purple-vector"
      />}
      {!isMobile && <img
        src={process.env.PUBLIC_URL + "/img/upgrade-plan-modal/blue-vector.png"}
        className="upgrade-plan-note__imgs__blue-vector"
      />}
      {isMobile && <img
        src={process.env.PUBLIC_URL + "/img/upgrade-plan-modal/purple-vector-mobile.png"}
        className="upgrade-plan-note__imgs__purple-vector-mobile"
      />}
      {isMobile && <img
        src={process.env.PUBLIC_URL + "/img/upgrade-plan-modal/purple-opacity-group-mobile.png"}
        className="upgrade-plan-note__imgs__purple-opacity-group-mobile"
      />}
    </>
  );
};

export default UpgradePlanNoteImages;