import { FC } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import { publicAccountTransactionsStateSelector } from "store/public/selectors";

const PublicMobileAccountTransactionTitle: FC = () => {
  const { t } = useTranslation();
  const { count } = useSelector(publicAccountTransactionsStateSelector);
  
  return <span>{t("publicAccount.transactions", { number: count || 0 })}</span>;
};

export default PublicMobileAccountTransactionTitle;