import { FC } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import dayjs from "dayjs";

import { paths } from "config/paths";

import { useLayoutAppNameContext } from "components/Layout/hooks";

import {
  getReportSingleSelector,
  getSingleTaxReportDataSelector, taxReportResultStateSelector
} from "store/reports/selectors";
import { EReportStatus } from "store/reports/types";
import { amlChecksDetailsSelector } from "store/amlChecks/selectors";
import { EAmlCheckType } from "store/amlChecks/types";

import { getPageTitle } from "utils/history";

import MobileHeaderAssetSingleTitle from "../MobileHeaderAssetSingleTitle";

const MobileHeaderTitle: FC = () => {
  const { t, i18n } = useTranslation();
  const { routePath } = useLayoutAppNameContext();
  const { data: amlCheckDetails, initialLoading: amlCheckDetailsInitialLoading }
    = useSelector(amlChecksDetailsSelector);

  const { data: report, fetching, initialLoading } = useSelector(getReportSingleSelector);
  const { id } = useParams<{ id: string }>();
  const SingleTaxReport = useSelector(getSingleTaxReportDataSelector(id));
  const showDate = !(fetching || initialLoading) && report?.status === EReportStatus.formed;
  const { fetching: loadingReport } = useSelector(taxReportResultStateSelector);

  const formPageTitle = () => {
    switch (routePath) {
    case paths.SINGLE_ASSET:
      return <MobileHeaderAssetSingleTitle/>;
    case paths.TAX_REPORT_FORMED:
      const showDateDocument = !loadingReport && report?.status === EReportStatus.formed;

      if (!showDateDocument) {
        return <span>{t("naming.taxForm")}</span>;
      }

      const reportCreatedAt = dayjs(SingleTaxReport?.updated_at).locale(i18n.language).format("DD.MM.YYYY");
      return <>
        <span>{t("naming.taxForm")}</span>
        <div className="header-mobile__sub-title">{t("naming.formationDate")} {reportCreatedAt}</div>
      </>;
    case paths.DOCUMENTS_TRANSACTION_EXPORT_FORMED:

      if (!showDate) {
        return <span>{t("documentCreateModalForm.transactionsExportTitle")}</span>;
      }

      const transactionExportCreatedAt = dayjs(report?.formed_at).locale(i18n.language).format("DD.MM.YYYY");
      return <>
        <span>{t("documentCreateModalForm.transactionsExportTitle")}</span>
        <div className="header-mobile__sub-title">{t("naming.formationDate")} {transactionExportCreatedAt}</div>
      </>;
    case paths.DOCUMENTS_SOURCES_EXPORT_FORMED:
      if (!showDate) {
        return t("documentCreateModalForm.sourcesExportTitle");
      }

      const sourcesExportCreatedAt = dayjs(report?.formed_at).locale(i18n.language).format("DD.MM.YYYY");
      return <>
        <span>{t("documentCreateModalForm.sourcesExportTitle")}</span>
        <div className="header-mobile__sub-title">{t("naming.formationDate")} {sourcesExportCreatedAt}</div>
      </>;
    case paths.AML_CHEKS_SINGLE:
      return <>
        <span>{amlCheckDetailsInitialLoading ? "" : amlCheckDetails?.type === EAmlCheckType.deposit
          ? t("amlChecks.transferCheck") : t("amlChecks.addressCheck")}</span>
        <div className="header-mobile__sub-title">
          {amlCheckDetailsInitialLoading ? "" : amlCheckDetails?.id}
        </div>
      </>;
    }

    return <span>{getPageTitle(routePath || "")}</span>;
  };

  return (
    <div className="header-mobile__title">
      {formPageTitle()}
    </div>
  );
};

export default MobileHeaderTitle;
