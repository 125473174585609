import { useEffect } from "react";

export const useOnwheel = (showNote: boolean, handleClickNext: () => void): void => {
  let flag = true;

  const onWheel = (e: WheelEvent) => {
    if(flag) {
      if (e.deltaY <= 0) return;
      handleClickNext();
      flag = false;
    }
  };

  useEffect(() => {
    if (!showNote) return;

    window.addEventListener("wheel", onWheel);
    return () => {
      window.removeEventListener("wheel", onWheel);
    };

  }, [showNote]);

};
