import { FC } from 'react';
import cn from "classnames";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";

import ExternalLinkIcon from "components/Icons/ExternalLinkIcon";

import { ETariffPlan } from 'store/services/types';

import { analyticEvent } from "../../../../store/analytics/effects";
import { EEventType } from "../../../../store/analytics/types";

import { usePricingContext } from "../../hooks";

import PricingCardActiveMarker from './components/PricingCardActiveMarker';
import PricingCardPopular from './components/PricingCardPopular';

export type TPricingCardProps = {
  pricing?: ETariffPlan
  active?: boolean
  popular?: boolean
}

const PricingCard: FC<TPricingCardProps> = ({ children, pricing, active, popular }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { isModal } = usePricingContext();

  return (
    <div className="pricing__card__wrapper">
      <div className={cn("pricing__card", pricing )}>
        {children}
        {active && <PricingCardActiveMarker/>}
        {(popular && !active) && <PricingCardPopular/>}
      </div>
      <a
        href="https://bitok.org/pricing"
        target="_blank"
        className={cn("pricing__card__external-link", { modal: isModal })}
        rel="noopener noreferrer"
        onClick={() => {
          dispatch(analyticEvent(EEventType.PRICING_LEARN_MORE));
        }}
      >
        {t("pricing.learnMore")}
        <ExternalLinkIcon/>
      </a>
    </div>
  );};

export default PricingCard;