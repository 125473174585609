{/* eslint-disable max-len */}

export interface ISendGAStatisticPayload {
  event: EEventType | string;
}

export enum EEventType {
  /**
   *  Авторизация
   */
  // * Регистрация
  AUTH_REG_PAGE = "auth:reg:page", // Отображение страницы регистрации (/registration)
  AUTH_REG_PHONE_SENT = "auth:reg:phone:sent", // Отправка телефона и пароля для регистрации
  AUTH_REG_PHONE_SUCCESS = "auth:reg:phone:success", // Успешная регистрации с помощью телефона и пароля
  AUTH_REG_EMAIL_SENT = "auth:reg:email:sent", // Отправка почты и пароля для регистрации
  AUTH_REG_EMAIL_SUCCESS = "auth:reg:email:success", // Завершение регистрации по почте
  AUTH_REG_SHOW_LOGIN = "auth:reg:show-login", // Клик по ссылке "Sign in" на форме регистрации

  REGISTRATION_SENT = 'registration_sent', // Отправка почты/телефона и пароля для регистрации
  REGISTRATION_SUCCESS = 'registration_success', // Завершение регистрации по почте/Завершение регистрации по телефону
  REGISTRATION_FORM_LOGIN_LINK = "registration_form_login_link", // Клик по ссылке "Sign in" на форме регистрации

  // * Вход
  AUTH_LOGIN_PAGE = 'auth:login:page', // Отображение страницы входа
  AUTH_LOGIN_PHONE_SENT = 'auth:login:phone:sent', // Отправка телефона и пароля для входа
  AUTH_LOGIN_PHONE_SUCCESS = 'auth:login:phone:success', // Успешный вход с помощью телефона и пароля
  AUTH_LOGIN_EMAIL_SENT = 'auth:login:email:sent', // Отправка почты и пароля для входа
  AUTH_LOGIN_EMAIL_SUCCESS = 'auth:login:email:success', // Успешный вход с помощью почты и пароля
  AUTH_LOGIN_SHOW_REG = 'auth:login:show-reg', // Клик по ссылке "Sign up" на форме входа
  AUTH_LOGIN_SHOW_PASSWORD_REST = 'auth:login:show-password-reset', // Клик по ссылке "Forgot password" на форме входа

  LOGIN_SENT = 'login_sent', // Отправка почты/телефона и пароля для входа
  LOGIN_SUCCESS = 'login_success', // Успешный вход в ЛК
  LOGIN_FORM_REG_LINK = "login_form_reg_link", // Клик по ссылке "Sign up" на форме входа
  LOGIN_FORM_RESET_LINK = "login_form_reset_link", // Клик по ссылке "Forgot password" на форме входа

  // сброса пароля
  AUTH_RESET_PAGE = "auth:reset:page", // Отображение страницы сброса пароля (/reset-password)

  // OAuth
  AUTH_OAUTH_GOOGLE_CLICK = "auth:oauth:google:click", // Клик на Google
  AUTH_OAUTH_FACEBOOK_CLICK = "auth:oauth:facebook:click", // Клик на Facebook
  AUTH_OAUTH_TELEGRAM_CLICK = "auth:oauth:telegram:click", // Клик на Telegram
  AUTH_OAUTH_GOOGLE_LOGIN = "auth:oauth:google:login", // Успешный логин через Google
  AUTH_OAUTH_FACEBOOK_LOGIN = "auth:oauth:facebook:login", // Успешный логин через Facebook
  AUTH_OAUTH_TELEGRAM_LOGIN = "auth:oauth:telegram:login", // Успешный логин через Telegram
  AUTH_OAUTH_GOOGLE_REG = "auth:oauth:google:reg", // Успешная регистрация через Google
  AUTH_OAUTH_FACEBOOK_REG = "auth:oauth:facebook:reg", // Успешная регистрация через Facebook
  AUTH_OAUTH_TELEGRAM_REG = "auth:oauth:telegram:reg", // Успешная регистрация через Telegram

  // * Сессии
  AUH_SESSION_NEW = 'auth:session:new', // Начало новой сессии. Возможные кейсы: 1. Токен уже хранился в localStorage.Открытие в новой вкладке создает новую сессию. 2. Пользователь сделал логин и получил токен. 3. Пользователь зарегистрировался и получил токен.
  AUH_SESSION_LOGOUT = 'auth:session:logout', // Пользователь выполнил логаут

  LOGOUT = 'logout',
  SIGN_IN_CLICKED = 'signInClicked',

  DOCS_POPUP_CONTINUE_TAXREPORT = 'docs_popup_continue_taxreport',
  DOCS_POPUP_CONTINUE_TAXREPORT_YES = 'docs_popup_continue_taxreport_yes',
  DOCS_POPUP_CONTINUE_TAXREPORT_CREATE_NEW = 'docs_popup_continue_taxreport_create_new',
  DOCS_TAXREPORT_DRAFT_OPEN = 'docs_taxreport_draft_open',
  DOCS_TAXREPORT_FORMED_OPEN = 'docs_taxreport_formed_open',

  TAXREPORT_STEP1_NEW_CREATED = 'taxreport_step1_new_created',
  TAXREPORT_STEP1_SAVED = 'taxreport_step1_saved',
  TAXREPORT_STEP2_SAVED = 'taxreport_step2_saved',
  TAXREPORT_STEP3_SAVED_FULLY = 'taxreport_step3_saved_fully',
  TAXREPORT_STEP3_SAVED_AS_ANONYMOUS = 'taxreport_step3_saved_as_anonymous',
  TAXREPORT_STEP3_COMPLETED = 'taxreport_step3_completed',

  TAXREPORT_STEP1_TRANSACTIONS_ERROR = 'taxreport_step1_transactions_error',
  TAXREPORT_STEP3_FORMING_ERROR = 'taxreport_step3_forming_error',

  TAXREPORT_STEP1_ACCOUNTS_CHANGED = 'taxreport_step1_accounts_changed',
  TAXREPORT_STEP1_ACCOUNTS_ADDED = 'taxreport_step1_account_added',
  TAXREPORT_STEP2_TRANSACTION_ADDED = 'taxreport_step2_transaction_added',
  TAXREPORT_STEP2_TRANSACTION_CHECKED_CHANGED = 'taxreport_step2_transaction_checked_changed',

  TAXREPORT_STEP1_EXIT = 'taxreport_step1_exit',
  TAXREPORT_STEP2_EXIT = 'taxreport_step2_exit',
  TAXREPORT_STEP3_EXIT = 'taxreport_step3_exit',

  TAXREPORT_STEP3_TO_STEP2 = 'taxreport_step3_to_step2',
  TAXREPORT_STEP3_TO_STEP1 = 'taxreport_step3_to_step1',
  TAXREPORT_STEP2_TO_STEP1 = 'taxreport_step2_to_step1',
  TAXREPORT_STEP1_CANCEL = 'taxreport_step1_cancel',
  TAXREPORT_STEP2_CANCEL = 'taxreport_step2_cancel',
  TAXREPORT_STEP3_CANCEL = 'taxreport_step3_cancel',

  TAXREPORT_STEP3_IFNS_ERROR = 'taxreport_step3_ifns_error',
  TAXREPORT_STEP3_OKTMO_ERROR = 'taxreport_step3_oktmo_error',
  TAXREPORT_STEP3_INN_ERROR = 'taxreport_step3_inn_error',

  // УСЛОВИЕ - УСЛУГА НЕ АКТИВИРОВАНА
  TAXREPORT_STEP2_PAYMENT_REQUESTED = 'taxreport_step2_payment_requested',
  TAXREPORT_STEP2_PAYMENT_STARTED = 'taxreport_step2_payment_started',
  TAXREPORT_STEP2_PAYMENT_CANCELED = 'taxreport_step2_payment_canceled',

  // УСЛОВИЕ - ДЕКЛАРАЦИЯ СФОРМИРОВАНА
  TAXREPORT_FORMED_TRANSACTIONS_SHOWN = 'taxreport_formed_transactions_shown',
  TAXREPORT_FORMED_ALL_DELETE = 'taxreport_formed_all_deleted',

  /**
   *  Биржи и кошельки
   */
  // * Общие события страницы
  WALLET_ADD_NEW = 'wallets:add-new', // Нажатие на кнопку "Добавить"
  WALLET_SHOW_ACCOUNT_INFO = 'wallets:show-account-info', // Клик по конкретному аккаунту (левый столбец на десктопе и список в мобилке) для отображения информации об аккаунте

  // * Попап "Добавление аккаунта"
  WALLET_NEW_ACCOUNT_STEP_1_START = 'wallets:new:step1:start', // Отображение Шага 1 добавления аккаунта
  WALLET_NEW_ACCOUNT_STEP_1_NOT_FOUND = 'wallets:new:step1:not-found', // В поиске на Шаге 1 вбили строку, для которой не были найдены биржи или кошельки
  WALLET_NEW_ACCOUNT_STEP_1_SHOW_ALL = 'wallets:new:step1:show-all', // Клик по вкладке "Все". При первом показе (когда "Все" стоит по дефолту) событие не вызываем - только смена вкладки.
  WALLET_NEW_ACCOUNT_STEP_1_SHOW_EXCHANGES = 'wallets:new:step1:show-exchanges', // Клик по вкладке "Биржи"
  WALLET_NEW_ACCOUNT_STEP_1_SHOW_WALLETS = 'wallets:new:step1:show-wallets', // Клик по вкладке "Кошельки"
  WALLET_NEW_ACCOUNT_STEP_1_SHOW_BLOCKCHAINS = 'wallets:new:step1:show-blockchains', // Клик по вкладке "Блокчейны"
  WALLET_NEW_ACCOUNT_STEP_1_CLOSE = 'wallets:new:step1:close', // Закрытие попапа "Добавление аккаунта" на Шаге 1 (крестик или клик за пределы попапа)
  // wallets:new_account:step2:start:{stock_code}
  WALLET_NEW_ACCOUNT_STEP_2_START = 'wallets:new:step2:start', // Выбор сервиса для второго шага
  // wallets:new_account:step2:finishing:{stock_code}
  WALLET_NEW_ACCOUNT_STEP_2_FINISHING = 'wallets:new:step2:finishing', // Нажатие на кнопку "Импорт" ("Защищенный импорт").Отправка запроса за бэк.
  // wallets:new_account:step2:success:{stock_code}
  WALLET_NEW_ACCOUNT_STEP_2_SUCCESS = 'wallets:new:step2:success', // Успешное завершение создания аккаунта
  // wallets:new_account:step2:fail:{stock_code}
  WALLET_NEW_ACCOUNT_STEP_2_FAIL = 'wallets:new:step2:fail', // Ошибка с сервера при создании аккаунта
  WALLET_NEW_ACCOUNT_STEP_2_CLOSE = 'wallets:new:step2:close', // Закрытие попапа "Добавление аккаунта" на Шаге 2 (крестик или клик за пределы попапа)
  WALLET_NEW_ACCOUNT_STEP_2_GO_BACK = 'wallets:new:step2:go-back', // Возврат с Шага 2 на Шаг 1 (стрелкой назад)

  // * Информация об аккаунте
  WALLETS_ACCOUNT_INFO = 'wallets:account:info', // Отображение блока "Информация об аккаунте"
  WALLETS_ACCOUNT_SYNC = 'wallets:account:sync', // Нажатие на кнопку "Синхронизировать" (или на пункт из выпадающего меню в мобилке)
  WALLETS_ACCOUNT_RENAME_START = 'wallets:account:rename:start', // Нажатие на "Переименовать" в выпадающем меню
  WALLETS_ACCOUNT_RENAME_SUCCESS = 'wallets:account:rename:success', // Успешное завершение переименования аккаунта
  WALLETS_ACCOUNT_CONFIGURE_START = 'wallets:account:configure:start', // Нажатие на "Параметры синхронизации" в выпадающем меню
  WALLETS_ACCOUNT_CONFIGURE_SUCCESS = 'wallets:account:configure:success', // Успешное завершение смены параметров синхронизации аккаунта
  WALLETS_ACCOUNT_DELETE_START = 'wallets:account:delete:start', // Нажатие на "Удалить аккаунт" в выпадающем меню
  WALLETS_ACCOUNT_DELETE_SUCCESS = 'wallets:account:delete:success', // Успешное завершение удаления аккаунта
  WALLETS_ACCOUNT_SHOW_MORE_ASSETS = 'wallets:account:show-more-assets', // Клик на "Показать еще"
  WALLETS_ACCOUNT_SHOW_MORE_TRANSACTIONS = 'wallets:account:show-transactions', // Клик на транзакции, которые потом открывают страницу "Операции"


  /**
   *  Дашборд
   */
  // * Виджеты
  DASHBOARD_ACCOUNTS_ADD_FIRST = 'dashboard:accounts:add-first', // Виджет "Accounts". Нажатие на кнопку "Добавить".
  DASHBOARD_ACCOUNTS_ADD_NEW = 'dashboard:accounts:add-new', // Виджет "Accounts". Нажатие на кнопку "+" для добавления аккаунта.
  DASHBOARD_ACCOUNTS_SYNC = 'dashboard:accounts:sync', // Виджет "Accounts". Запуск синхронизации.
  DASHBOARD_TAXES_ADD_ACCOUNT = 'dashboard:taxes:add-account', // Виджет "Taxes". Начало добавления аккаунта, если аккаунтов нет (клик на заглушку).
  DASHBOARD_TAXES_SHOW_DETAILS = 'dashboard:taxes:show-details', // Виджет "Taxes". Переход на страницу "Документы" после клик на налог одного из годов.
  DASHBOARD_ASSETS_ADD_ACCOUNT = 'dashboard:assets:add-account', // Виджет "Asset". Начало добавления аккаунта, если аккаунтов нет (клик на заглушку).
  DASHBOARD_ASSETS_SHOW_ALL = 'dashboard:assets:show-all', // Виджет "Asset". Клик на "Смотреть все".

  // * Символы
  DASHBOARD_SYMBOLS_HOVER = 'dashboard:symbols:hover', // Наведение на символ на десктопе
  DASHBOARD_SYMBOLS_CONFIGURE = 'dashboard:symbols:configure', // Нажатие на кнопку настроек символов (шестеренку)
  DASHBOARD_SYMBOLS_SAVE_CONFIG = 'dashboard:symbols:save-config', // Сохранение кастомного набора символов
  DASHBOARD_SYMBOLS_CLOSE_CONFIG = 'dashboard:symbols:close-config', // Закрытие окна настройки символов без сохранения

  // * Туториал
  DASHBOARD_SHOW_TUTORIAL = 'dashboard:show-tutorial', // Клик на "How to Use".

  // * Виджет "Asset Value"
  DASHBOARD_ASSETS_VALUE_SHOW_7_DAYS = "dashboard:asset-value:show-7-days", // Клик на режим "7 дней"
  DASHBOARD_ASSETS_VALUE_SHOW_30_DAYS = "dashboard:asset-value:show-30-days", // Клик на режим "30 дней"
  DASHBOARD_ASSETS_VALUE_SHOW_2023 = "dashboard:asset-value:show-2023", // Клик на режим "2023"
  DASHBOARD_ASSETS_VALUE_SHOW_2024 = "dashboard:asset-value:show-2024", // Клик на режим "2024"
  DASHBOARD_ASSETS_VALUE_SHOW_2025 = "dashboard:asset-value:show-2025", // Клик на режим "2025"
  DASHBOARD_ASSETS_VALUE_SHOW_2021 = "dashboard:asset-value:show-2021", // Клик на режим "2021"
  DASHBOARD_ASSETS_VALUE_SHOW_CUSTOM_PERIOD = "dashboard:asset-value:show-custom-period", // Установление кастомного диапазона дат
  DASHBOARD_ASSETS_VALUE_INTERACTION = "dashboard:asset-value:interaction", // Пользователя навел курсор мыши на показания графика. Отправляем событие лишь один раз в рамках существования инстанса графика.

  // * Виджет "Asset Distribution"
  DASHBOARD_DISTRIBUTION_SHOW_EXCHANGE = "dashboard:distribution:show-exchanges", // Клик на режим "Биржи"
  DASHBOARD_DISTRIBUTION_SHOW_ASSETS = "dashboard:distribution:show-assets", // Клик на режим "Криптоактивы"
  DASHBOARD_DISTRIBUTION_SHOW_INTERACTION = "dashboard:distribution:interaction", // Пользователя навел курсор мыши на сектор диаграммы. Отправляем событие лишь один раз в рамках существования инстанса диаграммы.

  // * Виджет "P2P Exchange"
  DASHBOARD_P2P_SHOW_TODAY = "dashboard:p2p:show-today", // Клик на режим "Сегодня"
  DASHBOARD_P2P_SHOW_7_DAYS = "dashboard:p2p:show-7-days", // Клик на режим "7 дней"
  DASHBOARD_P2P_SHOW_14_DAYS = "dashboard:p2p:show-14-days", // Клик на режим "7 дней"
  DASHBOARD_P2P_SHOW_30_DAYS = "dashboard:p2p:show-30-days", // Клик на режим "30 дней"
  DASHBOARD_P2P_INTERACTION = "dashboard:p2p:interaction", // Пользователя навел курсор мыши на столбец графика. Отправляем событие лишь один раз в рамках существования инстанса графика.

  // * Виджет "AML Risk Score"
  DASHBOARD_AML_ADD_WALLET = "dashboard:aml:add-wallet", // Нажатие на кнопку "Add wallet"
  DASHBOARD_AML_VIEW_DETAILS = "dashboard:aml:view-details", // Нажатие на кнопку "View details"
  DASHBOARD_AML_CHECK_AMLLY = "dashboard:aml:check-amlly", // Нажатие на кнопку "Check more wallets"

  /**
   *  AML
   */
  AML_SHOW_ACCOUNT_INFO = "aml:show-account-info", // Нажатие на конкретный аккаунт из списка
  /**
   *  Форма оплаты услуги
   */
  PAYMENT_STEP1 = "payment:step1", // Отображение шага 1 формы покупки услуги. Вызывается также при смене услуги на альтернативную (например, при смене годовой подписки на месячную).
  PAYMENT_STEP1_ALT_CLICKED = "payment:step1:alt-clicked", // Вызов выпадающего списка с альтернативными услугами.
  PAYMENT_STEP1_ALT_SELECTED = "payment:step1:alt-selected", // Выбор новой услуги выпадающего списка (с альтернативными услугами).
  PAYMENT_STEP1_SERVICE_CODE = "payment:step1/{service_code}", // (service_code - код выбранной услуги)
  PAYMENT_STEP1_PROMO_APPLIED = "payment:step1:promo:applied", // Успешное применение промокода после нажатие на кнопку "Применить" (промокод)
  PAYMENT_STEP1_PROMO_FAILED = "payment:step1:promo:failed", // Неудачное применение промокода после нажатие на кнопку "Применить" (промокод)
  PAYMENT_STEP1_CURRENCY_CHANGED = "payment:step1:currency:changed", // Изменена валюта платежа
  PAYMENT_STEP1_NEXT = "payment:step1:next", // Нажатие на кнопку "Pay"
  PAYMENT_STEP2 = "payment:step2", // Отображение шага 2 формы покупки услуги.
  PAYMENT_STEP2_METHOD_CHANGED = "payment:step2:method:changed", // Смена метода оплаты на шаге 2
  PAYMENT_STEP2_PAY = " payment:step2:pay",
  PAYMENT_STEP2_PAY_NEW = "payment:step2:pay-new", // Нажатие на "Pay" на шаге 2 при выборе нового способа платежа
  PAYMENT_STEP2_PAY_SAVED = "payment:step2:pay-saved", // Нажатие на "Pay" на шаге 2 при выборе сохраненного ранее способа платежа


  /**
   *  Pricing & Upgrade plan
   */
  // * Карточки тарифов (Pricing)
  PRICING_PAGE = "pricing:page", // Показ страницы Pricing
  PRICING_POPUP = "pricing:popup", // Показ попапа Pricing (после Upgrade plan)
  PRICING_POPUP_CLOSE = "pricing:popup:close", // Закрытие попапа Pricing
  PRICING_SWITCHER_MONTH = "pricing:switcher:month", // Переключение на оплату за месяц
  PRICING_SWITCHER_YEAR = "pricing:switcher:year", // Переключение на оплату за год
  PRICING_SMART_CLICK = "pricing:smart:click", // Клик на кнопку Get SMART (на карточке тарифа)
  PRICING_PRO_CLICK = "pricing:pro:click", // Клик на кнопку Get PRO (на карточке тарифа)
  PRICING_LEARN_MORE = "pricing:learn-more", // Клик на Learn more (под карточкой тарифа)
  // * Попап "Upgrade plan"
  UPGRADE_PLAN_POPUP = "upgrade-plan:popup", // Появление попапа Upgrade plan
  UPGRADE_PLAN_CLOSE = "upgrade-plan:close", // Закрытие попапа Upgrade plan
  UPGRADE_PLAN_NEXT = "upgrade-plan:next", // Нажатие на кпопку "Select plan" попапа Upgrade plan

  /**
   *  Транзакции
   */
  // * Общее
  TRANSACTIONS_PAGE = "transactions:page", // Показ страницы "Transactions"
  // * Фильтр
  TRANSACTIONS_FILTER_ACCOUNT = "transactions:filter:account", // Выбор/смена аккаунта
  TRANSACTIONS_FILTER_CURRENCY = "transactions:filter:currency", // Выбор/смена валюты
  TRANSACTIONS_FILTER_TYPE = "transactions:filter:type", // Выбор/смена типа транзакции
  TRANSACTIONS_FILTER_DATE_RANGE = "transactions:filter:date-range", // Выбор/смена временного диапазона
  TRANSACTIONS_FILTER_CLEAR = "transactions:filter:clear", // Очистка фильтра
  // * Навигация
  TRANSACTIONS_NAV_SHOW_MORE = "transactions:nav:show-more", // Клик на "Show more"
  TRANSACTIONS_NAV_PAGE = "transactions:nav:page", // Клик по номеру страницы
  TRANSACTIONS_NAV_NEXT = "transactions:nav:next", // Клик по кнопке Next (стрелка вправо)
  TRANSACTIONS_NAV_PREV = "transactions:nav:prev", // Клик по кнопке Previous (стрелка влево)
  TRANSACTIONS_NAV_GO_TO = "transactions:nav:go-to", // Переход на введенный номер страницы
}
