import { FC, useEffect, useMemo, useState } from 'react';
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Form, Formik } from 'formik';

import { TNullable } from "config/types";

import Button from "components/Buttons/Button";
import BNCheckbox from "components/BNCheckbox";
import BNInput from "components/BNInput";
import AnimatedVisibility from "components/AnimatedVisibility";

import {
  paymentStateSelector,
} from "store/services/selectors";
import { EReceiptDestinationTypes, TUserPaymentMethodsData } from "store/user/types";
import { paymentFailure } from "store/services/reducers";

import PricingPaymentMethods from "../PricingPaymentMethods";
import { getPricingPaymentFormSchema } from "../PricingPaymentMethods/validation";

import { EReceiptDestinationType } from "./types";

export type TPricingPaymentMethodProps = {
  submitText: string;
  loading?: boolean;
  onSubmit: (val: PricingPaymentMethodValues) => void;
  paymentMethods: TUserPaymentMethodsData[]
  newPaymentMethod?: boolean
}

export type PricingPaymentMethodValues = {
  payment_method: TNullable<TUserPaymentMethodsData>
  save_method: boolean
  receipt_destination?: TNullable<string>
}

const PricingPaymentMethod: FC<TPricingPaymentMethodProps> = ({ 
  submitText,
  onSubmit,
  loading,
  paymentMethods,
  newPaymentMethod
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { failure: paymentStateError } = useSelector(paymentStateSelector);
  const parsedErrors = paymentStateError?.parsedErrors;

  const [paymentMethod, setPaymentMethod] = useState<TNullable<TUserPaymentMethodsData>>(null);

  const $defaultPaymentMethod = paymentMethods
    ? paymentMethods.find(item => item.is_default) || paymentMethods[0]
    : null;

  const initialValues: PricingPaymentMethodValues = {
    payment_method: paymentMethod,
    save_method: paymentMethod? paymentMethod.can_be_saved : true,
    receipt_destination: paymentMethod?.receipt_destination?.value || ""
  };

  const onSelectMethod = (value: string) => {
    const [type, id] = value.split("/");

    if (id !== "null") {
      const selectedById = paymentMethods.find(item => item.id === id);
      if (paymentMethod?.id !== id) {
        setPaymentMethod(selectedById || null);
      }
    } else {
      const selected = paymentMethods.find(item => item.type === type);
      if (paymentMethod?.type !== type) {
        setPaymentMethod(selected || null);
      }
    }
  };
  const receiptDestinationType: EReceiptDestinationType = useMemo(() => {
    if (!paymentMethod || !paymentMethod?.receipt_destination) {
      return EReceiptDestinationType.none;
    }

    switch (paymentMethod?.receipt_destination.type) {
    case EReceiptDestinationTypes.EMAIL: {
      return EReceiptDestinationType.email;
    }
    case EReceiptDestinationTypes.PHONE: {
      return EReceiptDestinationType.phone;
    }
    case EReceiptDestinationTypes.EMAIL_OR_PHONE: {
      return EReceiptDestinationType.emailOrPhone;
    }
    default:
      return EReceiptDestinationType.none;
    }
  }, [paymentMethod]);

  const formSaveMethodText = (type: EReceiptDestinationType) => {
    switch (type) {
    case EReceiptDestinationType.email: {
      return t("pricing.mailForCheck");
    }
    case EReceiptDestinationType.phone: {
      return t("pricing.phoneForCheck");
    }
    case EReceiptDestinationType.emailOrPhone:
      return t("pricing.phoneOrMailForCheck");
    default:
      return t("pricing.phoneOrMailForCheck");
    }
  };

  useEffect(() => {
    setPaymentMethod($defaultPaymentMethod);
  }, [paymentMethods]);

  return (
    <Formik
      enableReinitialize={true}
      initialValues={initialValues}
      validationSchema={() =>
        getPricingPaymentFormSchema(receiptDestinationType, !!paymentMethod?.receipt_destination?.required)}
      onSubmit={onSubmit}
    >
      <Form className="pricing__activate__form">
        {paymentMethods && paymentMethod && <PricingPaymentMethods
          selected={paymentMethod}
          loading={loading}
          paymentMethods={paymentMethods}
          setPaymentMethod={onSelectMethod}
        />}
        <div className="pricing__activate__form__additional">
          {!newPaymentMethod && <AnimatedVisibility
            show={!!paymentMethod?.can_be_saved}
          >
            <BNCheckbox name="save_method" className="pricing__activate__save-payment-method" disabled={loading}>
              {t("pricing.savePaymentMethod")}
            </BNCheckbox>
          </AnimatedVisibility>}
          <AnimatedVisibility
            show={!!paymentMethod?.receipt_destination}
          >
            <BNInput
              disabled={loading}
              wrapperClass="pricing__activate__receipt-destination"
              type="text"
              name="receipt_destination"
              label={formSaveMethodText(receiptDestinationType)}
              error={parsedErrors?.receipt_destination?.receipt_destination}
              onFocus={() => dispatch(paymentFailure(null))}
            />
          </AnimatedVisibility>
        </div>
        <Button
          title={submitText}
          className="pricing__activate__btn"
          loading={loading}
          htmlType="submit"
        />
      </Form>
    </Formik>
  );
};

export default PricingPaymentMethod;
