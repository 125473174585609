import { FC } from "react";
import cn from "classnames";
import { Checkbox as AntdCheckbox } from "antd";
import { NavLink } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";

import { paths } from "config/paths";

import { analyticEvent } from "../../../../store/analytics/effects";
import { EEventType } from "../../../../store/analytics/types";

import { AuthCheckboxProps } from "./types";

const AuthCheckbox: FC<AuthCheckboxProps> = ({ isSignIn, onChange, checkValidation }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  if (isSignIn) {
    return  (
      <div className="sign-form_forgot-remember">
        <AntdCheckbox className="sign-form_checkbox">{t("auth.remember")}</AntdCheckbox>
        <NavLink to={paths.FORGOT} className="text" exact={true} 
          onClick={() => {
            dispatch(analyticEvent(EEventType.AUTH_LOGIN_SHOW_PASSWORD_REST));
            dispatch(analyticEvent(EEventType.LOGIN_FORM_RESET_LINK));
          }}
        >
          {t("auth.forgotPassword")}
        </NavLink>
      </div>
    );
  }
  
  return (
    <>
      <AntdCheckbox
        onChange={onChange}
        name="acceptTerms"
        className={cn("sign-form_checkbox", {
          checkboxError: checkValidation,
        })}
      >
        {t("auth.acceptTerms")}{" "}
        <a href="https://bitok.org/terms-of-service"
          target="_blank"
          rel="noopener noreferrer"
          className="checkbox-link">
          {t('auth.userAgreement')}
        </a>
        {` ${t("naming.and")} `}
        <a href="https://bitok.org/privacy"
          target="_blank"
          rel="noopener noreferrer"
          className="checkbox-link">
          {t("auth.privacyPolicy")}
        </a>
      </AntdCheckbox>
    </>
  );
};

export default AuthCheckbox;
