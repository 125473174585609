import { FC } from "react";
import { useTranslation } from "react-i18next";

import PublicHeaderRedirectButton from "../../components/segments/PublicHeader/components/PublicHeaderRedirectButton";

const Public404: FC = () => {
  const { t } = useTranslation();

  return (
    <div className="public-404 mob-p-16">
      <div className="public-404__container">
        <img
          className="public-404__okman-big"
          src={process.env.PUBLIC_URL + "/img/public-404/okman-big.png"}
        />
        <img
          className="public-404__okman-small"
          src={process.env.PUBLIC_URL + "/img/public-404/okman-small.png"}
        />
        <div className="public-404__text">{t("404.pageNoLongerAvailable")}</div>
        <PublicHeaderRedirectButton className="public-404__redirect" is404={true}/>
      </div>
    </div>
  );
};

export default Public404;