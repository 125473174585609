import { FC, useMemo } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";

import { paths } from "config/paths";

import VectorIcon from "components/Icons/VectorIcon";
import { useLayoutAppNameContext } from "components/Layout/hooks";

import { setMobileAdditionalMenu } from "store/common/reducers";

const MobileHeaderLeft: FC = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { routePath } = useLayoutAppNameContext();

  const vectorBack: Record<string, () => void> = useMemo(() => ({
    [paths.AML_CHEKS_SINGLE]: () => {
      history.push(paths.AML_CHEKS);
    },
    [paths.SINGLE_ASSET]: () => {
      history.push(paths.ASSETS);
    },
    [paths.TAX_REPORT_FORMED]: () => {
      history.push(paths.DOCUMENTS);
    },
    [paths.DOCUMENTS_TRANSACTION_EXPORT_FORMED]: () => {
      history.push(paths.DOCUMENTS);
    },
    [paths.DOCUMENTS_SOURCES_EXPORT_FORMED]: () => {
      history.push(paths.DOCUMENTS);
    },
    [paths.SETTINGS]: () => {
      dispatch(setMobileAdditionalMenu(true));
    },
    [paths.SETTINGS_PLAN_AND_PAYMENTS]: () => {
      dispatch(setMobileAdditionalMenu(true));
    },
    [paths.SETTINGS_SAFETY]: () => {
      dispatch(setMobileAdditionalMenu(true));
    },
    [paths.KYT_API_KEYS]: () => {
      dispatch(setMobileAdditionalMenu(true));
    },
  }), []);

  const handleClickBack = () => {
    if (!routePath) return;

    const call = vectorBack[routePath];
    if (call) call();
  };

  const showVectorBack = Object.keys(vectorBack).includes(routePath as paths);
  return (
    <div className="header-mobile__left">
      {showVectorBack && <VectorIcon className="header-mobile__left__back" onClick={handleClickBack}/>}
    </div>
  );
};

export default MobileHeaderLeft;
