import React, { FC, ReactElement, useEffect, useRef } from "react";
import { useDispatch } from "react-redux";
import cn from "classnames";
import { useMediaQuery } from "react-responsive";

import { maxMobileMediaWidth } from "config/constants";

import BNDropdown from "components/BNDropdown";


import { openModal } from "store/modals/reducers";
import { EModals } from "store/modals/types";

import AMLActivatePacks from "../AMLActivatePacks";

export const CHECK_AML_BUTTON_ID = "check-for-aml";

const AMLActivateDropdown: FC<{ className?: string }> = ({ children, className }) => {
  const dispatch = useDispatch();
  const isMaxMobile = useMediaQuery({ query: `(max-width: ${maxMobileMediaWidth}px)` });
  const available = process.env.REACT_APP_AML_ACTIVATE_DISABLED !== "true";
  const checkAMLButton = useRef<HTMLDivElement|null>(null);

  const overlay = () => (isMaxMobile ? <></> : <AMLActivatePacks/>);

  const handleClick = () => {
    dispatch(openModal(EModals.amlActivate));
  };

  useEffect(() => {
    if (!checkAMLButton.current) {
      const element = document.getElementById(CHECK_AML_BUTTON_ID) as HTMLDivElement;
      if (element) {
        checkAMLButton.current = element;
      }
    }
  }, []);

  return (
    <BNDropdown
      overlay={overlay}
      className={cn("aml-activate-dropdown", className)}
      active={(available && !isMaxMobile)}
      onClick={handleClick}
      outsideClickIgnore={checkAMLButton.current ? [checkAMLButton] : []}
    >
      {({ active }) =>
        <>
          {children && React.cloneElement(children as ReactElement,
            { className: cn((children as ReactElement).props.className, { "active": active }), })}
        </>
      }
    </BNDropdown>
  );
};

export default AMLActivateDropdown;
