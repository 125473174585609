import { FC } from "react";

import VectorIcon from "components/Icons/VectorIcon";

export type HeaderBackProps = {
  onClick: () => void,
  title: string
}
const HeaderBack: FC<HeaderBackProps> = ({ onClick, title }) => (
  <div className="new-header__back" onClick={onClick}>
    <VectorIcon className="new-header__back__icon" onClick={onClick}/>
    <div className="new-header__back__title">
      {title}
    </div>
  </div>
);

export default HeaderBack;
