import { all, call, put, takeLatest } from "redux-saga/effects";
import { AxiosResponse } from "axios";
import { isFunction } from "lodash";

import { formValueByAssetsToDashboardDiagram } from "store/assets/effects";

import request from "../../API";
import { convertTransactionsFilterToList, convertUrlParams } from "../../utils/url";
import { EStorageKeys } from "../../utils/storageHeplers";
import { ECurrency } from "../../config/types";

import { IPayloadAction } from "../rootInterface";
import { TTransactionsData } from "../transactions/types";
import {
  TAssetsDashboardPortfolioStatsData,
  TAssetsDashboardValueHistoryDate,
  TValueByAssetData
} from "../assets/types";
import { PAGINATION_PAGE_LIMIT } from "../constants";

import {
  publicAccountAssetsFailure,
  publicAccountAssetsRequest,
  publicAccountAssetsSuccess,
  publicAccountDateRangeStatisticsFailure,
  publicAccountDateRangeStatisticsRequest,
  publicAccountDateRangeStatisticsSuccess,
  publicAccountInfoFailure,
  publicAccountInfoRequest,
  publicAccountInfoSuccess,
  publicAccountTransactionsFailure,
  publicAccountTransactionsRequest,
  publicAccountTransactionsSuccess,
  publicAccountValueHistoryFailure,
  publicAccountValueHistoryRequest,
  publicAccountValueHistorySuccess
} from "./reducers";
import {
  TPublicAccountAssetsOptions,
  TPublicAccountDateRangeStatisticsOptions,
  TPublicAccountInfo,
  TPublicAccountTransactionsOptions,
  TPublicAccountValueHistoryOptions
} from "./types";

function* publicAccountInfo(action: IPayloadAction<{ id: string }>) {
  const { id } = action.payload;
  const currency = localStorage.getItem(EStorageKeys.CURRENCY) as ECurrency|undefined;
  try {
    const response: AxiosResponse<TPublicAccountInfo> = yield call(request.get, `/public/accounts/${id}/info/`,
      { params: { currency } });

    yield put(publicAccountInfoSuccess(response.data));
  } catch (e) {
    yield put(publicAccountInfoFailure(e));
  }
}

function* publicAccountAssets(action: IPayloadAction<TPublicAccountAssetsOptions>) {
  const { id, params } = action.payload;
  const currency = localStorage.getItem(EStorageKeys.CURRENCY) as ECurrency|undefined;
  
  try {
    const response: AxiosResponse<TValueByAssetData> =
      yield call(request.get, `/public/accounts/${id}/assets/`, { params: { ...params, currency } });

    yield put(publicAccountAssetsSuccess(response.data));
    yield formValueByAssetsToDashboardDiagram(response.data);
  } catch (e) {
    yield put(publicAccountAssetsFailure(e));
  }
}

function* publicAccountDateRangeStatistics(action: IPayloadAction<TPublicAccountDateRangeStatisticsOptions>) {
  const { id, ...rest } = action.payload;
  const currency = localStorage.getItem(EStorageKeys.CURRENCY) as ECurrency|undefined;
  
  try {
    const response: AxiosResponse<TAssetsDashboardPortfolioStatsData> =
      yield call(request.get, `/public/accounts/${id}/date-range-statistics/`, { params: { ...rest, currency } });

    yield put(publicAccountDateRangeStatisticsSuccess(response.data));
  } catch (e) {
    yield put(publicAccountDateRangeStatisticsFailure(e));
  }
}

function* publicAccountValueHistory(action: IPayloadAction<TPublicAccountValueHistoryOptions>) {
  const { id, ...rest } = action.payload;
  const currency = localStorage.getItem(EStorageKeys.CURRENCY) as ECurrency|undefined;

  try {
    const response: AxiosResponse<TAssetsDashboardValueHistoryDate[]> =
      yield call(request.get, `/public/accounts/${id}/value-history/`, { params: { ...rest, currency } });

    yield put(publicAccountValueHistorySuccess(response.data));
  } catch (e) {
    yield put(publicAccountValueHistoryFailure(e));
  }
}

function* publicAccountTransactions(action: IPayloadAction<TPublicAccountTransactionsOptions>) {
  const { id, offset = 0, limit = PAGINATION_PAGE_LIMIT, infiniteScroll, callback, ...filters } = action.payload;
  const currency = localStorage.getItem(EStorageKeys.CURRENCY) as ECurrency|undefined;

  try {

    const params: URLSearchParams = convertUrlParams(convertTransactionsFilterToList(filters));
    params.append("limit", limit.toString());
    params.append("offset", offset.toString());
    if (currency) params.append("currency", currency);

    const { data } =
      (yield call(request.get, `/public/accounts/${id}/transactions/`, { params })) as AxiosResponse<TTransactionsData>;
    yield put(publicAccountTransactionsSuccess({ ...data, infiniteScroll, limit, offset }));

    if (isFunction(callback)) {
      if (infiniteScroll && data.next) {
        callback(data.next);
      } else {
        callback();
      }
    }
  } catch (e) {
    yield put(publicAccountTransactionsFailure(e));
  }
}

function* Saga(): Generator {
  yield all([
    takeLatest(publicAccountInfoRequest.type, publicAccountInfo),
    takeLatest(publicAccountAssetsRequest.type, publicAccountAssets),
    takeLatest(publicAccountDateRangeStatisticsRequest.type, publicAccountDateRangeStatistics),
    takeLatest(publicAccountValueHistoryRequest.type, publicAccountValueHistory),
    takeLatest(publicAccountTransactionsRequest.type, publicAccountTransactions),
  ]);
}

export default Saga;