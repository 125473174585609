import { FC } from "react";
import { useHistory } from "react-router";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import { EQueries } from "config/types";

import Button from "components/Buttons/Button";
import { EPlans, usePricingContext } from "components/Pricing/hooks";

import useQuery from "hooks/useQuery";

import { EModals } from "store/modals/types";
import { ETariffPlan, EServiceCode, TServicesData } from 'store/services/types';
import { servicesAllSelectByCodeSelector } from "store/services/selectors";
import { userPlanInfoSelector } from "store/user/selectors";
import { analyticEvent } from "store/analytics/effects";
import { EEventType } from "store/analytics/types";

import { TPricingPlanProps } from "../../types";

import PricingCard from "../PricingCard";
import PricingCardFeatures from "../PricingCardFeatures";
import PricingProgress from "../PricingProgress";
import PricingCardPrice from "../PricingCardPrice";

import { availableFeatures } from "./constants";

const PricingPlanPro: FC<TPricingPlanProps> = ({ active }) => {
  const { t } = useTranslation();
  const history = useHistory();
  const query = useQuery();
  const dispatch = useDispatch();
  const { plan } = usePricingContext();
  const { data: userPlan } = useSelector(userPlanInfoSelector);
  const proMonth = useSelector(servicesAllSelectByCodeSelector(EServiceCode.plansProMonth));
  const proYear = useSelector(servicesAllSelectByCodeSelector(EServiceCode.plansProYear));
  const pro = (plan === EPlans.monthly ? proMonth : proYear) as TServicesData;
  const disabledPro = userPlan && !(
    userPlan.plans_to_upgrade.includes(pro?.code)
    || userPlan.plans_to_renew.includes(pro?.code)
    || userPlan.plans_to_prepaid.includes(pro?.code)
    || userPlan.plans_to_change.includes(pro?.code)
  );

  const openPricingActivateModal = () => {
    if (active || !pro) return;

    dispatch(analyticEvent(EEventType.PRICING_PRO_CLICK));
    
    if (userPlan?.plans_to_upgrade.includes(pro.code)) {
      if (userPlan?.current_plan.paid_service_code) {
        openModal(EModals.pricingTariffUpgrade); // -
      } else {
        openModal();
      }
    } else if (userPlan?.plans_to_renew.includes(pro.code)) {
      openModal(EModals.pricingSelectTariffForNextRenewal);
    } else if (userPlan?.plans_to_prepaid.includes(pro.code)) {
      openModal(EModals.pricingTariffPrepayment);
    } else if (userPlan?.plans_to_change.includes(pro.code)) {
      openModal(EModals.pricingReplacingTariffWithReplaceCurrent);
    } else {
      openModal();
    }
  };

  const openModal = (modal: EModals|undefined = EModals.activatePricing) => {
    query.set(EQueries.modal, modal);
    query.set(EQueries.tariff, ETariffPlan.pro);
    query.set(EQueries.code, pro?.code);
    history.replace({
      search: query.toString()
    });
  };
  
  return (
    <PricingCard pricing={ETariffPlan.pro} active={active}>
      <div className="pricing__card__title">
        {t("pricing.pro")}
      </div>
      <PricingCardPrice active={active} currency={pro?.currency} price={pro?.price}/>
      {active
        ? <PricingProgress pricing={ETariffPlan.pro}/>
        : (
          <div className="pricing__container">
            <Button
              title={t("pricing.getPro")}
              className="pricing__card__btn pricing__container"
              pink={true}
              onClick={openPricingActivateModal}
              disabled={!!disabledPro}
            />
          </div>
        )
      }
      <PricingCardFeatures features={availableFeatures()} tariffPlan={ETariffPlan.pro}/>
    </PricingCard>
  );};

export default PricingPlanPro;