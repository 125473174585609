
{/* eslint-disable max-len */}
const AlertIcon = (): JSX.Element => (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
    <path
      d="M20.3425 8.33628H15.041L19.8083 2.31284C19.9069 2.1853 19.8179 2 19.6567 2H10.4278C10.3604 2 10.2954 2.0361 10.2617 2.09626L4.02652 12.8653C3.95192 12.9928 4.04337 13.1541 4.19257 13.1541H8.38948L6.23809 21.7597C6.19236 21.9474 6.41857 22.0797 6.55815 21.945L20.4749 8.66597C20.6 8.54805 20.5158 8.33628 20.3425 8.33628ZM9.03683 17.3173L10.4879 11.5177H6.70013L11.2628 3.63882H16.6678L11.6551 9.9751H16.7328L9.03683 17.3173Z"
      fill="#626368" />
  </svg>
);

export default AlertIcon;
