import { FC } from "react";

import AnimatedVisibility from "../AnimatedVisibility";

import PricingCards from "./components/PricingCards";
import PricingHeader from "./components/PricingHeader";
import PricingModals from "./components/PricingModals";
import { PricingContext, TPricingContextValue } from "./hooks";

export type TPricingProps = {
  showHeader?: boolean;
  isModal?: boolean;
} & TPricingContextValue;

const Pricing: FC<TPricingProps> = ({
  showHeader, isModal, plan, setPlan
}) => (
  <PricingContext.Provider value={{ plan, setPlan, isModal }}>
    <div className="pricing">
      <AnimatedVisibility
        show={!!showHeader}
      >
        <PricingHeader/>
      </AnimatedVisibility>
      <PricingCards/>
      <PricingModals/>
    </div>
  </PricingContext.Provider>
);

export default Pricing;