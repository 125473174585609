import React from "react";
import { NavLink } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";

import { paths } from "config/paths";

import SelectLanguage from "components/SelectLanguage";

import { analyticEvent } from 'store/analytics/effects';
import { EEventType } from 'store/analytics/types';

import { TIsSignIn } from "../../types";

const AuthFooter: React.FC<TIsSignIn> = ({ isSignIn }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  return (
    <div className="sign-footer">
      <div>
        <span className="sign-footer_text">{isSignIn ? t("auth.dontHaveAccount") : t("auth.haveAccount")}</span>
        <NavLink
          to={isSignIn ? paths.SIGN_UP : paths.SIGN_IN} exact={true}
          className="sign-footer_link"
          onClick={() => {
            if (isSignIn) {
              dispatch(analyticEvent(EEventType.AUTH_LOGIN_SHOW_REG));
              dispatch(analyticEvent(EEventType.LOGIN_FORM_REG_LINK));
            } else {
              dispatch(analyticEvent(EEventType.AUTH_REG_SHOW_LOGIN));
              dispatch(analyticEvent(EEventType.REGISTRATION_FORM_LOGIN_LINK));
            }
          }}
        >
          {isSignIn ? t("auth.registration") : t("auth.signIn")}
        </NavLink>
      </div>
      <SelectLanguage clasName="sign-footer__lang" renderInWrapper={true}/>
    </div>
  );
};

export default AuthFooter;
