import { colors } from "config/constants";

{/* eslint-disable max-len */}
const HomeIcon = (): JSX.Element => (
  <svg width="22" height="21" viewBox="0 0 22 21" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M21.5438 10.2223L12.1673 0.850597L11.5388 0.222098C11.3956 0.0798406 11.2019 0 11 0C10.7982 0 10.6045 0.0798406 10.4613 0.222098L0.456308 10.2223C0.309572 10.3684 0.193604 10.5425 0.11525 10.7342C0.036895 10.9259 -0.0022583 11.1314 0.000100583 11.3385C0.00980713 12.1927 0.720812 12.8746 1.57499 12.8746H2.60631V20.7781H19.3938V12.8746H20.4469C20.8619 12.8746 21.2526 12.712 21.5462 12.4184C21.6908 12.2743 21.8053 12.1029 21.8832 11.9142C21.9611 11.7255 22.0008 11.5232 22 11.3191C22 10.9066 21.8374 10.5159 21.5438 10.2223ZM12.359 19.031H9.64113V14.0806H12.359V19.031ZM17.6466 11.1274V19.031H13.912V13.4982C13.912 12.9619 13.4776 12.5276 12.9414 12.5276H9.05873C8.52245 12.5276 8.08808 12.9619 8.08808 13.4982V19.031H4.35349V11.1274H2.02392L11.0025 2.15613L11.563 2.71668L19.9786 11.1274H17.6466Z"
      fill={colors.gray9}
    />
  </svg>
);

export default HomeIcon;
