import { FC, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import cn from "classnames";

import { paths } from "config/paths";

import Button from "components/Buttons/Button";
import { useLayoutAppNameContext } from "components/Layout/hooks";

import { isAuthorizedSelector } from "store/auth/selectors";

type PublicHeaderRedirectButtonProps = {
  className?: string
  is404?: boolean
}

const PublicHeaderRedirectButton: FC<PublicHeaderRedirectButtonProps> = ({ className, is404 }) => {
  const { t } = useTranslation();
  const history = useHistory();
  const { routePath } = useLayoutAppNameContext();
  const isAuthorized = useSelector(isAuthorizedSelector);
  
  const handleRedirect = () => {
    if (isAuthorized) {
      history.push(paths.HOME);
    } else {
      history.push(paths.SIGN_UP);
    }
  };
  
  const title = useMemo(() => {
    if (routePath === paths.PUBLIC_AML && !is404) return "BitOk";
    return isAuthorized ? t("amlPublic.backToMyPortfolio") : t("amlPublic.createPortfolio");
  }, [isAuthorized]);
  
  return <Button
    onClick={handleRedirect}
    title={title}
    className={cn("public-header__create-portfolio", className)}
    pink={true}
  />;
};

export default PublicHeaderRedirectButton;