import { FC, useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import { useTranslation } from "react-i18next";

import { EQueries, ECurrency } from "config/types";
import { paths } from "config/paths";

import ModalWrapper from "components/ModalWrapper";

import useQuery from "hooks/useQuery";

import { closeModal, openModal } from 'store/modals/reducers';
import { modalStateSelector } from "store/modals/selectors";
import { EModals } from "store/modals/types";
import { EServiceCode, EServiceSet, ETariffPlan, TPaymentOptions } from 'store/services/types';
import {
  activatePlanPendingSelector,
  paymentCalculateDataSelector,
  paymentCalculateOptionsSelector,
  servicesAllSelectByCodeSelector,
  servicesAllSelector,
} from "store/services/selectors";
import { getUserInfoSelector, userPaymentMethodsSelector } from "store/user/selectors";
import { calculatePaymentRequest,
  paymentRequest, servicesAllRequest, setPaymentPricingLoading } from "store/services/reducers";
import { userPlanInfoRequest } from "store/user/reducers";
import { analyticEvent } from "store/analytics/effects";
import { EEventType } from "store/analytics/types";

import delay from "utils/delay";
import { currencySymbol } from "utils/currency";

import PricingActivatePlan from './components/PricingActivatePlan';
import PricingPaymentMethod, { PricingPaymentMethodValues } from './components/PricingPaymentMethod';
import { CURRENCIES } from "./constants";

export enum EPricingActivateModalSteps {
  one = 1,
  two = 2
}

const PricingActivateModal: FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const visible = useSelector(modalStateSelector(EModals.activatePricing));
  const loading = useSelector(activatePlanPendingSelector);
  const calculateData = useSelector(paymentCalculateDataSelector);
  const paymentCalculateData = useSelector(paymentCalculateDataSelector);
  const paymentCalculateOptions = useSelector(paymentCalculateOptionsSelector);
  const { data: userPaymentMethods } = useSelector(userPaymentMethodsSelector);
  const query = useQuery();
  const modal = query.get(EQueries.modal);

  const code = query.get(EQueries.code) as EServiceCode;
  const pricing = query.get(EQueries.tariff) as ETariffPlan;
  const { data: userInfo } = useSelector(getUserInfoSelector);
  const [currency, setCurrency] = useState<ECurrency>(() => userInfo?.currency || ECurrency.rub);
  const [activePricingDropdown, setActivePricingDropdown] = useState(false);

  const getCurrency = () =>
    CURRENCIES.find(curr => curr.currency === currency && !curr.disabled)?.currency || ECurrency.rub;

  const [step, setStep] = useState<EPricingActivateModalSteps>(EPricingActivateModalSteps.one);
  const service = useSelector(servicesAllSelectByCodeSelector(code));
  const { fetching: serviceAllFetching } = useSelector(servicesAllSelector);

  const closeModalHandle = () => {
    setActivePricingDropdown(false);

    setTimeout(() => {
      dispatch(closeModal(EModals.activatePricing));
      setStep(EPricingActivateModalSteps.one);
    }, 100);

    query.delete(EQueries.modal);
    query.delete(EQueries.tariff);
    query.delete(EQueries.code);
    history.replace({
      search: query.toString(),
    });
  };

  const handleBack = () => setStep(EPricingActivateModalSteps.one);

  const formTitle = () => {
    if (step === EPricingActivateModalSteps.two) {
      return t("pricing.paymentMethod");
    }

    if (
      code === EServiceCode.amlPacks10
      || code === EServiceCode.amlPacks50
      || code === EServiceCode.amlPacks100
    ) {
      return t("amlPricing.activatingAML");
    }

    switch (pricing) {
    case ETariffPlan.smart:
      return t("pricing.activating") + " SMART";
    case ETariffPlan.pro:
      return t("pricing.activating") + " PRO";
    default:
      return "";
    }
  };

  const formSubmitText = () => {
    if (paymentCalculateData?.currency === ECurrency.rub) {
      return `${t("pricing.pay")} ${Number(calculateData?.price)} ${currencySymbol(ECurrency.rub)}`;
    }
    return `${t("pricing.pay")} ${currencySymbol(ECurrency.usd)} ${Number(calculateData?.price)}`;
  };

  const onSubmit = (values: PricingPaymentMethodValues) => {
    if (values?.payment_method?.id) {
      dispatch(analyticEvent(EEventType.PAYMENT_STEP2_PAY_SAVED));
    } else {
      dispatch(analyticEvent(EEventType.PAYMENT_STEP2_PAY_NEW));
    }
    dispatch(analyticEvent(EEventType.PAYMENT_STEP2_PAY));

    const data: TPaymentOptions = {
      promo_code: paymentCalculateOptions?.promo_code,
      currency: paymentCalculateData?.currency ? String(paymentCalculateData?.currency) : String(service?.currency),
      amount: paymentCalculateData?.price ? String(paymentCalculateData?.price) : String(service?.price),
      service_code: code,
      receipt_destination: values.receipt_destination || null,
      save_method: values.save_method,
      url_pending: paths.SETTINGS_PLAN_AND_PAYMENTS,
      url_canceled: paths.SETTINGS_PLAN_AND_PAYMENTS,
      url_completed: paths.SETTINGS_PLAN_AND_PAYMENTS,
    };

    if (values.payment_method) {
      if (values.payment_method.id) {
        data.saved_method = values.payment_method.id;
      }
      else {
        data.new_method = values.payment_method.type;
      }
    }

    dispatch(paymentRequest({
      data,
      callOnSuccess: () => {
        closeModalHandle();
        delay(2000);
        dispatch(userPlanInfoRequest());
        history.push(paths.SETTINGS_PLAN_AND_PAYMENTS);
      }
    }));
    dispatch(setPaymentPricingLoading(true));
  };

  useEffect(() => {
    if (modal === EModals.activatePricing && code) {
      dispatch(openModal(EModals.activatePricing));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modal]);


  useEffect(() => {
    if (code && visible) {
      dispatch(calculatePaymentRequest({ service_code: code, currency: getCurrency() }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currency, code, visible]);

  useEffect(() => {
    if (!serviceAllFetching && service?.currency !== currency && visible) {
      dispatch(servicesAllRequest({ params: { set: EServiceSet.amlCheck, currency } }));
    }
  }, [currency]);

  useEffect(() => {
    if (!visible) return;

    if (step === EPricingActivateModalSteps.one) {
      dispatch(analyticEvent(EEventType.PAYMENT_STEP1));
      dispatch(analyticEvent(EEventType.PAYMENT_STEP1_SERVICE_CODE.replace("{service_code}", code)));
    } else {
      dispatch(analyticEvent(EEventType.PAYMENT_STEP2));
    }
  }, [step, visible]);

  useEffect(() => {
    if (userInfo && userInfo?.currency) {
      setCurrency(userInfo?.currency === ECurrency.usd ? ECurrency.usdt : userInfo?.currency);
    }
  }, [userInfo]);

  return <ModalWrapper // ---
    visible={!!visible}
    title={formTitle()}
    closeModal={!loading ? closeModalHandle : undefined}
    handleBack={(step !== EPricingActivateModalSteps.one && !loading)&& handleBack}
    className="pricing__activate__modal"
    destroyOnClose={true}
    width={636}
  >
    <div className="pricing__activate">
      {
        step === EPricingActivateModalSteps.one
          ? (

            <PricingActivatePlan
              setStep={setStep}
              currency={getCurrency()}
              setCurrency={setCurrency}
              activePricingDropdown={activePricingDropdown}
              setActivePricingDropdown={setActivePricingDropdown}
            />)
          : (
            <PricingPaymentMethod
              submitText={formSubmitText()}
              onSubmit={onSubmit}
              loading={loading}
              paymentMethods={userPaymentMethods || []}
            />)
      }
    </div>
  </ModalWrapper>;
};

export default PricingActivateModal;
