import { FC } from "react";
import MediaQuery, { useMediaQuery } from "react-responsive";
import cn from "classnames";

import { maxTableMediaWidth, minShowSidebarWidth } from "config/constants";
import { paths } from "config/paths";

import { useLayoutAppNameContext } from "components/Layout/hooks";

import HeaderCurrency from "./components/HeaderCurrency";
import HeaderSyncAccounts from "./components/HeaderSyncAccounts";
import HeaderAdditional from "./components/HeaderAdditional";
import NewHeaderDrawer from "./components/NewHeaderDrawer";
import HeaderLeft from "./components/HeaderLeft";

const NewHeader: FC = () => {
  const notTable = useMediaQuery({ minWidth: maxTableMediaWidth });
  const { routePath } = useLayoutAppNameContext();

  return (
    <div className={cn("new-header__wrapper hideDrawer")}>
      <MediaQuery maxWidth={minShowSidebarWidth - 1}>
        <NewHeaderDrawer/>
      </MediaQuery>

      <div className={cn("new-header", { container: notTable })}>
        <HeaderLeft/>
        <div className="new-header__right">
          { routePath !== paths.AML_CHEKS_SINGLE && <HeaderCurrency/> }
          <HeaderSyncAccounts/>
          <HeaderAdditional/>
        </div>
      </div>
    </div>
  );
};

export default NewHeader;
