export const publicAccountTransactionsStateDefault = {
  fetching: false,
  infiniteScroll: true,
  data: [],
  requestedFilter: null,
  lastElementFromPreviousPage: null,
  firstElementOfNextPage: null,
  finish: false,
  next: null,
  count: null,
};