import { FC, useEffect } from 'react';

const OAuthGetCode: FC = () => {

  useEffect(() => {
    // eslint-disable-next-line no-console
    console.log('OAuthGetCode say hello');
  }, []);
  return <></>;
};

export default OAuthGetCode;