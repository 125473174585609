import { createSlice } from "@reduxjs/toolkit";


// import { defaultInitialState, defaultState } from "../constants";
import { IPayloadAction } from "../rootInterface";

import {
  TAmlChecksCheckAddress,
  TAmlChecksCheckAddressOptions, TAmlChecksChecksRiskOptions, TAmlChecksRisksData,
  TAmlChecksCheckTransfer,
  TAmlChecksCheckTransferOptions,
  TAmlChecksData,
  TAmlChecksDetailsOptions,
  TAmlChecksOptions,
  TAmlChecksStoreState,
  TNetwork,
  TFindAddressOrTransactionSOptions,
  TExplorerFindAddressOrTransaction,
  TAmlChecksDetails,
} from "./types";

export const defaultState = {
  fetching: false,
  data: null,
  failure: null,
};
export const defaultInitialState = {
  ...defaultState,
  initialLoading: true
};

const initialState: TAmlChecksStoreState = {
  amlChecks: defaultInitialState,
  amlChecksCheckTransfer: defaultState,
  amlChecksCheckAddress: defaultState,
  amlChecksDetails: defaultInitialState,
  amlChecksRisks: defaultInitialState,
  amlChecksRecheck: defaultInitialState,
  amlFilterMobileGroups: defaultInitialState,
  networks: defaultInitialState,
  explorerFindAddressOrTransaction: defaultInitialState,
  amlChecksDetailsDelete:defaultInitialState,
  amlRisksOverview:defaultState
};

const amlChecksSlice = createSlice({
  name: "amlChecks",
  initialState,
  reducers: {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    explorerFindAddressOrTransactionRequest(state, _: IPayloadAction<TFindAddressOrTransactionSOptions>) {
      state.explorerFindAddressOrTransaction = {
        ...state.explorerFindAddressOrTransaction,
        fetching: true,
      };
    },
    explorerFindAddressOrTransactionSuccess(state, action: IPayloadAction<TExplorerFindAddressOrTransaction>) {
      state.explorerFindAddressOrTransaction = {
        fetching: false,
        data: action.payload,
        failure: null,
      };
    },
    explorerFindAddressOrTransactionFailure(state, action) {
      state.explorerFindAddressOrTransaction = {
        fetching: false,
        data: null,
        failure: action.payload,
      };
    },
    explorerFindAddressOrTransactionClear(state) {
      state.explorerFindAddressOrTransaction = defaultState;
    },

    networksRequest(state, _: IPayloadAction<{search?: string}>) {
      state.networks = {
        ...state.networks,
        fetching: true,
      };
    },
    networksSuccess(state, action: IPayloadAction<TNetwork[]>) {
      state.networks = {
        fetching: false,
        data: action.payload,
        failure: null,
      };
    },
    networksFailure(state, action) {
      state.networks = {
        ...state.networks,
        fetching: false,
        failure: action.payload,
      };
    },

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    amlChecksRequest(state, _: IPayloadAction<TAmlChecksOptions>) {
      state.amlChecks = {
        ...state.amlChecks,
        fetching: true,
      };
    },
    amlChecksSuccess(state, action: IPayloadAction<{
      data: TAmlChecksData, infiniteScroll?: boolean;
    }>) {
      let newData = action.payload.data;
      if (action.payload.infiniteScroll) {
        const prevResults = state?.amlChecks?.data?.results || [];

        newData = {
          ...action.payload.data,
          results: [...prevResults, ...(action.payload?.data?.results || [])]
        };
      }
      state.amlChecks = {
        ...state.amlChecks,
        fetching: false,
        data: newData,
        failure: null,
        initialLoading: false,
      };
    },
    amlChecksFailure(state, action) {
      state.amlChecks = {
        ...state.amlChecks,
        fetching: false,
        failure: action.payload,
        initialLoading: false,
      };
    },
    amlChecksDetailDeleteRequest(state, _: IPayloadAction<{id: string, callOnSuccess: (value: unknown) => void }>) {
      state.amlChecksDetailsDelete = {
        fetching: true,
        data: null,
        failure: null,
      };
    },
    amlChecksDetailDeleteSuccess(state) {
      state.amlChecksDetailsDelete = {
        fetching: false,
        data: null,
        failure: null,
      };
    },
    amlChecksDetailDeleteFailure(state, action) {
      state.amlChecksDetailsDelete = {
        data:  null,
        fetching: false,
        failure: action.payload,
      };
    },

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    amlChecksCheckTransferRequest(state, _: IPayloadAction<TAmlChecksCheckTransferOptions>) {
      state.amlChecksCheckTransfer = {
        ...state.amlChecksCheckTransfer,
        fetching: true,
      };
    },
    amlChecksCheckTransferSuccess(state, action: IPayloadAction<TAmlChecksCheckTransfer>) {
      state.amlChecksCheckTransfer = {
        fetching: false,
        data: action.payload,
        failure: null,
      };
    },
    amlChecksCheckTransferFailure(state, action) {
      state.amlChecksCheckTransfer = {
        ...state.amlChecksCheckTransfer,
        fetching: false,
        failure: action.payload,
      };
    },
    amlChecksCheckTransferClear(state) {
      state.amlChecksCheckTransfer = defaultState;
    },
    amlChecksCheckAddressRequest(state, _: IPayloadAction<TAmlChecksCheckAddressOptions>) {
      state.amlChecksCheckAddress = {
        ...state.amlChecksCheckAddress,
        fetching: true,
      };
    },
    amlChecksCheckAddressSuccess(state, action: IPayloadAction<TAmlChecksCheckAddress>) {
      state.amlChecksCheckAddress = {
        fetching: false,
        data: action.payload,
        failure: null,
      };
    },
    amlChecksCheckAddressFailure(state, action) {
      state.amlChecksCheckAddress = {
        ...state.amlChecksCheckAddress,
        fetching: false,
        failure: action.payload,
      };
    },
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    amlChecksDetailsRequest(state, _: IPayloadAction<TAmlChecksDetailsOptions>) {
      state.amlChecksDetails = {
        ...state.amlChecksDetails,
        fetching: true,
      };
    },
    amlChecksDetailsSuccess(state, action: IPayloadAction<TAmlChecksDetails>) {
      
      state.amlChecksDetails = {
        fetching: false,
        data: action.payload,
        failure: null,
        initialLoading: false,
      };
    },
    amlChecksDetailsFailure(state, action) {
      state.amlChecksDetails = {
        ...state.amlChecksDetails,
        fetching: false,
        failure: action.payload,
        initialLoading: false,
      };
    },
    amlChecksDetailsClear(state) {
      state.amlChecksDetails = defaultInitialState;
    },
    amlChecksDetailsEdit(state, action: IPayloadAction<Partial<TAmlChecksDetails>>) {
      const { data } = state.amlChecksDetails;
      if (!data) {
        return;
      }
      const newData: TAmlChecksDetails = { ...data, ...action.payload };
      state.amlChecksDetails = {
        ...state.amlChecksDetails,
        data: newData || {}
      };
    },
    amlChecksRisksRequest(state, _: IPayloadAction<TAmlChecksChecksRiskOptions>) {
      state.amlChecksRisks = {
        ...state.amlChecksRisks,
        fetching: true,
      };
    },
    amlChecksRisksSuccess(state, action: IPayloadAction<TAmlChecksRisksData>) {
      state.amlChecksRisks = {
        fetching: false,
        data: action.payload,
        failure: null,
        initialLoading: false,
      };
    },
    amlChecksRisksFailure(state, action) {
      state.amlChecksRisks = {
        ...state.amlChecksRisks,
        fetching: false,
        failure: action.payload,
        initialLoading: false,
      };
    },
    amlChecksRisksClear(state) {
      state.amlChecksRisks = defaultInitialState;
    },

    amlChecksRecheckRequest(state, _: IPayloadAction<{ id: string}>) {
      state.amlChecksRecheck = {
        ...state.amlChecksRecheck,
        fetching: true
      };
    },
    amlChecksRecheckSuccess(state, action) {
      state.amlChecksRecheck = {
        fetching: false,
        data: action.payload,
        failure: null,
        initialLoading: false,
      };
    },
    amlChecksRecheckFailure(state, action) {
      state.amlChecksRecheck = {
        ...state.amlChecksRecheck,
        fetching: false,
        failure: action.payload,
        initialLoading: false,
      };
    },

    getAmlGroupsRequest(state, _) {
      state.amlFilterMobileGroups = {
        ...state.amlFilterMobileGroups,
        fetching: true,
        failure: null,
        data: [],
      };
    },
    getAmlGroupsSuccess(state, action) {
      state.amlFilterMobileGroups = {
        ...state.amlFilterMobileGroups,
        data: action.payload,
        fetching: false,
        failure: null
      };
    },
    getAmlGroupsFailure(state, action) {
      state.amlFilterMobileGroups = {
        ...state.amlFilterMobileGroups,
        data: [],
        fetching: false,
        failure: action.payload,
      };
    },
    getRiskOverviewRequest(state,_) {
      state.amlRisksOverview = {
        ...state.amlRisksOverview,
        fetching:true,
        failure:null,
      };
    },
    getRiskOverviewSuccess(state,action) {
      state.amlRisksOverview = {
        ...state.amlRisksOverview,
        fetching: false,
        failure: null,
        data: action.payload,
      };
    },
    getRiskOverviewFailure(state,action) {
      state.amlRisksOverview = {
        ...state.amlRisksOverview,
        fetching: false,
        failure:action.payload,
      };
    }
  },
});

export const {
  amlChecksRequest,
  amlChecksSuccess,
  amlChecksFailure,

  amlChecksCheckTransferRequest,
  amlChecksCheckTransferSuccess,
  amlChecksCheckTransferFailure,
  amlChecksCheckTransferClear,

  amlChecksCheckAddressRequest,
  amlChecksCheckAddressSuccess,
  amlChecksCheckAddressFailure,

  amlChecksDetailsRequest,
  amlChecksDetailsSuccess,
  amlChecksDetailsFailure,
  amlChecksDetailsClear,

  amlChecksRisksRequest,
  amlChecksRisksSuccess,
  amlChecksRisksFailure,
  amlChecksRisksClear,

  amlChecksRecheckRequest,
  amlChecksRecheckSuccess,
  amlChecksRecheckFailure,

  getAmlGroupsRequest,
  getAmlGroupsSuccess,
  getAmlGroupsFailure,

  networksRequest,
  networksFailure,
  networksSuccess,

  explorerFindAddressOrTransactionRequest,
  explorerFindAddressOrTransactionFailure,
  explorerFindAddressOrTransactionSuccess,
  explorerFindAddressOrTransactionClear,

  amlChecksDetailDeleteRequest,
  amlChecksDetailDeleteSuccess,
  amlChecksDetailDeleteFailure,

  getRiskOverviewRequest,
  getRiskOverviewSuccess,
  getRiskOverviewFailure,

} = amlChecksSlice.actions;

export default amlChecksSlice.reducer;
