import { createSelector } from "@reduxjs/toolkit";

import { getGroupedTransactions } from "../../utils/transactionUtils";

import { IApplicationState } from "../rootInterface";
import { TTransactionResult } from "../transactions/types";

const selectState = (state: IApplicationState) => state.public;

export const publicAccountInfoStateSelector = createSelector(
  selectState, pub => pub.publicAccountInfoState);
export const publicAccountAssetsStateSelector = createSelector(
  selectState, pub => pub.publicAccountAssetsState);
export const publicAccountDateRangeStatisticsStateSelector = createSelector(
  selectState, pub => pub.publicAccountDateRangeStatisticsState);
export const publicAccountValueHistoryStateSelector = createSelector(
  selectState, pub => pub.publicAccountValueHistoryState);
export const publicAccountTransactionsStateSelector = createSelector(
  selectState, pub => pub.publicAccountTransactionsState);
export const publicGroupedTransactionsSelector = createSelector(
  selectState, pub => getGroupedTransactions<TTransactionResult>(pub.publicAccountTransactionsState.data));
export const publicAccountTransactionsCountSelector = createSelector(
  selectState,
  state => state.publicAccountTransactionsState?.count,
);