import { FC } from 'react';
import { useField } from "formik";

import { ECurrency } from "config/types";

import DropdownSelector from 'components/DropdownSelector';
import CryptoIcon from "components/CryptoIcon";

const options = [
  {
    label: ECurrency.usd,
    value: ECurrency.usd,
    icon: () => <CryptoIcon asset={ECurrency.usd}/>
  },
  {
    label: ECurrency.eur,
    value: ECurrency.eur,
    icon: () => <CryptoIcon asset={ECurrency.eur}/>
  },
  {
    label: ECurrency.rub,
    value: ECurrency.rub,
    icon: () => <CryptoIcon asset={ECurrency.rub}/>
  },
  {
    label: ECurrency.gbp,
    value: ECurrency.gbp,
    icon: () => <CryptoIcon asset={ECurrency.gbp}/>
  },
  {
    label: ECurrency.try,
    value: ECurrency.try,
    icon: () => <CryptoIcon asset={ECurrency.try}/>
  },
  {
    label: ECurrency.jpy,
    value: ECurrency.jpy,
    icon: () => <CryptoIcon asset={ECurrency.jpy}/>
  },
  {
    label: ECurrency.aud,
    value: ECurrency.aud,
    icon: () => <CryptoIcon asset={ECurrency.aud}/>
  },
  {
    label: ECurrency.uah,
    value: ECurrency.uah,
    icon: () => <CryptoIcon asset={ECurrency.uah}/>
  },
  {
    label: ECurrency.thb,
    value: ECurrency.thb,
    icon: () => <CryptoIcon asset={ECurrency.thb}/>
  },
];

const MainSettingsCurrency: FC = () => {
  const [, meta, helpers] = useField("currency");
  const { setValue } = helpers;
  const { value } = meta;


  return (
    <div className="settings-main__currency  settings__item__row">
      <DropdownSelector
        value={value}
        options={options} className="settings-main__currency__select"
        onSelect={setValue}
      />
    </div>
  );
};

export default MainSettingsCurrency;
