import { paths } from "config/paths";
import { NAVIGATIONS } from "config/constants";

export const MOBILE_MENU = [
  NAVIGATIONS[paths.HOME],
  NAVIGATIONS[paths.ASSETS],
  NAVIGATIONS[paths.TRANSACTIONS],
  NAVIGATIONS[paths.AML_CHEKS],
];

export const MOBILE_MENU_ADDITIONAL = [
  NAVIGATIONS[paths.DOCUMENTS],
  NAVIGATIONS[paths.TRADING_INSIGHT],
];
