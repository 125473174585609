import { colors } from "config/constants";

{/* eslint-disable max-len */}
const ModalIcon = (): JSX.Element => (
  <svg width="40" height="40" fill={colors.gray7} className="icon">
    <path d="M21.5058 2.84151L12.404 -0.260278C12.2942 -0.297778 12.1469 -0.316528 11.9996 -0.316528C11.8523 -0.316528 11.7049 -0.297778 11.5951 -0.260278L2.49333 2.84151C2.27101 2.91651 2.08887 3.17365 2.08887 3.40936V16.3308C2.08887 16.5665 2.24155 16.8772 2.42637 17.0245L11.6594 24.2192C11.7532 24.2915 11.8737 24.329 11.9969 24.329C12.1201 24.329 12.2433 24.2915 12.3344 24.2192L21.5674 17.0245C21.7523 16.8799 21.9049 16.5692 21.9049 16.3308V3.40936C21.9103 3.17365 21.7282 2.91919 21.5058 2.84151ZM19.9817 15.8165L11.9996 22.0361L4.01744 15.8165V4.36294L11.9996 1.64151L19.9817 4.36294V15.8165ZM9.11208 10.4326C9.03172 10.3228 8.90315 10.2558 8.76387 10.2558H7.2853C7.11119 10.2558 7.0094 10.454 7.11119 10.596L10.4969 15.2567C10.5369 15.3115 10.5893 15.3561 10.6498 15.3868C10.7104 15.4175 10.7773 15.4335 10.8451 15.4335C10.913 15.4335 10.9799 15.4175 11.0404 15.3868C11.1009 15.3561 11.1533 15.3115 11.1933 15.2567L16.888 7.41651C16.9898 7.27454 16.888 7.07633 16.7139 7.07633H15.2353C15.0987 7.07633 14.9674 7.14329 14.8871 7.25311L10.8451 12.8192L9.11208 10.4326Z" />
  </svg>
);

export default ModalIcon;
