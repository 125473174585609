import { FC } from "react";
import { useDispatch, useSelector } from "react-redux";

import Sync from "components/Sync";

import { analyticEvent } from "store/analytics/effects";
import { EEventType } from "store/analytics/types";
import { getAssetsDashboardSyncAccountRequest } from "store/assets/reducers";
import {
  assetsDashboardAssetsSelector, assetsDashboardSyncAccountSelector
} from "store/assets/selectors";
import { openModal } from "store/modals/reducers";
import { EModals } from "store/modals/types";

const MobileHeaderSyncAccounts: FC = () => {
  const dispatch = useDispatch();
  const { data } = useSelector(assetsDashboardAssetsSelector);
  const { syncLoading } = useSelector(assetsDashboardSyncAccountSelector);
  const accountsExists = data?.accounts !== 0;

  const onClickSync = () => {
    if (!accountsExists) {
      dispatch(analyticEvent(EEventType.DASHBOARD_ACCOUNTS_ADD_FIRST));
      addAccount();
      return;
    }
    dispatch(analyticEvent(EEventType.DASHBOARD_ACCOUNTS_SYNC));
    dispatch(getAssetsDashboardSyncAccountRequest());
  };

  const addAccount = () => {
    dispatch(openModal(EModals.assetModal));
  };

  return  <Sync onClick={onClickSync} loading={syncLoading}/>;
};

export default MobileHeaderSyncAccounts;
