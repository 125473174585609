import { FC, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { paths } from "config/paths";

import { openModal } from "store/modals/reducers";
import { EModals } from "store/modals/types";
import { assetAccountMakePublicRequest, getSingleAssetRequest } from "store/assets/reducers";
import { getSingleAssetDataSelector } from "store/assets/selectors";

import BNDropdown from "../../../../../BNDropdown";

import { TAssetSingleMenuItemProps } from "../../types";

const AssetSingleMenuPublic: FC<TAssetSingleMenuItemProps & {id: string}> = ({ setActive, id }) => {
  const [isPublic, setIsPublic] = useState(false);
  const dispatch = useDispatch();
  const account = useSelector(getSingleAssetDataSelector(id));
  const { t } = useTranslation();
  const history = useHistory();

  const handleClickSwitch = () => {
    if (isPublic) {
      dispatch(openModal(EModals.publicWalletDisable));
    } else {
      try {
        dispatch(assetAccountMakePublicRequest({
          id,
          callOnSuccess: () => {
            dispatch(getSingleAssetRequest({
              id,
              onError: () => history.push(paths.ASSETS)
            }));
          }
        }));
      } finally {
        setActive(false);
      }
    }
  };

  useEffect(() => {
    if (account) {
      setIsPublic(Boolean(account?.public_id));
    }
  }, [account]);

  return(
    <BNDropdown.Item onClick={handleClickSwitch}>
      <span>{isPublic ? t('assetSingle.MakeToPrivate') : t('assetSingle.MakeToPublic')}</span>
    </BNDropdown.Item>
  );
};
export default AssetSingleMenuPublic;
