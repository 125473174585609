// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const setFocus = (ref: any, tagName = 'input') => {
  const inputs = ref.current?.getElementsByTagName(tagName);
  if (inputs?.length && inputs[0]) {
    setTimeout(() => {
      inputs[0].focus();
    }, 300);
  }
};

