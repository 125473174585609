import { colors } from "config/constants";

{/* eslint-disable max-len */}
const BitnalogIcon = (): JSX.Element => (
  <svg width="91" height="24" viewBox="0 0 91 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M47.948 19V4.86255H44.7305V14.6125L39.056 4.86255H35V19H38.2175V9.25005L43.892 19H47.948ZM56.78 12.448C52.7045 12.76 49.799 13.5985 49.799 16.1725C49.799 18.0835 51.437 19.2535 53.426 19.2535C54.9275 19.2535 56.1755 18.844 56.9555 17.7325L56.9945 19H60.1535C59.9975 18.337 59.9195 17.1085 59.9195 15.958V14.866L59.9585 12.5845C60.017 9.40605 58.301 8.17754 55.2785 8.17754C52.1585 8.17754 50.2865 9.93255 50.1305 11.902H53.1335C53.27 10.888 53.972 10.3615 55.0055 10.3615C56.1755 10.3615 56.78 10.7905 56.78 12.0385V12.448ZM56.858 14.593C56.858 16.5235 55.883 17.0695 54.596 17.0695C53.7575 17.0695 53.114 16.621 53.114 15.9385C53.114 14.8075 54.6155 14.554 56.858 14.3395V14.593ZM65.2172 4.86255H61.9997V19H65.2172V4.86255ZM72.4557 10.693C73.723 10.693 74.737 11.473 74.737 13.7155C74.737 15.919 73.762 16.7575 72.4557 16.7575C71.1492 16.7575 70.1742 15.919 70.1742 13.7155C70.1742 11.473 71.1882 10.693 72.4557 10.693ZM72.4557 8.19704C69.5502 8.19704 66.8982 9.91305 66.8982 13.7155C66.8982 17.518 69.5502 19.2535 72.4557 19.2535C75.361 19.2535 78.013 17.518 78.013 13.7155C78.013 9.91305 75.361 8.19704 72.4557 8.19704ZM87.019 9.93255C86.336 8.78205 84.932 8.27505 83.782 8.27505C81.071 8.27505 79.16 10.108 79.16 13.4425C79.16 16.777 81.071 18.6295 83.782 18.6295C84.932 18.6295 86.336 18.1225 87.019 16.972V18.22C87.019 19.6045 86.434 20.404 84.796 20.404C83.626 20.404 82.865 19.975 82.612 19.0585H79.589C79.901 20.989 81.734 22.666 84.835 22.666C87.74 22.666 90.217 21.496 90.217 17.596V8.50905H87.019V9.93255ZM84.835 10.771C86.102 10.771 87.155 11.3755 87.155 13.4425C87.155 15.4705 86.102 16.1335 84.835 16.1335C83.548 16.1335 82.495 15.3145 82.495 13.4425C82.495 11.59 83.548 10.771 84.835 10.771Z" fill={colors.brown9}/>
    <path d="M0 19H6.4155C9.8475 19 12.129 17.83 12.129 14.6515C12.129 12.9355 11.2125 11.941 9.8865 11.473C10.9785 10.7905 11.5635 9.83505 11.5635 8.52855C11.5635 5.56455 9.2625 4.86255 6.162 4.86255H0V19ZM3.3345 12.994H6.4545C7.878 12.994 8.5995 13.5985 8.5995 14.593C8.5995 15.5875 7.878 16.192 6.4545 16.192H3.3345V12.994ZM3.3345 7.67055H6.279C7.449 7.67055 8.0925 8.19705 8.0925 9.07455C8.0925 9.95205 7.449 10.5175 6.279 10.5175H3.3345V7.67055ZM13.7839 8.50905V19H17.0209V8.50905H13.7839ZM13.7839 4.86255V7.43655H17.0014V4.86255H13.7839ZM20.6124 8.50905H18.9549V10.7905H20.6124V16.0555C20.6124 18.493 21.4899 19 24.0834 19H26.1309V16.6795H25.2144C24.1419 16.6795 23.8299 16.4845 23.8299 15.49V10.7905H26.1309V8.50905H23.8299V5.64255H20.6124V8.50905Z" fill={colors.brown9}/>
    <path d="M3.71289 4H1.71289V7H3.71289V4Z" fill={colors.brown9}/>
    <path d="M3.71289 17H1.71289V20H3.71289V17Z" fill={colors.brown9}/>
    <path d="M6.71289 4H4.71289V7H6.71289V4Z" fill={colors.brown9}/>
    <path d="M6.71289 17H4.71289V20H6.71289V17Z" fill={colors.brown9}/>
    <path d="M30.7129 0H29.7129V24H30.7129V0Z" fill={colors.brown9}/>
  </svg>
);

export default BitnalogIcon;
