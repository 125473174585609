import { FC, CSSProperties } from 'react';
import { useMediaQuery } from "react-responsive";
import { Transition } from 'react-transition-group';
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";

import { maxMobileMediaWidth } from "config/constants";

import Button from 'components/Buttons/Button';
import CloseIcon from "components/Icons/CloseIcon";

import { analyticEvent } from "store/analytics/effects";
import { EEventType } from "store/analytics/types";

import UpgradePlanNext from "../UpgradePlanNext";

import UpgradePlanNoteImages from "./components/UpgradePlanNoteImages";
import { useOnwheel } from "./hooks";

export type TUpgradePlanNoteProps = {
  text: string
  setShowNote: (val: boolean) => void
  closeModal: () => void
  showNote: boolean
}

const UpgradePlanNote: FC<TUpgradePlanNoteProps> = ({ text, setShowNote, closeModal, showNote }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const isMaxMobile = useMediaQuery({ query: `(max-width: ${maxMobileMediaWidth}px)` });

  const styles: Record<string, CSSProperties> = {
    entering: {
      // transform: "translate(0, 0)",
      opacity: 1,
    },
    entered: {
      // transform: "translate(0, 0)",
      opacity: 1,
    },
    exiting: {
      transform: "translate(0, -1000px)",
      opacity: 0
    },
    exited: {
      transform: "translate(0, -1000px)",
      opacity: 0
    },
  };

  const handleClickNext = () => {
    setShowNote(false);
    dispatch(analyticEvent(EEventType.UPGRADE_PLAN_NEXT));
  };

  useOnwheel(showNote, handleClickNext);

  return (
    <Transition
      timeout={0}
      in={showNote}
    >
      {state => (
        <div className="upgrade-plan-note" style={{
          transition: "opacity 0.5s, transform 1s",
          ...styles[state]
        }}>
          <div className="upgrade-plan-note__content">
            <div className="upgrade-plan-note__title">
              {t("pricing.upgradePlan")}
            </div>
            <div className="upgrade-plan-note__text">
              {text}
            </div>
            {isMaxMobile && <Button
              className="upgrade-plan-note__btn"
              title={t("pricing.selectPlan")}
              onClick={() => {
                setShowNote(false);
                dispatch(analyticEvent(EEventType.UPGRADE_PLAN_NEXT));
              }}
            />}
            <UpgradePlanNoteImages/>
            <div className="upgrade-plan-note__close" onClick={closeModal}>
              <CloseIcon/>
            </div>
          </div>
          {!isMaxMobile && <UpgradePlanNext onClick={handleClickNext}/>}
        </div>
      )}
    </Transition>
  );
};

export default UpgradePlanNote;
