import { createSlice } from "@reduxjs/toolkit";

import { transactionInfoMapper as mapper } from "../../utils/transactionUtils";

import { defaultState, PAGINATION_PAGE_LIMIT } from "../constants";
import { IPayloadAction } from "../rootInterface";
import { TGetTransactionsListSuccess } from "../transactions/types";

import { TPublicStoreState } from "./types";
import { publicAccountTransactionsStateDefault } from "./constants";

const initialState: TPublicStoreState = {
  publicAccountInfoState: defaultState,
  publicAccountAssetsState: { ...defaultState, loadingModal: false, initialLoading: true },
  publicAccountDateRangeStatisticsState: defaultState,
  publicAccountValueHistoryState: { ...defaultState, initialLoading: true },
  publicAccountTransactionsState: publicAccountTransactionsStateDefault,
};

const publicSlice = createSlice({
  name: "public",
  initialState,
  reducers: {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    publicAccountInfoRequest(state, _) {
      state.publicAccountInfoState = {
        ...state.publicAccountInfoState,
        fetching: true
      };
    },
    publicAccountInfoSuccess(state, action) {
      state.publicAccountInfoState = {
        ...state.publicAccountInfoState,
        fetching: false,
        data: action.payload,
        failure: null
      };
    },
    publicAccountInfoFailure(state, action) {
      state.publicAccountInfoState = {
        ...state.publicAccountInfoState,
        fetching: false,
        failure: action.payload
      };
    },

    publicAccountAssetsRequest(state, action) {
      state.publicAccountAssetsState = {
        ...state.publicAccountAssetsState,
        fetching: true,
        loadingModal: action.payload.loadingModal,
      };
    },
    publicAccountAssetsSuccess(state, action) {
      state.publicAccountAssetsState = {
        ...state.publicAccountAssetsState,
        fetching: false,
        data: action.payload,
        failure: null,
        initialLoading: false,
        loadingModal: false,
      };
    },
    publicAccountAssetsFailure(state, action) {
      state.publicAccountAssetsState = {
        ...state.publicAccountAssetsState,
        fetching: false,
        failure: action.payload,
        initialLoading: false,
        loadingModal: false,
      };
    },

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    publicAccountDateRangeStatisticsRequest(state, _) {
      state.publicAccountDateRangeStatisticsState = {
        ...state.publicAccountDateRangeStatisticsState,
        fetching: true
      };
    },
    publicAccountDateRangeStatisticsSuccess(state, action) {
      state.publicAccountDateRangeStatisticsState = {
        ...state.publicAccountDateRangeStatisticsState,
        fetching: false,
        data: action.payload,
        failure: null
      };
    },
    publicAccountDateRangeStatisticsFailure(state, action) {
      state.publicAccountDateRangeStatisticsState = {
        ...state.publicAccountDateRangeStatisticsState,
        fetching: false,
        failure: action.payload
      };
    },

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    publicAccountValueHistoryRequest(state, _) {
      state.publicAccountValueHistoryState = {
        ...state.publicAccountValueHistoryState,
        fetching: true
      };
    },
    publicAccountValueHistorySuccess(state, action) {
      state.publicAccountValueHistoryState = {
        ...state.publicAccountValueHistoryState,
        fetching: false,
        data: action.payload,
        failure: null,
        initialLoading: false,
      };
    },
    publicAccountValueHistoryFailure(state, action) {
      state.publicAccountValueHistoryState = {
        ...state.publicAccountValueHistoryState,
        fetching: false,
        failure: action.payload,
        initialLoading: false,
      };
    },

    publicAccountTransactionClearState(state) {
      state.publicAccountTransactionsState = publicAccountTransactionsStateDefault;
    },
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    publicAccountTransactionsRequest(state: TPublicStoreState, action) {
      state.publicAccountTransactionsState.fetching = true;
      state.publicAccountTransactionsState.requestedFilter = action.payload;
    },
    publicAccountTransactionsSuccess(state, action: IPayloadAction<TGetTransactionsListSuccess>) {
      const { results, next, count, infiniteScroll, limit, offset } = action.payload;
      let data = results;
      state.publicAccountTransactionsState.fetching = false;

      switch (limit) {
      case PAGINATION_PAGE_LIMIT+2:
        data = results.slice(1, PAGINATION_PAGE_LIMIT+1);
        state.publicAccountTransactionsState.lastElementFromPreviousPage = results[0];
        state.publicAccountTransactionsState.next =
          count > offset + PAGINATION_PAGE_LIMIT ? offset + PAGINATION_PAGE_LIMIT : null;

        state.publicAccountTransactionsState.firstElementOfNextPage = results.length === PAGINATION_PAGE_LIMIT+2
          ? results[results.length-1]
          : null;
        break;
      case PAGINATION_PAGE_LIMIT+1:
        data = results.slice(0, PAGINATION_PAGE_LIMIT);
        state.publicAccountTransactionsState.lastElementFromPreviousPage = null;
        state.publicAccountTransactionsState.next =
          count > offset + PAGINATION_PAGE_LIMIT ? offset + PAGINATION_PAGE_LIMIT : null;

        state.publicAccountTransactionsState.firstElementOfNextPage = results.length === PAGINATION_PAGE_LIMIT+1
          ? results[results.length-1]
          : null;
        break;
      default:
        state.publicAccountTransactionsState.lastElementFromPreviousPage = null;
        state.publicAccountTransactionsState.firstElementOfNextPage = null;
        state.publicAccountTransactionsState.next = next;
        break;
      }

      const formedData = data.map(transaction => ({
        ...transaction,
        formedInfo: mapper.map(transaction)
      }));

      state.publicAccountTransactionsState.data = infiniteScroll
        ? [...state.publicAccountTransactionsState.data, ...formedData]
        : formedData;
      state.publicAccountTransactionsState.count = count;
      state.publicAccountTransactionsState.finish = !next;
      state.publicAccountTransactionsState.infiniteScroll = infiniteScroll;
    },
    publicAccountTransactionsFailure(state, action) {
      state.publicAccountTransactionsState.fetching = false;
      state.publicAccountTransactionsState.error = action.payload;
    },
  }
});

export const {
  publicAccountInfoRequest,
  publicAccountInfoSuccess,
  publicAccountInfoFailure,

  publicAccountAssetsRequest,
  publicAccountAssetsSuccess,
  publicAccountAssetsFailure,

  publicAccountDateRangeStatisticsRequest,
  publicAccountDateRangeStatisticsSuccess,
  publicAccountDateRangeStatisticsFailure,

  publicAccountValueHistoryRequest,
  publicAccountValueHistorySuccess,
  publicAccountValueHistoryFailure,

  publicAccountTransactionClearState,
  publicAccountTransactionsRequest,
  publicAccountTransactionsSuccess,
  publicAccountTransactionsFailure,
} = publicSlice.actions;

export default publicSlice.reducer;