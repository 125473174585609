import React, { useMemo }  from "react";
import { useSelector } from "react-redux";
import { Layout as AntLayout } from 'antd';
import cn from "classnames";
import { matchPath, useLocation } from "react-router-dom";

import { allRoutes } from "config/routes";
import { paths } from "config/paths";

import { isAuthorizedSelector } from "store/auth/selectors";
import { dashboardOnboardingSelector } from "store/common/selectors";

import { ILayout } from "./types";
import LayoutProviders from "./components/LayoutProviders";
import PrivateContainer from "./components/PrivateContainer";
import PublicContainer from "./components/PublicContainer";

const Layout: React.FC<ILayout> = ({ children }) => {
  const location = useLocation();
  const { isRun } = useSelector(dashboardOnboardingSelector);
  const isAuthorized = useSelector(isAuthorizedSelector);

  const routePath = useMemo(() => allRoutes.find(
    route => matchPath(location.pathname, route)?.isExact
  )?.path as paths, [location.pathname]);

  const ignorePrivateContent = useMemo(() =>
    [paths.PUBLIC_AML].includes(routePath),
  [routePath]);

  const content = useMemo(() => {
    if (location.pathname?.includes("/public")) return <PublicContainer>{ children }</PublicContainer>;
    if (isAuthorized && !ignorePrivateContent) return <PrivateContainer>{ children }</PrivateContainer>;
    return children;
  }, [isAuthorized, ignorePrivateContent, routePath, location.pathname]);

  return (
    <LayoutProviders routePath={routePath}>
      <AntLayout className={cn("layout-page", { onboarding: isRun })}>
        {content}
      </AntLayout>
    </LayoutProviders>
  );
};

export default Layout;
