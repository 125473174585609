import { FC, useMemo } from "react";
import { Link } from "react-router-dom";

import { paths } from "config/paths";

import { useLayoutAppNameContext } from "components/Layout/hooks";

import PublicMobileAccountTransactionTitle from "./components/PublicMobileAccountTransactionTitle";

const PublicMobileTitle: FC = () => {
  const { routePath } = useLayoutAppNameContext();
  const { logo } = useLayoutAppNameContext();

  const pageTitle = useMemo(() => {
    switch (routePath) {
    case paths.PUBLIC_ACCOUNT_TRANSACTIONS:
      return <PublicMobileAccountTransactionTitle/>;
    }
    return <Link className="public-header-mobile__logo" to={paths.HOME}>{logo}</Link>;
  }, [routePath]);

  return (
    <div className="header-mobile__title">
      {pageTitle}
    </div>
  );
};

export default PublicMobileTitle;