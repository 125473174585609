import { FC } from "react";

const AuthCarouselSlider1: FC = () => (
  <div className="auth-carousel__slider-2">
    <img
      src={process.env.PUBLIC_URL + "/img/auth-slider-2/okman.png"}
      className="auth-carousel__slider-2__okman"
    />
    <img
      src={process.env.PUBLIC_URL + "/img/auth-slider-2/segment.png"}
      className="auth-carousel__slider-2__segment"
    />
    <img
      src={process.env.PUBLIC_URL + "/img/auth-slider-2/documents.png"}
      className="auth-carousel__slider-2__document"
    />
  </div>
);

export default AuthCarouselSlider1;
