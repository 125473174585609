import { FC, useEffect } from 'react';
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import Pricing from "components/Pricing";
import { PricingContext, usePricingState } from "components/Pricing/hooks";
import PreparedMobileDrawer from 'components/PreparedMobileDrawer';
import PricingHeader from "components/Pricing/components/PricingHeader";


import { modalStateSelector } from "store/modals/selectors";
import { EModals } from "store/modals/types";
import { analyticEvent } from "store/analytics/effects";
import { EEventType } from "store/analytics/types";

export type TUpgradePlanPricingMobileProps = {
  closeModal: () => void
}
const UpgradePlanPricingMobile: FC<TUpgradePlanPricingMobileProps> = ({ closeModal }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [plan, setPlan] = usePricingState();
  const visible = useSelector(modalStateSelector(EModals.upgradePlan));

  useEffect(() => {
    dispatch(analyticEvent(EEventType.PRICING_POPUP));
  }, [dispatch]);

  return (
    <div className="upgrade-plan-pricing__mobile">
      <PricingContext.Provider value={{ plan, setPlan, isModal: true }}>
        <PreparedMobileDrawer
          visible={!!visible}
          header={<PricingHeader/>}
          close={closeModal}
          title={t("naming.pricing")}
          headerClass="upgrade-plan-drawer__header"
        >
          <div className="upgrade-plan-drawer__content">
            <Pricing showHeader={false} plan={plan} setPlan={setPlan}/>
          </div>
        </PreparedMobileDrawer>
      </PricingContext.Provider>
    </div>
  );
};

export default UpgradePlanPricingMobile;