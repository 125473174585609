import { createContext, useContext, useEffect, useState } from "react";
import { useDispatch } from "react-redux";

import { servicesAllRequest } from "store/services/reducers";

export enum EPlans {
  monthly = "monthly",
  annually = "annually",
}

export type TPricingContextValue = { plan?: EPlans, setPlan?: (val: EPlans) => void, isModal?: boolean };
export const PricingContext = createContext<TPricingContextValue>({});
export const usePricingContext = (): TPricingContextValue => useContext(PricingContext);

export type TUsePricingState = [ EPlans, (val: EPlans) => void]
export const usePricingState = (): TUsePricingState => {
  const dispatch = useDispatch();
  const [plan, setPlan] = useState<EPlans>(EPlans.annually);

  useEffect(() => {
    dispatch(servicesAllRequest({}));
  }, [dispatch]);

  return [plan, setPlan];
};
