import { FC, useEffect } from 'react';
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useMediaQuery } from "react-responsive";
import { useHistory } from "react-router";
import isFunction from "lodash/isFunction";

import { mobileMediaWidth } from "config/constants";
import { EQueries } from "config/types";

import NoteIcon from "components/Icons/AssetNoteIcon";
import Button from "components/Buttons/Button";
import ConfirmModal from "components/ConfirmModal";

import useQuery from "hooks/useQuery";

import { EModals } from "store/modals/types";
import { modalStateSelector } from "store/modals/selectors";
import { closeModal, openModal } from "store/modals/reducers";

export type TPricingConfirmModalProps = {
  modal: EModals;
  text: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  submit?: (value?: any) => void;
}

const PricingConfirmModal: FC<TPricingConfirmModalProps> = ({ modal , text, submit }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const visible = useSelector(modalStateSelector(modal));
  const history = useHistory();
  const isMobile = useMediaQuery({ query: `(max-width: ${mobileMediaWidth}px)`, });
  const query = useQuery();
  const code = query.get(EQueries.code);
  const queryModal = query.get(EQueries.modal);

  const close = () => {
    dispatch(closeModal(modal));
    query.delete(EQueries.modal);
    query.delete(EQueries.tariff);
    query.delete(EQueries.code);
    history.replace({
      search: query.toString(),
    });
  };

  const handleClickProceed = () => {
    if (isFunction(submit) && modal === EModals.pricingSelectTariffForNextRenewal) {
      submit(code);
      close();
    } else {
      query.set(EQueries.modal, EModals.activatePricing);
      history.replace({
        search: query.toString(),
      });
    }
    dispatch(closeModal(modal));
  };

  useEffect(() => {
    if (queryModal === modal) {
      dispatch(openModal(modal));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryModal]);

  return <ConfirmModal // ---
    title={t("pricing.tariffPlanChange")}
    text={text}
    icon={<NoteIcon />}
    visible={!!visible}
    btns={
      <>
        <Button
          title={t("action.cancel")}
          transparent={true} onClick={close} />
        <Button title={t("action.proceed")} onClick={handleClickProceed} />
      </>
    }
    onCancel={close}
    width={isMobile ? 360 : 416}
  />;
};

export default PricingConfirmModal;