import React, { FC, useEffect, useRef } from "react";
import cn from 'classnames';
import { useTranslation } from "react-i18next";

import SearchIcon from "components/Icons/SearchIcon";


import { ISearchInputProps } from "./types";

const SearchInput: FC<ISearchInputProps> = ({ setSearch, className }) => {
  const { t } = useTranslation();
  const ref = useRef<any>();
  useEffect(() => {
    ref?.current?.focus();
  }, []);

  return (
    <div className={cn("search-wrapper", className)}>
      <input
        ref={ref}
        placeholder={t('action.search')}
        className="search-input"
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => setSearch(e.target.value)}
      />
      <div>
        <SearchIcon />
      </div>
    </div>
  );};

export default SearchInput;
