import { getAssetsSinglePath } from "config/paths";

import AssetSingleMenuitem from "./AssetSingleMenuShareItem";

interface ShareType {
  id:string
}
const AssetSingleMenuShare:React.FC<ShareType> = ({ id }) => (
  <AssetSingleMenuitem
    path={getAssetsSinglePath(id)} />
);
export default AssetSingleMenuShare;
