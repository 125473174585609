import { FC, useEffect, useState } from "react";
import { useMediaQuery } from "react-responsive";

import { maxMobileMediaWidth, maxTableMediaWidth } from "config/constants";

import CloseIcon from "components/Icons/CloseIcon";

import OkManIcon from "../Icons/OkManIcon";

import PopUpPortal from "./components/PopUpPortal";

export type PopUpProps = {
  title: string;
  text: string;
  relatedTo: string;
  setVisible: () => void;
}

const PopUp: FC<PopUpProps> = ({ title, text, setVisible, relatedTo }) => {
  const [related, setRelated] = useState<Element|null>(null);
  const relatedElements = document.getElementsByClassName(relatedTo);
  const isMaxMobile = useMediaQuery({ query: `(max-width: ${maxMobileMediaWidth}px)` });
  const isTable = useMediaQuery({ maxWidth: maxTableMediaWidth, minWidth: maxMobileMediaWidth });

  useEffect(() => {
    if (relatedElements.length > 0) {
      setTimeout(() =>  {
        setRelated(relatedElements[0]);
      }, 300);
    }
  }, [relatedElements]);

  const styles = () => {
    if (isMaxMobile) {
      return {
        top: related?.getBoundingClientRect().top && related?.getBoundingClientRect().top - 48,
        left: related?.getBoundingClientRect().left && related?.getBoundingClientRect().left - 16,
        width: related?.getBoundingClientRect().width && related?.getBoundingClientRect().width
      };
    } else if (isTable) {
      return {
        top: related?.getBoundingClientRect().top && related?.getBoundingClientRect().top + 470,
        left: related?.getBoundingClientRect().right && related?.getBoundingClientRect().right/3,
        width: 369
      };
    }
    return {
      top: related?.getBoundingClientRect().top && related?.getBoundingClientRect().top + 100,
      left: related?.getBoundingClientRect().right && related?.getBoundingClientRect().right + 16,
      width: 369
    };
  };

  return (
    <PopUpPortal>
      <div className="pop-up" style={styles()}>
        <div className="pop-up__body" style={{ width: styles().width }}>
          {!isMaxMobile && <div className="pop-up__close" onClick={setVisible}>
            <CloseIcon />
          </div>}
          <div
            className="pop-up__content"
            style={{ maxWidth: related?.getBoundingClientRect().width && related?.getBoundingClientRect().width }}
          >
            {!isMaxMobile && <h4 className="pop-up__title">
              <div className="pop-up__title__icon">
                <OkManIcon/>
              </div>
              {title}
            </h4>}
            <div className="pop-up__text">{text}</div>
          </div>
        </div>
      </div>
    </PopUpPortal>
  );};

export default PopUp;
