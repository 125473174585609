import { FC } from 'react';
import { useTranslation } from "react-i18next";

import { ECurrency } from "config/types";

import { EPlans, usePricingContext } from "components/Pricing/hooks";

import { currencySymbol } from "../../../../utils/currency";

export type TPricingCardPriceProps = {
  active?: boolean;
  currency?: ECurrency;
  price?: number;
}
const PricingCardPrice: FC<TPricingCardPriceProps> = ({ active, currency, price }) => {
  const { t } = useTranslation();
  const { plan } = usePricingContext();

  const formPrice = () => {
    if (!price) return "";

    if (plan === EPlans.annually) {
      return Math.ceil(price/12);
    }

    return price;
  };

  return <>
    {!active && <div className="pricing__card__price">
      {
        currency === ECurrency.rub
          ? <div>{formPrice()}<span className="time">{currencySymbol(ECurrency.rub)} / {t("pricing.month")}</span></div>
          : <div><span className="usd">{currencySymbol(ECurrency.usd)}</span>{formPrice()}
            <span className="time">/ {t("pricing.month")}</span></div>
      }
    </div>}
  </>;
};

export default PricingCardPrice;
