import { useMediaQuery } from "react-responsive";
import cn from "classnames";
import { FC, useMemo } from "react";
import { Link } from "react-router-dom";

import { maxTableMediaWidth } from "config/constants";
import { paths } from "config/paths";

import { useLayoutAppNameContext } from "components/Layout/hooks";
import SelectLanguage from "components/SelectLanguage";

import { EScrollDirection } from "hooks/types";
import { useScrollDirection } from "hooks/useScrollDirection";

import { EStorageKeys } from "utils/storageHeplers";

import { usePublicChildLoaderContext } from "../../Layout/components/PublicChildLoader";

import PublicHeaderAccount from "./components/PublicHeaderAccount";
import PublicHeaderRedirectButton from "./components/PublicHeaderRedirectButton";
import PublicHeaderCurrency from "./components/PublicHeaderCurrency";

const PublicHeader: FC = () => {
  const { logo, routePath } = useLayoutAppNameContext();
  const { loading, show404 } = usePublicChildLoaderContext();
  const notTable = useMediaQuery({ minWidth: maxTableMediaWidth });

  const handleChangeLanguage = (lng: string) => {
    localStorage.setItem(EStorageKeys.I18NEXT_LNG, lng);
  };

  const showAccount = useMemo(() => [paths.PUBLIC_ACCOUNT_TRANSACTIONS]
    .includes(routePath as paths) && !loading && !show404, [routePath, loading, show404]);
  const showLanguage = useMemo(() => [paths.PUBLIC_ACCOUNT, paths.PUBLIC_AML, paths.PUBLIC_ACCOUNT_TRANSACTIONS]
    .includes(routePath as paths) && !loading && !show404, [routePath, loading, show404]);
  const showCurrency = useMemo(() => [paths.PUBLIC_ACCOUNT, paths.PUBLIC_ACCOUNT_TRANSACTIONS]
    .includes(routePath as paths) && !loading && !show404, [routePath, loading, show404]);

  const [top, scrollDir] = useScrollDirection();
  const headerIsFixed = useMemo(() => [paths.PUBLIC_ACCOUNT_TRANSACTIONS].includes(routePath as paths),
    [routePath]);

  return <div className={cn("new-header__wrapper public-header", {
    stayedUpstairs: headerIsFixed && top > 72,
    goDownOnScrollTop: headerIsFixed && scrollDir === EScrollDirection.up
  })}>
    <div className={cn("new-header", { container: notTable })}>
      <div className="new-header__left">
        {showAccount 
          ? <PublicHeaderAccount/> 
          : <Link className="new-header__logo" to={paths.HOME}>
            {logo}
          </Link>
        }
      </div>
      <div className="new-header__right">
        {showLanguage && <SelectLanguage
          placement="top"
          renderInWrapper={true}
          onChangeLng={handleChangeLanguage}
        />}
        {showCurrency && <PublicHeaderCurrency/>}
        <PublicHeaderRedirectButton/>
      </div>
    </div>
  </div>;
};

export default PublicHeader;