import { PayloadAction } from "@reduxjs/toolkit";

import { TDashboardOnboardingState } from "./types";

export const START_DASHBOARD_ONBOARDING = "START_DASHBOARD_ONBOARDING";

export const startDashboardOnboarding = ():
  PayloadAction<Partial<TDashboardOnboardingState>> => ({
  type: START_DASHBOARD_ONBOARDING,
  payload: {}
});

export const START_DASHBOARD_ONBOARDING_HOW_TO_USE = "START_DASHBOARD_ONBOARDING_HOW_TO_USE";

export const startDashboardOnboardingHowToUse = ():
  PayloadAction<Partial<TDashboardOnboardingState>> => ({
  type: START_DASHBOARD_ONBOARDING_HOW_TO_USE,
  payload: {}
});

export const SET_DASHBOARD_ONBOARDING_TARGET = "SET_DASHBOARD_ONBOARDING_TARGET";

export const setDashboardOnboardingTarget = (payload: string):
  PayloadAction<string> => ({
  type: SET_DASHBOARD_ONBOARDING_TARGET,
  payload
});

export const NOT_REDIRECT_ON_CREATE_ASSETS = "NOT_REDIRECT_ON_CREATE_ASSETS";

export const notRedirectOnCreateAssets = ():
  PayloadAction<Partial<TDashboardOnboardingState>> => ({
  type: NOT_REDIRECT_ON_CREATE_ASSETS,
  payload: {}
});

export const CLOSE_MODAL_ACTION_ON_ONBOARDING_STATE = "CLOSE_MODAL_ACTION_ON_ONBOARDING_STATE";

export const closeModalActionOnOnboardingState = ():
  PayloadAction<Partial<TDashboardOnboardingState>> => ({
  type: CLOSE_MODAL_ACTION_ON_ONBOARDING_STATE,
  payload: {}
});

export const DASHBOARD_ONBOARDING_FINISH = "DASHBOARD_ONBOARDING_FINISH";

export const dashboardOnboardingFinish = ():
  PayloadAction<Partial<TDashboardOnboardingState>> => ({
  type: DASHBOARD_ONBOARDING_FINISH,
  payload: {}
});
