import { FC, useMemo } from "react";
import round from "lodash/round";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import { ECurrency } from "config/types";

import { servicesAllSelectByCodeSelector } from "store/services/selectors";

import { currencySymbol } from "utils/currency";

import { TAMLActivatePackProps } from "../../index";

const AMLActivatePackContent: FC<TAMLActivatePackProps> = ({
  code, count, title,lowTitle
}) => {

  const { t } = useTranslation();
  const service = useSelector(servicesAllSelectByCodeSelector(code));
  const price = service?.price ? round(service?.price / count, 2) : 0;

  const perDate = useMemo(() => {
    let value = 'mo';
    if (service?.type !== "tariff_plan") {
      return null;
    }

    switch (service?.service_category) {
    case "yearly_plan":
      value = t("amlPricing.yearlyPlan");
      break;
    default:
      value = t("amlPricing.monthlyPlan");
    }

    return <p className="aml-activate-pack__price__monthly">{`/${value}`}</p>;
  }, [service]);

  return (<>
    <div className="aml-activate-pack__left">
      <div className="aml-activate-pack__check" dangerouslySetInnerHTML={{ __html: title }} />
      <div className="aml-activate-pack__per-check">
        {
          service?.currency === ECurrency.usd
            ? <>
              {currencySymbol(service?.currency)}{price} {t("amlPricing.perCheck")}
            </>
            : <>
              {price.toFixed(0)} {currencySymbol(service?.currency)} {t("amlPricing.perCheck")}
            </>
        }
      </div>
    </div>
    <div className="aml-activate-pack__right">
      <div className="aml-activate-pack__price">
        {
          service?.currency === ECurrency.usd
            ? <>
              <p className="aml-activate-pack__price__currency">{currencySymbol(service?.currency)}</p>
              <p>{service?.price ? round(service?.price, 1) : 0}</p>
              {perDate}

            </>
            : <>
              <p>{service?.price ? round(service?.price, 1) : 0}</p>
              <p className="aml-activate-pack__price__currency">{currencySymbol(service?.currency)}</p>
              {perDate}
            </>
        }
      </div>
      <div className="aml-activate-pack__right-check" dangerouslySetInnerHTML={{ __html: lowTitle as string }}/>
    </div>

  </>);
};

export default AMLActivatePackContent;
