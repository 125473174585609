import * as Yup from "yup";
import get from "lodash/get";
import filter from "lodash/filter";
import isEmpty from "lodash/isEmpty";

import { requiredStringField } from "utils/validationRules";

import i18n from "../../../../i18n";

declare module 'yup' {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  interface ArraySchema<T> {
    uniqueProperty(name: string, message?: string): ArraySchema<T>;
    uniqueProperties(name: string, fields: string[], message?: string): ArraySchema<T>;
  }
}

Yup.addMethod(Yup.array, 'uniqueProperty', function(propertyPath, message) {
  return this.test('unique', '', function(list) {
    const errors: any[] = [];

    list?.forEach((item, index) => {
      const propertyValue = get(item, propertyPath);

      if (propertyValue && filter(list, [propertyPath, propertyValue]).length > 1) {
        errors.push(
          this.createError({
            path: `${this.path}[${index}].${propertyPath}`,
            message: i18n.t(message),
          })
        );
      }
    });

    if (!isEmpty(errors)) {
      throw new Yup.ValidationError(errors);
    }

    return true;
  });
});


Yup.addMethod(Yup.array, 'uniqueProperties', function(name, fields, message) {
  return this.test('unique', '', function(list) {
    const errors: any[] = [];

    list?.forEach((item, index) => {

      const matches: Record<string, any> = {};
      // eslint-disable-next-line max-nested-callbacks
      fields.forEach((field: string) => {
        matches[field] = get(item, field);
      });

      if (!isEmpty(matches) && filter(list, matches).length > 1) {
        errors.push(
          this.createError({
            path: `${this.path}[${index}].${name}`,
            message: i18n.t(message),
          })
        );
      }
    });

    if (!isEmpty(errors)) {
      throw new Yup.ValidationError(errors);
    }

    return true;
  });
});


export const ConnectAssetWalletSchema = Yup.object().shape({
  blockchains: Yup.array()
    .of(
      Yup.object().shape({
        stock: requiredStringField,
        address: requiredStringField
      })
    )
    // .uniqueProperty('address', i18n.t('connectAssetsWalletForm.uniqueAddress'))
    .uniqueProperties('address', ['address', 'stock'], i18n.t('connectAssetsWalletForm.uniqueAddress'))
});
export default Yup;