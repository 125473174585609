import { FC, MouseEvent, useEffect, useState } from "react";
import cn from "classnames";
import { Collapse } from "antd";
import isEmpty from "lodash/isEmpty";
import isFunction from "lodash/isFunction";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useMediaQuery } from "react-responsive";
import PerfectScrollbar from "react-perfect-scrollbar";

import { paths } from "config/paths";
import { minShowSidebarWidth } from "config/constants";

import PlusIcon from "components/Icons/PlusIcon";
import BNSpinner from "components/BNSpinner";
import Button from "components/Buttons/Button";

import { openModal } from "store/modals/reducers";
import { EModals } from "store/modals/types";
import { TMyAssetsData } from "store/assets/types";
import { connectAssetsDataSelector, getMyAssetsSelector } from "store/assets/selectors";
import { getMyAssetsRequest } from "store/assets/reducers";
import { dashboardOnboardingSelector } from "store/common/selectors";

import { useHeaderDrawerContext } from "../../../NewHeader/components/NewHeaderDrawer/hooks";
import { useActiveNavLink } from "../../../NewHeader/hooks";

import SidebarAccount from "../SidebarAccount";

const { Panel } = Collapse;

type TItemsProps = Pick<TSidebarCollapseProps, "assets"|"showRiskScore"|"path">
const Items: FC<TItemsProps> = ({ assets, path, showRiskScore }) => (<>
  {assets?.map(item => (
    <SidebarAccount key={item.id} path={path} account={item} showRiskScore={showRiskScore} />
  ))}
</>);

export enum ECollapseKeys {
  accounts = "accounts",
  kyt = "kyt",
}
export type TOpenKeys = ECollapseKeys[]|string[];

export type TSidebarCollapseProps = {
  title: string
  assets: TMyAssetsData[]
  collapseKey: ECollapseKeys
  path: paths
  showRiskScore: boolean
  showPlusIcon?: boolean
  showModalCallback?: () => void
  disableOpenModal?: boolean
  id: string
}

const SidebarCollapse: FC<TSidebarCollapseProps> = ({
  children,
  title,
  assets: myAssets,
  collapseKey,
  path,
  showRiskScore,
  showModalCallback,
  disableOpenModal,
  id,
  showPlusIcon,
}) => {
  const { t } = useTranslation();
  const location = useLocation();
  const dispatch = useDispatch();
  const connect = useSelector(connectAssetsDataSelector);
  const showSidebarBurger = useMediaQuery({ query: `(max-width: ${minShowSidebarWidth - 1}px)` });
  const { fetching, isSetStatus } = useSelector(getMyAssetsSelector);
  const { target, isRun } = useSelector(dashboardOnboardingSelector);


  const getOpenKey = () => {
    if (!target?.includes(id) && !isRun) return location.pathname.includes(path) ? [collapseKey] : [];
    return target?.includes(id) ? [collapseKey] : [];
  };

  const [openKeys, setOpenKeys] =
    useState<TOpenKeys>(getOpenKey);

  const [showAll, setShowAll] = useState(false);
  const shownAssets = showAll ? myAssets : myAssets?.slice(0, 3);
  const showBtn = myAssets && myAssets.length > 3;

  const isOpen = target?.includes(id) && isRun
    ? true
    : openKeys.includes(collapseKey);

  const locatesInCurrentPath = useActiveNavLink();
  const { visible } = useHeaderDrawerContext();

  const $isOpen = (myAssets && myAssets.length > 0 || disableOpenModal) && isOpen;

  const handleOpenAssetModal = () => {
    dispatch(openModal(EModals.assetModal));
  };

  const showModal = (e: MouseEvent<SVGSVGElement>) => {
    e.stopPropagation();
    handleOpenAssetModal();
    if (isFunction(showModalCallback)) showModalCallback();
  };

  useEffect(() => {
    dispatch(getMyAssetsRequest({ isSetStatus: true }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [connect]);

  useEffect(() => {
    setOpenKeys(getOpenKey);

    if (showSidebarBurger) {
      setShowAll(false);
    }
  }, [location.pathname, visible, target, isRun]);

  return (
    <li
      className={cn("sidebar-nav-item", {
        disableHover: $isOpen,
        active: !isOpen && locatesInCurrentPath(path)
      })}
      id={id}
    >
      <Collapse
        ghost={true}
        activeKey={openKeys}
        expandIcon={() => $isOpen && showPlusIcon
          ? <PlusIcon className="sidebar-nav-collapse-plus" onClick={showModal}/> : <></>
        }
        onChange={props => {
          if ( myAssets && myAssets.length === 0 && !disableOpenModal && !fetching ) {
            handleOpenAssetModal();
          } else if(!fetching) {
            setOpenKeys(props as TOpenKeys);
            if (isEmpty(props)) setShowAll(false);
          }
        }}
      >
        <Panel
          key={collapseKey}
          header={
            <div className={cn("sidebar-nav-collapse-header", {
              open: isOpen,
              active: !isOpen && locatesInCurrentPath(path)
            })}
            >{t(title)}</div>
          }
        >
          <div className="sidebar-nav-collapse-accounts__wrapper">

            <PerfectScrollbar
              options={{ suppressScrollX: true }}
              className={cn("sidebar-nav-collapse-accounts", { showAll })}
            >
              {(fetching && isSetStatus === false)
                ? <BNSpinner/>
                : (shownAssets && !isEmpty(shownAssets))
                  && <Items assets={shownAssets} path={path} showRiskScore={showRiskScore}/>
              }
            </PerfectScrollbar>
          </div>
          {(!showAll && showBtn) && (
            <Button
              title={t("planAndPaymentSettings.seeAll")}
              transparent={true}
              className="sidebar-nav-collapse-show-all"
              onClick={() => setShowAll(true)}
            />)}
          {children}
        </Panel>
      </Collapse>
    </li>
  );
};

export default SidebarCollapse;
