{/* eslint-disable max-len */}
const EllipsisHorizontalIcon = (): JSX.Element => (
  <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40" fill="none">
    <path
      d="M11 19.9766C11 20.1735 11.0388 20.3686 11.1142 20.5506C11.1896 20.7326 11.3001 20.8979 11.4393 21.0372C11.5786 21.1765 11.744 21.287 11.926 21.3624C12.108 21.4378 12.303 21.4766 12.5 21.4766C12.697 21.4766 12.892 21.4378 13.074 21.3624C13.256 21.287 13.4214 21.1765 13.5607 21.0372C13.6999 20.8979 13.8104 20.7326 13.8858 20.5506C13.9612 20.3686 14 20.1735 14 19.9766C14 19.7796 13.9612 19.5845 13.8858 19.4025C13.8104 19.2205 13.6999 19.0552 13.5607 18.9159C13.4214 18.7766 13.256 18.6661 13.074 18.5907C12.892 18.5154 12.697 18.4766 12.5 18.4766C12.303 18.4766 12.108 18.5154 11.926 18.5907C11.744 18.6661 11.5786 18.7766 11.4393 18.9159C11.3001 19.0552 11.1896 19.2205 11.1142 19.4025C11.0388 19.5845 11 19.7796 11 19.9766ZM18.5 19.9766C18.5 20.3744 18.658 20.7559 18.9393 21.0372C19.2206 21.3185 19.6022 21.4766 20 21.4766C20.3978 21.4766 20.7794 21.3185 21.0607 21.0372C21.342 20.7559 21.5 20.3744 21.5 19.9766C21.5 19.5787 21.342 19.1972 21.0607 18.9159C20.7794 18.6346 20.3978 18.4766 20 18.4766C19.6022 18.4766 19.2206 18.6346 18.9393 18.9159C18.658 19.1972 18.5 19.5787 18.5 19.9766ZM26 19.9766C26 20.3744 26.158 20.7559 26.4393 21.0372C26.7206 21.3185 27.1022 21.4766 27.5 21.4766C27.8978 21.4766 28.2794 21.3185 28.5607 21.0372C28.842 20.7559 29 20.3744 29 19.9766C29 19.5787 28.842 19.1972 28.5607 18.9159C28.2794 18.6346 27.8978 18.4766 27.5 18.4766C27.1022 18.4766 26.7206 18.6346 26.4393 18.9159C26.158 19.1972 26 19.5787 26 19.9766Z"
      fill="#626368" />
  </svg>
);

export default EllipsisHorizontalIcon;
