import { FC } from 'react';

import { IIconsProps } from "config/types";

{/* eslint-disable max-len */}
const LoginIcon:FC<IIconsProps> = ({ className, onClick }) => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" className={className} onClick={onClick}>
    <path d="M12.4344 2.00004C8.8879 1.99073 5.76697 3.82561 3.98325 6.5977C3.90418 6.72096 3.99255 6.88375 4.13907 6.88375H5.77395C5.88557 6.88375 5.99023 6.83491 6.05999 6.74886C6.22278 6.55119 6.3972 6.36049 6.58092 6.1791C7.33906 5.42329 8.22045 4.82794 9.20185 4.41398C10.2158 3.98608 11.2949 3.76747 12.4088 3.76747C13.5228 3.76747 14.6018 3.98375 15.6158 4.41398C16.5972 4.82794 17.4786 5.42329 18.2367 6.1791C18.9949 6.93491 19.5879 7.8163 20.0042 8.79537C20.4344 9.80932 20.6507 10.8861 20.6507 12C20.6507 13.114 20.4321 14.1907 20.0042 15.2047C19.5902 16.1837 18.9949 17.0651 18.2367 17.8209C17.4786 18.5767 16.5972 19.1721 15.6158 19.5861C14.601 20.0147 13.5104 20.2345 12.4088 20.2326C11.2949 20.2326 10.2158 20.014 9.20185 19.5861C8.22238 19.1725 7.33233 18.5731 6.58092 17.8209C6.3972 17.6372 6.22511 17.4465 6.05999 17.2512C5.99023 17.1651 5.88325 17.1163 5.77395 17.1163H4.13907C3.99255 17.1163 3.90186 17.2791 3.98325 17.4023C5.76464 20.1674 8.87162 22 12.4088 22C17.9018 22 22.3623 17.5791 22.4181 12.1023C22.4739 6.53724 18.0065 2.01399 12.4344 2.00004ZM9.48836 14.6047V12.8372H2.18605C2.08372 12.8372 2 12.7535 2 12.6512V11.3489C2 11.2465 2.08372 11.1628 2.18605 11.1628H9.48836V9.39537C9.48836 9.23956 9.66975 9.15119 9.79068 9.24886L13.0907 11.8535C13.1129 11.8709 13.1309 11.8931 13.1433 11.9185C13.1556 11.9439 13.1621 11.9718 13.1621 12C13.1621 12.0283 13.1556 12.0561 13.1433 12.0815C13.1309 12.1069 13.1129 12.1291 13.0907 12.1465L9.79068 14.7512C9.66975 14.8465 9.48836 14.7605 9.48836 14.6047Z" fill="#D64686"/>
  </svg>
);

export default LoginIcon;
