import * as Yup from "yup";

import { loginValidationFieldRequired, passwordValidationField, requiredStringField } from "utils/validationRules";

export const SignSchema = Yup.object().shape({
  username: loginValidationFieldRequired,
  password: passwordValidationField,
});

export const SignInShema = Yup.object().shape({
  username: loginValidationFieldRequired,
  password: requiredStringField,
});
