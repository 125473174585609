import { FC } from "react";
import { Dropdown } from "antd";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import { getAMLPublicPath } from "config/paths";

import EllipsisVerticalIcon from "components/Icons/EllipsisVerticalIcon";
import ShareLink from "components/ShareLink";

import { amlChecksDetailsSelector } from "store/amlChecks/selectors";
import { openModal } from "store/modals/reducers";
import { EModals } from "store/modals/types";

const AMLCheckDetailsMainHeaderMenu: FC = () => {
  const { t } = useTranslation();
  const { data } = useSelector(amlChecksDetailsSelector);
  const dispatch = useDispatch();
  const onRemove = () => {
    if (data) {
      dispatch(openModal(EModals.amlCheckModelDelete));
    }
  };
  interface GetItemType {
    key: string,
    click?: () => void,
    url?:string
  }
  const getItem = ({ key, click, url }:GetItemType) => ({
    key,
    onClick: click,
    label: url ? (
      <a
        href={url}
        className="exposure-block__download-icon"
        rel="noreferrer"
        target="_blank"
      >
        {t(key)}
      </a>
    ) : t(key),
    disabled: !url && key !== "amlPage.deleteCheck"
  });
  const ShareLinkItem = (key: string, path: string) => ({
    key,
    label: (
      <ShareLink
        path={path}
        className="aml-single__header__copy"
        title={t(key)}
      />
    )
  });
  const items = [
    getItem( { key:"amlPage.downloadPdf",url: data?.report_url }),
    ShareLinkItem("amlPage.shareReport", getAMLPublicPath(data?.id as string)),
    getItem({ key:"amlPage.deleteCheck",click:onRemove }),
  ];
  return (
    <div className="transfers-details-main__header__menu">
      <Dropdown
        menu={{
          className: "bn-dropdown__menu",
          items,
        }}
        trigger={["click"]}
        placement="bottomRight"
      >
        <a
          onClick={e => e.preventDefault()}
          className="transfers-details-main__header__menu__trigger"
        >
          <EllipsisVerticalIcon/>
        </a>
      </Dropdown>
    </div>
  );
};

export default AMLCheckDetailsMainHeaderMenu;