import { all, call, put, takeLatest } from "redux-saga/effects";
import { AxiosResponse } from "axios";
import isFunction from "lodash";
import request from "API";

import {
  calculatePaymentFailure,
  calculatePaymentRequest,
  calculatePaymentSuccess,
  paymentFailure,
  paymentRequest,
  paymentSuccess,
  serviceDetailsFailure,
  serviceDetailsRequest,
  serviceDetailsSuccess,
  servicesAllFailure,
  servicesAllRequest,
  servicesAllSuccess,
  servicesFailure,
  servicesRequest,
  servicesSuccess,
  setPaymentPricingLoading,
  userPaymentMethodStateFailure,
  userPaymentMethodStateRequest,
  userPaymentMethodStateSuccess,
} from "store/services/reducers";

import delay from "utils/delay";

import { ECurrency } from "../../config/types";
import i18n from "../../i18n";

import { IPayloadAction } from "../rootInterface";
import { userPlanInfoRequest } from "../user/reducers";
import { analyticEvent } from "../analytics/effects";
import { EEventType } from "../analytics/types";

import {
  PaymentRequestStatus, TPaymentCalculateData,
  TPaymentCalculateOptions,
  TPaymentData,
  TPaymentOptions, TServiceDetails, TServicesAllOptions, TServicesData,
} from "./types";
import { PAYMENT_PROMO_CODE_APPLY_REQUEST } from "./actions";

function* getServices() {
  try {
    const response: AxiosResponse<TPaymentData> = yield call(request.get, "/services/");
    yield put(servicesSuccess(response.data));
  } catch (e) {
    yield put(servicesFailure(e));
  }
}

function* getDetailServices(action: IPayloadAction<{id:string, currency?: ECurrency}>) {
  const { id, currency } = action.payload;

  try {
    const response: AxiosResponse<TServiceDetails> = yield call(request.get, `/services/${id}/details/`,
      { params: { currency } });

    if (response.data.price !== 0 && !response.data.price && response.data.variants.length > 0) {
      yield put(serviceDetailsRequest({ id: response.data.variants[0].id, currency }));
    } else {
      yield put(serviceDetailsSuccess(response.data));
    }
  } catch (e) {
    yield put(serviceDetailsFailure(e));
  }
}

function* getAllServices(action: IPayloadAction<TServicesAllOptions> ) {
  const { params } = action.payload;
  try {
    const response: AxiosResponse<TServicesData[]> = yield call(request.get, "/services/all/", { params });
    yield put(servicesAllSuccess(response.data));
  } catch (e) {
    yield put(servicesAllFailure(e));
  }
}

function* calculatePayment(action: IPayloadAction<TPaymentCalculateOptions & { applyPromoCode?: boolean }>) {
  const promoCodeExists = !!action.payload.applyPromoCode;
  try {
    const response: AxiosResponse<TPaymentCalculateData> =
      yield call(request.post, "/user/payment/calculate/", action.payload);
    yield put(calculatePaymentSuccess(response.data));

    if (promoCodeExists) {
      if (response.data.promo_code_applied) {
        put(analyticEvent(EEventType.PAYMENT_STEP1_PROMO_APPLIED));
      } else {
        yield put(analyticEvent(EEventType.PAYMENT_STEP1_PROMO_FAILED));
        yield put(calculatePaymentFailure({ parsedErrors: { promo_code: i18n.t("errors.appliedPromoCode") } }));
      }
    }
  } catch (e) {
    yield put(calculatePaymentFailure(e));
  }
}

function* paymentPromoCodeApply(action: IPayloadAction<TPaymentCalculateOptions>) {
  yield put(calculatePaymentRequest({ ...action.payload, applyPromoCode: true }));
}

function* payment(action: IPayloadAction<{data:TPaymentOptions, callOnSuccess: () => void}>) {
  const { data, callOnSuccess } = action.payload;
  try {
    const response: AxiosResponse<TPaymentData> = yield call(request.post, "/user/payment/new/", data);
    yield put(paymentSuccess(response.data));
    yield put(userPlanInfoRequest());

    if (response.data.payment_url) {
      yield put(setPaymentPricingLoading(false));
      window.location.href = response.data.payment_url;
    }

    if (response.data.status === PaymentRequestStatus.completed) {
      if (isFunction(callOnSuccess)) callOnSuccess();
      yield put(setPaymentPricingLoading(false));
    }

    if (response.data.status === PaymentRequestStatus.pending) {
      yield delay(2000);
      yield put(userPaymentMethodStateRequest({ id: response.data.id, callOnSuccess }));
    }
  } catch (e) {
    yield put(paymentFailure(e));
    yield put(setPaymentPricingLoading(false));
  }
}


function* getUserPaymentMethodState(action: IPayloadAction<{ id: string, callOnSuccess?: () => void }>) {
  const { id, callOnSuccess } = action.payload;
  try {
    const response: AxiosResponse<TPaymentData> =
      yield call(request.get, `/user/payment/${id}/state/`);

    yield put(userPaymentMethodStateSuccess(response.data));

    if (response.data.status === PaymentRequestStatus.pending) {
      yield delay(5000);
      yield put(userPaymentMethodStateRequest({ id, callOnSuccess }));
    } else {
      if (callOnSuccess && isFunction(callOnSuccess)) callOnSuccess();
      yield put(setPaymentPricingLoading(false));
    }
  } catch (e) {
    yield put(userPaymentMethodStateFailure(e));
    yield put(setPaymentPricingLoading(false));
  }
}

function* Saga(): Generator {
  yield all([
    takeLatest(servicesRequest.type, getServices),
    takeLatest(serviceDetailsRequest.type, getDetailServices),
    takeLatest(servicesAllRequest.type, getAllServices),
    takeLatest(calculatePaymentRequest.type, calculatePayment),
    takeLatest(PAYMENT_PROMO_CODE_APPLY_REQUEST, paymentPromoCodeApply),
    takeLatest(paymentRequest.type, payment),
    takeLatest(userPaymentMethodStateRequest.type, getUserPaymentMethodState)
  ]);
}

export default Saga;
