import { CSSProperties, FC, useEffect, useState } from 'react';
import cn from "classnames";
import { Transition, CSSTransition } from 'react-transition-group';
import { useDispatch } from "react-redux";

import PricingPage from 'components/PricingPage';
import CloseIcon from "components/Icons/CloseIcon";

import useWindowDimensions from "hooks/useWindowDimensions";

import { analyticEvent } from "store/analytics/effects";
import { EEventType } from "store/analytics/types";

export type TUpgradePlanPricingProps = {
  showPlan: boolean;
  closeModal: () => void;
}
const UpgradePlanPricing: FC<TUpgradePlanPricingProps> = ({ showPlan, closeModal }) => {
  const dispatch = useDispatch();
  const [showDetails, setShowDetails] = useState(false);
  const { height: windowHeight } = useWindowDimensions();
  const minHeight = windowHeight && windowHeight <= 1012;
  const transform = `translate(0px, ${minHeight ? "520px" : "calc(77vh - 256px)"})`;

  const styles: Record<string, CSSProperties> = {
    entering: {
      transform: "translate(0, 0)",
    },
    entered: {
      transform: "translate(0, 0)",
    },
    exiting: {
      transform
    },
    exited: {
      transform
    },
  };

  useEffect(() => {
    if (showPlan) {
      setTimeout(() => {
        setShowDetails(true);
      }, 0);
    }
    return () => setShowDetails(false);
  }, [showPlan]);

  useEffect(() => {
    if (showPlan) {
      dispatch(analyticEvent(EEventType.PRICING_POPUP));
    }
  }, [showPlan]);

  return (
    <Transition
      timeout={0}
      in={showPlan}
    >
      {state => (
        <div
          style={{
            transition: "transform 1s",
            ...styles[state]
          }}
          className={cn("upgrade-plan-pricing", { "show-header": showDetails })}
        >
          <CSSTransition
            classNames="auth-carousel__transition"
            timeout={350}
            in={showDetails}
          >
            {showDetails ? <div className="upgrade-plan-pricing__close" onClick={closeModal}>
              <CloseIcon/>
            </div> : <></>}
          </CSSTransition>

          <PricingPage showHeader={showDetails} isModal={true}/>
        </div>
      )}
    </Transition>
  );
};

export default UpgradePlanPricing;
