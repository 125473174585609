import { FC } from 'react';
import cn from "classnames";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useMediaQuery } from "react-responsive";

import { mobileMediaWidth } from "config/constants";

import ProgressInfo from "components/ProgressInfo";

import { ETariffPlan } from 'store/services/types';
import { userPlanInfoSelector } from "store/user/selectors";

import { formatBytesToMb } from "utils/formatBytes";

export type TPricingProgressProps = {
  pricing?: ETariffPlan;
}

const PricingProgress: FC<TPricingProgressProps> = ({ pricing }) => {
  const { t } = useTranslation();
  const { data: userPlan } = useSelector(userPlanInfoSelector);
  const isMobile = useMediaQuery({ query: `(max-width: ${mobileMediaWidth}px)` });
  
  if (isMobile) {
    return <></>;
  }

  return (
    <div className={cn("pricing__card__processes pricing__container", pricing )}>
      <ProgressInfo
        title={t("pricing.fileStorage")}
        number={formatBytesToMb(userPlan?.file_storage.used || 0)}
        max={formatBytesToMb(userPlan?.file_storage.max || 0)}
        noLimit={!userPlan?.file_storage.max}
        unit={"MB"}
      />
      <ProgressInfo
        title={t("pricing.notesAndTags")}
        number={userPlan?.notes.used || 0}
        max={userPlan?.notes.max || 0}
        noLimit={!userPlan?.notes.max}
      />
    </div>
  );
};

export default PricingProgress;