import { FC } from "react";
import cn from "classnames";

import SyncIcon from "components/Icons/SyncIcon";

import SyncLoader from "../SyncLoader";

export type TSyncBadgeProps = {
  className?: string
  isSpin?: boolean
  error?: boolean
}
const SyncBadge: FC<TSyncBadgeProps> = ({ isSpin, error , className }) => (
  <div className={cn("sync-badge sync-icon-wrapper", className)}>
    {isSpin && <SyncLoader isSpin={isSpin} />}
    {error && <SyncIcon className="error"/>}
  </div>
);

export default SyncBadge;