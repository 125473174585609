import { FC, useMemo } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";

import { getAccountPublicPath, getPublicAccountId, paths } from "config/paths";

import { useLayoutAppNameContext } from "components/Layout/hooks";
import VectorIcon from "components/Icons/VectorIcon";
import SettingIcon from "components/Icons/SettingIcon";

import { setHeaderDrawer } from "../../../../../store/common/reducers";
import AMLPublicDownload from "../../../../AMLPublic/components/AMLPublicDownload";

const PublicMobileLeft: FC = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const publicAccountId = getPublicAccountId();
  const { routePath } = useLayoutAppNameContext();

  const vectorBack: Record<string, () => void> = useMemo(() => ({
    [paths.PUBLIC_ACCOUNT_TRANSACTIONS]: () => {
      if (!publicAccountId) return;
      history.push(getAccountPublicPath(publicAccountId));
    },
  }), [publicAccountId]);

  const handleClickBack = () => {
    if (!routePath) return;

    const call = vectorBack[routePath];
    if (call) call();
  };

  const showVectorBack = Object.keys(vectorBack).includes(routePath as paths);

  const showSettings = useMemo(() => 
    [paths.PUBLIC_ACCOUNT, paths.PUBLIC_AML].includes(routePath as paths), [routePath]);
  const showDownload = routePath === paths.PUBLIC_AML;
  
  return <div className="header-mobile__left">
    {showSettings &&
      <SettingIcon
        className="public-header-mobile__settings"
        onClick={() => dispatch(setHeaderDrawer(true))}/>}
    {showDownload && <AMLPublicDownload/>}
    {showVectorBack && <VectorIcon className="header-mobile__left__back" onClick={handleClickBack}/>}
  </div>;
};

export default PublicMobileLeft;