import { ECurrency, TNullable } from "config/types";

import { EServiceCode, ETariffPlan } from "store/services/types";

import { ELanguages } from "../../i18n/constants";

import { IPaginationResponse, TRequestHandler } from "../rootInterface";

export enum ETimeFormat {
  h12 = "12H",
  h24 = "24H",
}

export enum EDateFormat {
  dateFormat1 = 'DD.MM.YYYY',
  dateFormat2 = 'DD/MM/YYYY',
  dateFormat3 = 'YYYY-MM-DD',
  dateFormat4 = 'YYYY.MM.DD',
  dateFormat5 = 'MM/DD/YYYY',
}

export type TUserInfoData = {
  id: TNullable<string>;
  username: string;
  email: string;
  phone: string;
  services: [string];
  tariff_plan: ETariffPlan
  date_joined: string;
  language: TNullable<ELanguages|"auto">;
  time_zone: TNullable<string|"auto">;
  time_format: TNullable<ETimeFormat>;
  date_format: TNullable<EDateFormat>;
  currency: TNullable<ECurrency>;
  country: TNullable<string>;
  telegram_nickname: TNullable<string>;
  telegram_full_name : TNullable<string>;
  checks_available?: boolean;
  tariff_plan_upgradable:boolean;
  aml_checks_available: number;
};

export type TUserInfo = TRequestHandler<TUserInfoData>

export type TUserSettingsInfoData = {
  email: TNullable<string>;
  phone: TNullable<string>;
  email_confirmed: boolean;
};

export type TUserSettingsInfoState = TRequestHandler<TUserSettingsInfoData>

export type TUserChangePasswordOptions = {
  data: { old_password: string; new_password: string };
  callOnSuccess: () => void;
};

export type TUserChangePasswordData = {
  detail: string;
};

export type TUserChangePasswordState = TRequestHandler<TUserChangePasswordData>

export type TUserSendActivateEmailLinkData = {
  code_token: string;
  blocking_time: string;
};

export type TUserSendActivateEmailCodeData = {
  refresh_token: string;
  access_token: string;
};

export type TUserSendActivateEmailLinkState = TRequestHandler<TUserSendActivateEmailLinkData>

export type TUserBindEmailPhone = TRequestHandler<TUserSendActivateEmailLinkData>

export type TUserBindEmailPhoneCode = TRequestHandler<TUserSendActivateEmailCodeData>

export type TUserSaveLocalSettingsData = {
  language: ELanguages|"auto";
  time_zone: string|"auto";
  time_format: ETimeFormat;
  date_format: EDateFormat;
  currency: ECurrency;
}
export type TUserSaveLocalSettingsState = TRequestHandler<TUserSaveLocalSettingsData>

export enum EPaymentProvider {
  YOOKASSA = 'yookassa',
  OXP = '0xp',
  OXP_USDT_ERC20 = '0xp:usdt:erc20',
  OXP_USDT_TRC20 = '0xp:usdt:trc20',
  OXP_USDT_BEP20 = '0xp:usdt:bep20',
}
export enum EReceiptDestinationTypes {
  EMAIL_OR_PHONE = 'email_or_phone',
  EMAIL = 'email',
  PHONE = 'phone',
}
export type TReceiptDestination = {
  required: boolean
  type: EReceiptDestinationTypes
  value: string
}
export type TUserPaymentMethodsData = {
  id: string;
  type: EPaymentProvider;
  name: string;
  is_default: boolean;
  can_be_saved: boolean;
  receipt_destination: TNullable<TReceiptDestination>
}
export type TUserPaymentMethodsState = TRequestHandler<TUserPaymentMethodsData[]>

export type TUserPaymentMethodsToSaveState = TRequestHandler<TUserPaymentMethodsData[]>

export type TUserPaymentMethodsSavedData = {
  id: string;
  type: EPaymentProvider;
  name: string;
  is_default: boolean;
}
export type TUserPaymentMethodsSavedState = TRequestHandler<TUserPaymentMethodsSavedData[]>

export type TSetUserPaymentMethodsState = TRequestHandler<Record<string, unknown>>

export type TNewPaymentMethodsOptions = {
  provider: EPaymentProvider
  receipt_destination?: TNullable<string>
  url_completed?:string
  url_canceled?:string
  url_pending?:string
}
export type TNewPaymentMethodsData = {
  payment_url: string
}
export type TNewPaymentMethodsState = TRequestHandler<TNewPaymentMethodsData>

export type TDeleteUserPaymentMethodsState = TRequestHandler<Record<string, unknown>>

export enum EUserPaymentHistoryStatus {
  pending="pending",
  canceled="canceled",
  completed="completed",
}
export type TUserPaymentHistory = {
  id: string;
  status: EUserPaymentHistoryStatus;
  currency: ECurrency;
  amount: number;
  description: string;
  created_at: TNullable<string>;
}
export type TUserPaymentHistoryState = TRequestHandler<IPaginationResponse<TUserPaymentHistory>>

export type TUserPlanInfoPlan = {
  id: TNullable<string>;
  next_payment_date: TNullable<string>;
  paid_service_code: TNullable<EServiceCode>;
  paid_service_id: TNullable<string>;
  payment_failed: TNullable<boolean>;
  started_at: TNullable<string>;
  ended_at: TNullable<string>
  tariff_plan: ETariffPlan;
}
export type TUserPlanInfoFileStorage = {
  max: number; // - максимально доступный объем для "Файлового хранилища".
  used: number; // - использованный объем в "Файловом хранилище".
}
export type TUserPlanIfoNotes = {
  max: number; // - максимальное число доступных заметок.
  used: number; // - использованное число заметок.
}
export type TUserPlanInfoData = {
  current_plan: TUserPlanInfoPlan // - код тарифного плана. Возможные значения: free, smart, pro.;
  next_plan: TNullable<TUserPlanInfoPlan> // - следующий план, который будет активирован после текущего.
  notes: TUserPlanIfoNotes // - данные о лимитах для "Заметок и тегов".
  file_storage: TUserPlanInfoFileStorage  // - данные о лимитах для "Файлового хранилища".
  plans_to_upgrade: EServiceCode[]; //  - тарифные планы (коды услуг), доступные для апгрейда.
  plans_to_renew: EServiceCode[]; // - тарифные планы (коды услуг), доступные для ближайшего продления.
  plans_to_prepaid: EServiceCode[]; // - тарифные планы (коды услуг), доступные для предварительной оплаты.
  plans_to_change: EServiceCode[]; // - тарифные планы (коды услуг), доступные для замены (с фризом текущего плана).
}
export type TUserPlanInfoState = TRequestHandler<TUserPlanInfoData>

export type TUserPlanSetNextPlanToRenewState = TRequestHandler<null>

export type TUserPlanCancelNextPayment = TRequestHandler<null>


export type TUserStoreState = {
  userInfo: TUserInfo;
  userSettingsInfoState: TUserSettingsInfoState;
  userChangePasswordState: TUserChangePasswordState;
  userSendActivateEmailLinkState: TUserSendActivateEmailLinkState;
  userBindEmailPhoneState: TUserBindEmailPhone,
  userBindEmailPhoneCodeState: TUserBindEmailPhoneCode,
  userBindEmailPhoneResendState: TUserBindEmailPhone,
  userSaveLocalSettingsState: TUserSaveLocalSettingsState,
  userPaymentMethodsState: TUserPaymentMethodsState,
  userPaymentMethodsToSaveState: TUserPaymentMethodsToSaveState,
  userPaymentMethodsSavedState: TUserPaymentMethodsSavedState,
  setUserPaymentMethodsState: TSetUserPaymentMethodsState,
  newUserPaymentMethodsState: TNewPaymentMethodsState,
  deleteUserPaymentMethodsState: TDeleteUserPaymentMethodsState,
  userPaymentHistoryState: TUserPaymentHistoryState,
  userPlanInfoState: TUserPlanInfoState,
  userPlanSetNextPlanToRenewState: TUserPlanSetNextPlanToRenewState,
  userPlanCancelNextPaymentState: TUserPlanCancelNextPayment,
  showBindCodeForm: boolean,
  showBindPopup: boolean,
  loadingTelegram: boolean,
};

export type TTelegramUser = {
  auth_date: number;
  first_name: string;
  hash: string;
  id: number;
  last_name: string;
  username: string
}
